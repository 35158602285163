import React from 'react';
import { Switch, Typography, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
    margin: 2,
  },
  switchBase: {
    padding: 2,
    '&$checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + $track': {
        opacity: 0.7,
        backgroundColor: theme.palette.primary.light,
      },
      '& $thumb': {
        backgroundColor: theme.palette.primary.main, // Altere a cor aqui
      },
    },
  },
  thumb: {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    color: '#757575',
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  track: {
    borderRadius: 16 / 2,
    opacity: 0.2,
    backgroundColor: theme.palette.grey,
    boxSizing: 'border-box',
  },
  checked: {},
}))(Switch);

const CustomSwitch = ({ leftText, rightText, value, onClick }) => {
  return (
    <Grid container direction="row" spacing={1} alignItems="center">
      {leftText && <Typography component="span" style={{cursor: 'pointer', marginRight: '3px'}} onClick={onClick}>{leftText}</Typography>}
      <AntSwitch checked={value} inputProps={{ 'aria-label': 'ant design' }} onChange={onClick} />
      {rightText && <Typography component="span" style={{cursor: 'pointer', marginLeft: '3px'}} onClick={onClick}>{rightText}</Typography>}
    </Grid>
  );
}

export default CustomSwitch;
