import React, { useContext, useRef } from "react";

import Avatar from "@material-ui/core/Avatar";
import Chip from "@material-ui/core/Chip";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import { green } from "@material-ui/core/colors";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from "clsx";

import { Tooltip } from "@material-ui/core";
import { red } from "@material-ui/core/colors";
import ErrorIcon from '@material-ui/icons/Error';
import { Badge, ListItemAvatar } from "@mui/material";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../../context/Auth/AuthContext";
import { i18n } from "../../translate/i18n";
import MarkdownWrapper from "../MarkdownWrapper";
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import InstagramIcon from '@material-ui/icons/Instagram';

const useStyles = makeStyles(theme => ({
	ticket: {
		position: "relative",
	},

	pendingTicket: {
		cursor: "unset",
	},

	noTicketsDiv: {
		display: "flex",
		height: "100px",
		margin: 40,
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
	},

	noTicketsText: {
		textAlign: "center",
		color: "rgb(104, 121, 146)",
		fontSize: "14px",
		lineHeight: "1.4",
	},

	noTicketsTitle: {
		textAlign: "center",
		fontSize: "16px",
		fontWeight: "600",
		margin: "0px",
	},

	contactNameWrapper: {
		display: "flex",
		justifyContent: "space-between",
	},

	lastMessageTime: {
		justifySelf: "flex-end",
	},

	closedBadge: {
		alignSelf: "center",
		justifySelf: "flex-end",
		marginRight: 32,
		marginLeft: "auto",
		zIndex: 3
	},
	campaignBadge: {
		alignSelf: "center",
		justifySelf: "flex-end",
		marginRight: '80px',
		marginLeft: "auto",
	},
	contactLastMessage: {
		maxWidth: '30em',
		width: "100%"
	},

	newMessagesCount: {
		alignSelf: "center",
		marginRight: 8,
		marginLeft: "auto",
	},

	badgeStyle: {
		color: "white",
		backgroundColor: green[500],
	},

	badgeAlertStyle: {
		color: red[900],
		height: "fit-content",
		width: "fit-content",
		padding: "0",
	},

	acceptButton: {
		textTransform: "capitalize",
		padding: "2px 5px",
		margin: "0px",
		width: "70px",
		fontSize: "11px",
	},

	ticketQueueColor: {
		flex: "none",
		width: "8px",
		height: "100%",
		position: "absolute",
		top: "0%",
		left: "0%",
	},
	ticketQueueColorTags: {
		flex: "none",
		width: "8px",
		height: "90%",
		position: "absolute",
		top: "0%",
		left: "0%",
	},

	icon: {
		borderRadius: 3,
		width: 16,
		height: 16,
		boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
		backgroundColor: '#f5f8fa',
		backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
		'$root.Mui-focusVisible &': {
			outline: '2px auto rgba(19,124,189,.6)',
			outlineOffset: 2,
		},
		'input:hover ~ &': {
			backgroundColor: '#ebf1f5',
		},
		'input:disabled ~ &': {
			boxShadow: 'none',
			background: 'rgba(206,217,224,.5)',
		},
	},
	checkedIcon: {
		backgroundColor: '#137cbd',
		backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
		'&:before': {
			display: 'block',
			width: 16,
			height: 16,
			backgroundImage:
				"url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
				" fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
				"1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
			content: '""',
		},
		'input:hover ~ &': {
			backgroundColor: '#106ba3',
		},
	},
	moduleBadge: {
		marginRight: 0,  
		background: '#ffffff', 
		borderRadius: '100%',
		width: '18px',
		height: '18px'
	}
}));


const SmallAvatar = withStyles((theme) => ({
	root: {
		width: 22,
		height: 22,
		border: `2px solid ${theme.palette.background.paper}`,
	},
}))(Avatar);


const ContactListItem = ({ contact, ...props }) => {
	const classes = useStyles();
	const isMounted = useRef(true);
	const { user } = useContext(AuthContext);
	const history = useHistory();

	const goToTicket = async (uuid) => {
		history.push(`/tickets/${uuid}`);
	}

	const handleSelectTicket = (e, contactId) => {
		goToTicket(`createTicket:${contactId}`);
	};

	return (
		<React.Fragment key={contact.id}>
			<ListItem
				dense
				button
				id={contact.id}
				className={clsx(classes.ticket)}
				onClick={(event) => {
					handleSelectTicket(event, contact.id);
				}}
			>

				<Tooltip
					arrow
					placement="right"
					title={contact.Queue?.name || "contato"}
				>
					<span
						style={{ backgroundColor: contact.Queue?.color || "#7C7C7C" }}
						className={classes.ticketQueueColor}
					/>
				</Tooltip>
				<ListItemAvatar>
					<Badge
						overlap="circular"
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'right',
						}}
						badgeContent={
							contact?.modulesId == 1 || contact?.modulesId == null  ? (
								<Tooltip title="Este atendimento foi realizado pelo WhatsApp.">
									<WhatsAppIcon className={classes.moduleBadge} style={{color: '#25D366'}}/>
								</Tooltip>
							) : contact?.modulesId == 2 ? (
								<Tooltip title="Este atendimento foi realizado pelo Instagram.">
									<InstagramIcon className={classes.moduleBadge} style={{color: '#C13584'}} />
								</Tooltip>
							) : ( 
								<> </>
							)
						}
					>						
						<Avatar alt="user_pic" src={contact?.profilePicUrl} />
					</Badge>
				</ListItemAvatar>
				<ListItemText
					disableTypography

					primary={
						<span className={classes.contactNameWrapper}>
							<Typography
								className={classes.contactLastMessage}
								noWrap
								component="span"
								variant="body2"
								style={{ display: "flex", justifyContent: "space-between" }}
								color="textSecondary">

								{contact.isGroup ? i18n.t('groupPrefix') + contact.name : contact.name}

							</Typography>
						</span>
					}

					secondary={
						<span className={classes.contactNameWrapper}>
							<Typography
								className={classes.contactLastMessage}
								noWrap
								component="span"
								variant="body2"
								color="textSecondary"
							>
								{contact.whatsapp?.connection && contact.whatsapp?.name ? (
									<MarkdownWrapper>{`${contact.whatsapp.name} - ${contact.whatsapp.connection}`}</MarkdownWrapper>
								) : (
									<br />
								)}

								{
									(contact.whatsapp?.status && String(contact.whatsapp.status).toLowerCase() != 'connected')
									&&
									<Badge
										style={{
											float: "right",
											zIndex: "3",
											top: "8px",
											right: "9px"
										}}
										badgeContent={
											<Tooltip
												arrow
												placement="bottom"
												title={<Typography gutterBottom color="inherit">{i18n.t("messagesInput.placeholderConnection")}</Typography>}
											>
												<ErrorIcon />
											</Tooltip>
										}
										classes={{
											badge: classes.badgeAlertStyle,
										}}
									/>
								}
							</Typography>
						</span>
					}
				/>
			</ListItem>
			{contact?.tagsId &&
				<>
					<div style={{
						marginBottom: "-1px",
						position: "relative",
						padding: "0 2px 0 10px",
					}}>
						<>
							<Tooltip
								arrow
								placement="right"
								title={contact.Queue?.name || "contato"}
							>
								<span
									style={{ backgroundColor: contact.Queue?.color || "#7C7C7C" }}
									className={classes.ticketQueueColorTags}
								/>
							</Tooltip>
							{Array.isArray(contact?.tagsId) && contact?.tagsId.map((tag) => (
								<Tooltip
									arrow
									title={tag.name || "Não identificado"}
								>
									<Chip
										size="small"
										style={{
											borderRadius: '5px',
											height: '10px',
											padding: '0px 10px',
											margin: '0px 3px',
											backgroundColor: tag.color,
											'& .MuiChip-label': {
												paddingLeft: '5px',
												paddingRight: '5px',
											},
										}}
									/>

								</Tooltip>
							))}
						</>
					</div>
				</>
			}

			<Divider variant="inset" component="li" style={{ marginLeft: 0 }} />
		</React.Fragment>
	);
};

export default ContactListItem;