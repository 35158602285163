import { useContext, useEffect, useRef, useState } from "react";


import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import { Field, FieldArray, Form, Formik } from "formik";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { AuthContext } from "../../context/Auth/AuthContext";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import ContactDrawerSkeleton from "../ContactDrawerSkeleton";

const drawerWidth = 320;

const useStyles = makeStyles(theme => ({
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
	},
	drawerPaper: {
		width: drawerWidth,
		display: "flex",
		borderTop: "1px solid rgba(0, 0, 0, 0.12)",
		borderRight: "1px solid rgba(0, 0, 0, 0.12)",
		borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
		borderTopRightRadius: 4,
		borderBottomRightRadius: 4,
	},
	header: {
		display: "flex",
		borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
		backgroundColor: "#eee",
		alignItems: "center",
		padding: theme.spacing(0, 1),
		minHeight: "73px",
		justifyContent: "flex-start",
	},
	content: {
		display: "flex",
		backgroundColor: "#eee",
		flexDirection: "column",
		padding: "8px 0px 8px 8px",
		height: "100%",
		overflowY: "scroll",
		...theme.scrollbarStyles,
	},

	contactAvatar: {
		margin: 15,
		width: 100,
		height: 100,
	},

	contactHeader: {
		display: "flex",
		padding: 8,
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		"& > *": {
			margin: 4,
		},
	},

	contactDetails: {
		marginTop: 8,
		padding: 8,
		display: "flex",
		flexDirection: "column",
	},
	contactExtraInfo: {
		marginTop: 4,
		padding: 6,
	},
	btnWrapper: {
		width: "100%"
	},
	arrayFields: {
		width: '100%'
	}
}));
const ContactInfoModal = ({ open, onClose, onSave, contactId, handleDrawerClose, initialValues, loading }) => {

	const classes = useStyles();
	const isMounted = useRef(true);
	const { user: loggedInUser } = useContext(AuthContext);
	const [modalOpen, setModalOpen] = useState(false);
	const initialState = {
		adminId: loggedInUser.adminId,
		name: "",
		number: "",
		email: "",
		forAll: true,
		rg: "",
		cpf: "",
		empresa:"",
		connection: "",
		nasc: "",
		endereco: "",
		extraInfo: [],
		tagsId: [],
		createdBy: "",
		isGroup: "",
		profilePicUrl: "",
		queueId: "",
		modulesId: 1
	};
	const [contact, setContact] = useState(initialState);
	useEffect(() => {
		return () => {
			isMounted.current = false;
		};
	}, []);

	useEffect(() => {
		const fetchContact = async () => {
			if (initialValues) {
				setContact(prevState => {
					return { ...prevState, ...initialValues };
				});
			}

			if (!contactId) return;

			try {
				const { data } = await api.get(`/contacts/${contactId}`);

				if (isMounted.current) {
					let rg = '';
					let cpf = '';
					let empresa = '';
					let nasc = '';
					let endereco = '';

					// mapear campos extras dos contatos
					if (data.extraInfo) {
						data.extraInfo.map(item => {
							switch (item.name) {
								case 'rg':
									rg = item.value;
									break;
								case 'cpf':
									cpf = item.value;
									break;
								case 'empresa':
									empresa = item.value;
									break;
								case 'nasc':
									nasc = item.value;
									break;
								case 'endereco':
									endereco = item.value;
									break;
							}
						});
					}

					let dados = {
						"id": data.id,
						"adminId": data.adminId,
						"name": data.name,
						"number": data.number,
						"email": data.email,
						"rg": rg,
						"cpf": cpf,
						"empresa": empresa,
						"nasc": nasc,
						"endereco": endereco,
						"forAll": data.forAll,
						"createdBy": data.createdBy,
						"connection": data.connection,
						"profilePicUrl": data.profilePicUrl,
						"isGroup": data.isGroup,
						"createdAt": data.createdAt,
						"updatedAt": data.updatedAt,
						"extraInfo": [
							{
								"name": "rg",
								"value": rg
							},
							{
								"name": "cpf",
								"value": cpf
							},
							{
								"name": "empresa",
								"value": empresa
							},
							{
								"name": "nasc",
								"value": nasc
							},
							{
								"name": "endereco",
								"value": endereco
							}
						],
						"tagsId": data?.tagsId || "",
						"queueId": data?.queueId || "",
						"modulesId": data?.modulesId || "",
					}
					setContact(dados);
				}
			} catch (err) {
				console.error({err});
				toastError(err);
			}
		};

		fetchContact();
	}, [contactId, open, initialValues]);


	const handleClose = () => {
		handleDrawerClose();
		modalInfoClose();
		setContact(initialState);
	};

	const handleSaveContact = async values => {
		setContact(values);
		try {
			if (contactId) {
				handleClose();
				await api.put(`/contacts/${contactId}`, values);
			} else {
				const { data } = await api.post("/contacts", values);
				if (onSave) {
					onSave(data);
				}
				handleClose();
			}

			toast.success(i18n.t("contactModal.success"));
		} catch (err) {
			console.error({err});
			toastError(err);
		}
	};

	if (open) {
		if (window.innerWidth > 560) {
			//document.getElementById('modalContactInfotrue').style.width = '0px'
		}

	}
	function modalInfoClose() {
		if (window.innerWidth > 560) {
			document.getElementById('modalContactInfotrue').style.width = '320px'
		}
	}

	useEffect(() => {
		if (open) {
			document.addEventListener("mouseup", function (e) {
				var obj = document.getElementById("modalContactInfotrue");
				if(obj){
					if (!obj.contains(e.target)) {
						handleDrawerClose(e);
						modalInfoClose(e);
					}
				}
			});
		}
	}, [open]);

	return (
		<Drawer
			className={classes.drawer}
			id="modalContactInfotrue"
			variant="persistent"
			anchor="right"
			open={open}
			PaperProps={{ style: { position: "absolute" } }}
			BackdropProps={{ style: { position: "absolute" } }}
			ModalProps={{
				container: document.getElementById("drawer-container"),
				style: { position: "absolute" },
			}}
			classes={{
				paper: classes.drawerPaper,
			}}
		>
			<div className={classes.header}>
				<IconButton
					onClick={(e) => {
						handleDrawerClose(e);
						modalInfoClose(e);
					}}>
					<CloseIcon />
				</IconButton>
				<Typography style={{ justifySelf: "center" }}>
					{i18n.t("contactDrawer.header")}
				</Typography>
			</div>
			{loading ? (
				<ContactDrawerSkeleton classes={classes} />
			) : (
				<div className={classes.content}>
					<Paper square variant="outlined" className={classes.contactHeader}>
						<Avatar
							alt={contact.name}
							src={contact.profilePicUrl}
							className={classes.contactAvatar}
						></Avatar>
						<Typography>{contact.isGroup ? i18n.t('groupPrefix') : ''}{contact.name}</Typography>
						<Typography>
							<Link href={`https://api.whatsapp.com/send?phone=${contact.number}`}>{contact.number}</Link>
						</Typography>
						<Formik
							initialValues={contact}
							enableReinitialize={true}
							onSubmit={(values, actions) => {

								if (!values.name || String(values.name).trim() == '') { toastError({ response: { data: { error: `Nome do contato é obrigatório.` } } }); actions.setSubmitting(false); return;}
								if ((!values?.modulesId || values?.modulesId == 1) && (!values.number || String(values.number).trim() == '')) { toastError({ response: { data: { error: `Número do contato é obrigatório.` } } }); actions.setSubmitting(false); return;}

								setTimeout(() => {
									let rg = String(values.rg).trim();
									let empresa = String(values.empresa).trim();
									let cpf = String(values.cpf).trim();
									let nasc = String(values.nasc).trim();
									let endereco = String(values.endereco).trim();

									let dados = {
										"id": values.id,
										"adminId": values.adminId,
										"name": values.name,
										"number": values.number,
										"email": values.email,
										"forAll": values.forAll,
										"connection": values.connection,
										"profilePicUrl": values.profilePicUrl,
										"isGroup": values.isGroup,
										"createdAt": values.createdAt,
										"updatedAt": values.updatedAt,
										"extraInfo": [
											{
												"name": "rg",
												"value": rg,
												"contactId": values.id
											},
											{
												"name": "empresa",
												"value": empresa,
												"contactId": values.id
											},
											{

												"name": "cpf",
												"value": cpf,
												"contactId": values.id
											},
											{

												"name": "nasc",
												"value": nasc,
												"contactId": values.id
											},
											{

												"name": "endereco",
												"value": endereco,
												"contactId": values.id
											}
										],
										"tagsId": values?.tagsId || "[]",
										"createdBy": values?.createdBy || "",
										"modulesId": values?.modulesId || "",
									}
									handleSaveContact(dados);
									actions.setSubmitting(false);
								}, 400);
							}}
						>
							{({ values, errors, touched, isSubmitting }) => {
								return ((
									<Form>
										<Field

											type="hidden"
											name="adminId"
										/>
										<DialogContent dividers>
											<Typography variant="subtitle1" gutterBottom>
												{i18n.t("contactModal.form.mainInfo")}
											</Typography>
											<Field
												placeholder={`i18n.t("contactModal.form.name")`}
												name="name"
												autoFocus

												variant="outlined"
												margin="dense"
												style={{ display: "none" }}

											/>
											
											<Field
												name="number"

												placeholder="Cod. País + Cod. Estado + número"
												variant="outlined"
												margin="dense"
												style={{ display: "none" }}
											/>


											<div className="form-row">

												<Field
													style={{
														borderColor: '#c4c4c4',
														borderRadius: '3px',
														margin: '5px'
													}}
													className='form-control '
													name="name"
													placeholder="Nome"
													fullWidth
													margin="dense"
													variant="outlined"
													maxLength={200}
												/>
												<Field
													style={{
														borderColor: '#c4c4c4',
														borderRadius: '3px',
														margin: '5px'
													}}
													className='form-control '
													name="email"
													placeholder="Email"
													fullWidth
													margin="dense"
													variant="outlined"
													pattern="[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)+(\.[a-zA-Z]{2,})?"
													maxLength={200}
												/>
											

												<FieldArray name="extraInfo">
													{
														<>
															
														
															<Field
																style={{
																	borderColor: '#c4c4c4',
																	borderRadius: '3px',
																	margin: '5px'
																}}
																className='form-control '
																variant="outlined"
																margin="dense"
																name="rg"
																placeholder="RG"
																type="text"
																maxLength={50}

															/>
															<Field
																style={{
																	borderColor: '#c4c4c4',
																	borderRadius: '3px',
																	margin: '5px'
																}}
																variant="outlined"
																margin="dense"
																name="cpf"
																placeholder="CPF"
																type="text"
																className='form-control'
																maxLength={15}
															/>
															<Field
																style={{
																	borderColor: '#c4c4c4',
																	borderRadius: '3px',
																	margin: '5px'
																}}
																className='form-control '
																name="empresa"
																placeholder="Empresa"
																fullWidth
																margin="dense"
																variant="outlined"
																maxLength={100}
															/>

															<Field
																style={{
																	borderColor: '#c4c4c4',
																	borderRadius: '3px',
																	margin: '5px'
																}}
																variant="outlined"
																margin="dense"
																name="nasc"
																placeholder="Data de nascimento"
																type="text"
																className='form-control'
																maxLength={50}
															/>
															<Field
																style={{
																	borderColor: '#c4c4c4',
																	borderRadius: '3px',
																	margin: '5px'
																}}
																variant="outlined"
																margin="dense"
																name="endereco"
																placeholder="Endereço"
																type="text"
																className='form-control'
																maxLength={200}
															/>
														</>
													}
												</FieldArray>
											</div>
										</DialogContent>
										<DialogActions>
											<Button
												type="submit"
												color="primary"
												disabled={isSubmitting}
												variant="contained"
												className={classes.btnWrapper}
											>
												{contactId && 
													`${i18n.t("contactDrawer.buttons.edit")}`
												}
												{isSubmitting && (
													<CircularProgress
														size={24}
														className={classes.buttonProgress}
													/>
												)}
											</Button>
											
										</DialogActions>
									</Form>
								))
							}}
						</Formik>
					</Paper>
				</div>
			)}
		</Drawer>
	);
};

export default ContactInfoModal;
