import Checkbox from '@material-ui/core/Checkbox';
import Chip from "@material-ui/core/Chip";
import Fade from '@material-ui/core/Fade';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from "@material-ui/core/Typography";
import { makeStyles } from '@material-ui/core/styles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import BlockIcon from '@material-ui/icons/Block';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import SearchIcon from "@material-ui/icons/Search";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useReducer, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import TicketsTagSelect from '../../components/TicketsTagSelect';
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import CheckSupportEmail from '../../helpers/CheckSupportEmail';

import {
  Avatar,
  Button,
  FormControl,
  InputAdornment,
  Menu,
  MenuItem,
  Select,
  TextField
} from '@material-ui/core/';

import "./style.css";

import { AuthContext } from "../../context/Auth/AuthContext";
import { WhatsAppsContext } from "../../context/WhatsApp/WhatsAppsContext";

//Modal's
import ConfirmationImportModal from "../../components/ConfirmationImportModal";
import ContactModal from "../../components/ContactModal";
import DeleteSelectedsContacts from "../../components/DeleteSelectedsContacts";
import ImportContactsFromFileModal from "../../components/ImportContactsFromFileModal";
import NewTicketModal from "../../components/NewTicketModal";
import socket from '../../hooks/useSocket';

const reducer = (state, action) => {

  if (action.type === "LOAD_CONTACTS") {
    const contacts = action.payload;
    if (!contacts) return [];
    const newContacts = [];
    state = [];

    contacts.forEach((contact) => {
      const contactIndex = state.findIndex((c) => c.id === contact.id);
      if (contactIndex !== -1) {
        state[contactIndex] = contact;
      } else {
        newContacts.push(contact);
      }
    });

    return [...state, ...newContacts];
  }

  if (action.type === "UPDATE_CONTACTS") {
    const contact = action.payload;
    const contactIndex = state.findIndex((c) => c.id === contact.id);

    if (contactIndex !== -1) {
      state[contactIndex] = contact;
      return [...state];
    } else {
      return [contact, ...state];
    }
  }

  if (action.type === "DELETE_CONTACT") {
    const contactId = action.payload;

    const contactIndex = state.findIndex((c) => c.id === contactId);
    if (contactIndex !== -1) {
      state.splice(contactIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: 'channel', numeric: false, disablePadding: false, label: i18n.t("contacts.table.channel") },
  { id: 'name', numeric: false, disablePadding: true, label: i18n.t("contacts.table.name") },
  { id: 'number', numeric: false, disablePadding: false, label: i18n.t("contacts.table.origem") },
  { id: 'email', numeric: false, disablePadding: false, label: i18n.t("contacts.table.email") },
  { id: 'extraInfo', numeric: false, disablePadding: false, label: i18n.t("contacts.table.company") },
  { id: 'createdByName', numeric: false, disablePadding: false, label: i18n.t("contacts.table.createdBy") },
  { id: 'connection', numeric: false, disablePadding: false, label: i18n.t("contacts.table.connection") },
  { id: 'actions', numeric: true, disablePadding: false, label: i18n.t("contacts.table.actions") },
];

function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <>
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ 'aria-label': 'select all desserts' }}
            />
          </TableCell>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    </>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({

  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
  modalStyle: {
    padding: '20px 10px',
    maxheight: '95vh',
    minWidth: '310px',
    minWidth: '670px',
    "@media (max-width: 740px)": {
      padding: '0px !important',
      marginTop: '65px'
    }
  },

  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },

  tagsRow: {
    position: "absolute",
    width: "100%",
    left: 0,
    height: "20px",
    lineHeight: "20px",
    margin: 0,
    padding: 0,
    bottom: 0,
    textIndent: "10px"
  },
}));



export default function EnhancedTable() {
  const classes = useStyles();
  const history = useHistory();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const { user } = useContext(AuthContext);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [loading, setLoading] = useState(false);
  const [importContactsModal, setImportContactsModal] = useState(false);
  const [confirmModalDeleteAllOpen, setConfirmModalDeleteAllOpen] = useState(false);
  const [selectedWppFilter, setSelectedWppFilter] = useState("not_defined");
  const [selectedUserFilter, setSelectedUserFilter] = useState("not_defined_user");
  const [searchConnection, setSearchConnection] = useState("");
  const [searchUser, setSearchUser] = useState("");
  const [searchParam, setSearchParam] = useState("");
  const [searchForParam, setSearchForParam] = useState(false);
  const { whatsApps, loading2 } = useContext(WhatsAppsContext);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [deletingContact, setDeletingContact] = useState(null);
  const [newTicketModalOpen, setNewTicketModalOpen] = useState(false);
  const [contactTicket, setContactTicket] = useState({})
  const [selectedContactId, setSelectedContactId] = useState(null);
  const [contactModalOpen, setContactModalOpen] = useState(false);
  const [users, setUsers] = useState([]);
  const [contacts, dispatch] = useReducer(reducer, []);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [tags, setTags] = useState([]);
  const [tagsFilter, setTagsFilter] = useState([]);
  const open = Boolean(anchorEl);

  async function getTags() {
    try {
      const { data } = await api.get("/tags");
      if (!data || !data.tags) data.tags = [];
      if (data.tags) data.tags.unshift({ id: 0, adminId: user.adminId, color: '#000000', name: 'sem etiqueta', updatedBy: user.id, status: 1, });
      setTags(data.tags)
      const tagsMapped = data?.tags?.map(t => t.id) || [];
      setTagsFilter(tagsMapped);
    } catch (error) {
      console.error(error)
    } finally {
      setPage(0);
    }
  }

  function filterContactsByTags(contacts) {
    if (!contacts || contacts.length < 1) return [];
    let filteredContacts = contacts;
    if (tagsFilter) {
      filteredContacts = filteredContacts.filter((contact) => {
        if (contact?.tagsId && Array.isArray(contact?.tagsId) && contact?.tagsId?.length > 0) {
          const hasMatchingTag = contact.tagsId.some(tag => tagsFilter.includes(tag.id));
          return hasMatchingTag;
        } else {
          if (tagsFilter.includes(0)) return true;
          return false;
        }
      });
    }
    return filteredContacts;
  }

  useEffect(() => {
    getTags();
  }, []);


  useEffect(() => {
    dispatch({ type: "RESET" });
  }, [searchForParam, searchConnection, searchUser]);

  useEffect(() => {

    socket.on(`contact-${user.adminId}`, (data) => {
      if (data.action === "update" || data.action === "create") {
        dispatch({ type: "UPDATE_CONTACTS", payload: data.contact });
      }
      if (data.action === "delete") {
        dispatch({ type: "DELETE_CONTACT", payload: +data.contactId });
      }
    });

    return () => {};
  }, []);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const hadleEditContact = (contactId) => {
    setSelectedContactId(contactId);
    setContactModalOpen(true);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const [quant, setQuant] = useState(0)

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      let filtered = []
      contacts.forEach((contacts, i) => {
        if (i >= 1000) return;

        if (!(user.profile === 'user' && contacts.createdBy !== user.id)) {
          filtered.push(contacts)
        }
      });
      const newSelecteds = filtered.map((n) => n.id);
      setQuant(newSelecteds.length)
      setSelected(newSelecteds);

      if (newSelecteds.length < contacts.length)
        toast.error('O limite para seleção em massa é de 1.000 contatos por vez.');

      return;
    } else {
      setQuant(0);
      setSelected([]);
    }
  };

  useEffect(() => {
    setLoading(true);
    setPage(0);
    const delayDebounceFn = setTimeout(() => {
      const fetchContacts = async () => {
        try {
          const { data } = await api.get("/contacts/", {
            params: { searchParam, searchConnection, searchUser },
          });
          let filteredContacts = filterContactsByTags(data.contacts);
          dispatch({ type: "LOAD_CONTACTS", payload: filteredContacts });
          setLoading(false);
        } catch (err) {
          console.error({err});
          toastError(err);
        }
      };
      const fetchUsers = async () => {
        try {
          const { data } = await api.get("/usersList/");
          setUsers(data)
          setPage(0);
          setLoading(false);
        } catch (err) {
          console.error({err});
          setLoading(false);
          toastError(err);
        }
      };
      fetchUsers();
      fetchContacts();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchForParam, searchConnection, searchUser, tagsFilter]);


  const handleCloseOrOpenTicket = (ticket) => {
    setNewTicketModalOpen(false);
    if (ticket !== undefined && ticket.uuid !== undefined) {
      history.push(`/tickets/${ticket.uuid}`);
    }
  }

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const handleSearch = (str) => {
    setSearchParam(str.toLowerCase());
  };

  const handleChange = async (event) => {
    setSelected([]);
    setQuant(0);
    setPage(0);
    if (event.target.value === "not_defined") {
      setSelectedWppFilter("not_defined");
      setSearchConnection("")
    } else {
      setSearchConnection(event.target.value)
      setSelectedWppFilter(event.target.value);
    }
  };

  const handleOpenContactModal = () => {
    setSelectedContactId(null);
    setContactModalOpen(true);
  };

  const handleCloseContactModal = () => {
    setSelectedContactId(null);
    setContactModalOpen(false);
  };

  const handleCloseImportContactModal = () => setImportContactsModal(false);
  const handleOpenImportContactModal = () => setImportContactsModal(true);


  const handleChangeUser = async (event) => {
    setSelected([]);
    setQuant(0);
    setPage(0);
    if (event.target.value === "not_defined_user") {
      setSelectedUserFilter("not_defined_user");
      setSearchUser("")
    } else {
      setSearchUser(event.target.value)
      setSelectedUserFilter(event.target.value);
    }
  };

  const restartContacts = (e) => {
    if (e === true) {
      setPage(0);
      setLoading(true);
      const fetchContacts = async () => {
        try {
          const { data } = await api.get("/contacts/", {
            params: { searchParam, searchConnection },
          });
          let filteredContacts = filterContactsByTags(data.contacts);
          dispatch({ type: "LOAD_CONTACTS", payload: filteredContacts });
        } catch (err) {
          console.error({err});
          toastError(err);
        }
        setLoading(false);
      };
      fetchContacts();
    }
  }

  const deleteSelecteds = () => {
    const exclude = async () => {
      try {
        await api.delete(`/contactsDelete/`, { params: { selectedContacts: JSON.stringify(selected) } });
        setSearchParam('')
        toast.success('Seus contatos estão sendo deletados em background.');
        setSelected([])
      } catch (err) {
        console.error({err});
        toastError(err);
      }
    }
    exclude();
  }

  const handleimportContact = async (values) => {
    try {
      await api.post("/contacts/import", values);
      history.go("/contacts");
    } catch (err) {
      console.error({err});
      toastError(err);
    }
  };

  const handleDeleteContact = async (contactId) => {
    try {
      await api.delete(`/contacts/${contactId}`);
      toast.success(i18n.t("contacts.toasts.deleted"));
    } catch (err) {
      console.error({err});
      toastError(err);
    }
    setDeletingContact(null);
    setSearchParam("");
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const unifyContacts = async () => {
    try {
      const { data } = await api.post("/unifyContacts");
      toast.success('Seus contatos estão sendo unificados em background.');
    } catch (err) {
      console.error({err});
      toastError('Erro ao unificar contatos.')
    }
  }

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, contacts.length - page * rowsPerPage);

  const EnhancedTableToolbar = (props) => {
    const { numSelected } = props;
    return (
      <Tooltip title="Deletar">
        <Button onClick={e => setConfirmModalDeleteAllOpen(true)} variant="contained" color="secondary">
          <DeleteIcon />&nbsp;{numSelected}&nbsp;{numSelected === 1 ? 'selecionado' : 'selecionados'}
        </Button>
      </Tooltip>
    );
  };


  return (
    <>
      <NewTicketModal
        modalOpen={newTicketModalOpen}
        initialContact={contactTicket}
        onClose={(ticket) => {handleCloseOrOpenTicket(ticket);}} 
        connections={whatsApps.filter(wpp => wpp.numberConnection == contactTicket.connection) || []}
        queues={user?.queues || []}
      />
      <ContactModal
        open={contactModalOpen}
        onClose={handleCloseContactModal}
        aria-labelledby="form-dialog-title"
        contactId={selectedContactId}
        tags={tags.filter((tag) => tag.id >= 1)}
      />
      <ImportContactsFromFileModal
        open={importContactsModal}
        onClose={handleCloseImportContactModal}
        onConfirm={e => restartContacts(e)}
      />
      <ConfirmationImportModal
        title={deletingContact
          ? `${i18n.t("contacts.confirmationModal.deleteTitle")} ${deletingContact.name}?`
          : `${i18n.t("contacts.confirmationModal.importTitlte")}`}
        open={confirmOpen}
        onClose={() => { setConfirmOpen(false); }}
        contactToExcludeId={deletingContact?.id}
        onConfirm={(e) => deletingContact
          ? handleDeleteContact(deletingContact.id)
          : handleimportContact(e)}
        isDelete={deletingContact ? true : false}
      >
        {deletingContact
          ? `${i18n.t("contacts.confirmationModal.deleteMessage")}`
          : `${i18n.t("contacts.confirmationModal.importMessage")}`}
      </ConfirmationImportModal>
      <DeleteSelectedsContacts
        title={'Confirmar'}
        open={confirmModalDeleteAllOpen}
        onClose={setConfirmModalDeleteAllOpen}
        onConfirm={(e) => deleteSelecteds(e)}
      >
        Você Deseja Excluir {selected.length} contatos?
      </DeleteSelectedsContacts><>
        <div class='row gy-5 gx-xl-8'
          className={classes.modalStyle}
          id="box"
        >
          <div className='col-xl-15'>
            <div className='card card-xxl-stretch-5 mb-15 mb-xl-8'>
              {/* begin::Header */}
              <div className='card-header border-0 pt-5'>
                <h3 className='card-title align-items-start flex-column'>
                  <span className='card-label fw-bolder fs-3 mb-1'>Contatos</span>
                </h3>
                <div
                  className='card-toolbar '
                  data-bs-toggle='tooltip'
                  data-bs-placement='top'
                  data-bs-trigger='hover'
                >

                  <FormControl variant="outlined">
                    <Select
                      value={selectedWppFilter}
                      displayEmpty
                      style={{
                        padding: '1px 0px 4px',
                        margin: '4px 10px -2px 0px',
                        height: '30px',
                        width: '190px',
                        fontSize: '15px'
                      }}
                      onChange={e => handleChange(e)}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}

                    >
                      <MenuItem value="not_defined">
                        <em>{i18n.t("contacts.filters.connections")}</em>
                      </MenuItem>
                      {whatsApps.map((wpp, key) => (
                        <MenuItem key={key} value={wpp.numberConnection}>
                          <b>{wpp.name}</b>&nbsp; - {wpp.state == false || wpp.numberConnection == '' ? 'Sem conexão' : wpp.numberConnection}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl variant="outlined">
                    <Select
                      value={selectedUserFilter}
                      displayEmpty
                      style={{
                        padding: '1px 0px 4px',
                        margin: '4px 2px -2px 0px',
                        height: '30px',
                        width: '190px',
                        fontSize: '15px'
                      }}
                      inputProps={{
                        style: {
                          padding: 5
                        }
                      }}
                      onChange={e => handleChangeUser(e)}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}

                    >
                      <MenuItem value="not_defined_user">
                        <em>{i18n.t("contacts.filters.users")}</em>
                      </MenuItem>
                      {users && users.map((userData, key) => (
                        <MenuItem key={key} value={userData.id}>
                          <b>{userData.name}</b>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <Box style={{ margin: "0 10px" }}>
                    <TicketsTagSelect
                      selectedTagsIds={tagsFilter}
                      userTags={tags}
                      onChange={(e) => setTagsFilter(e)}
                      useText={
                        <Button variant="outlined" style={{ width: "100%", height: "35px", color: "rgba(0, 0, 0, .8)", paddingRight: "30px", fontStyle: "italic" }}>
                          {i18n.t("contacts.filters.tags")}
                          <ArrowDropDownIcon style={{ position: "absolute", right: "2px", color: "rgba(0,0,0,.55)" }} />
                        </Button>}
                    />
                  </Box>

                  <TextField
                    placeholder={i18n.t("contacts.filters.searchPlaceholder")}
                    type="search"
                    value={searchParam}
                    style={{ marginRight: '10px' }}
                    onChange={(e) => {
                      handleSearch(e.target.value);
                    }}
                    onKeyUp={(e) => {
                      if (e.target.value == '') {setSearchForParam(!searchForParam);return;}
                      const key = e.charCode || e.keyCode || 0;
                      if (key === 13) {
                        e.preventDefault();
                        setSearchForParam(!searchForParam);
                      }
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start" onClick={(e) => { setSearchForParam(!searchForParam); }} style={{ cursor: "pointer" }}>
                          <Tooltip
                            title={<Typography gutterBottom color="inherit">{i18n.t("contacts.filters.searchTooltip")}</Typography>}
                            placement="bottom"
                          >
                            <SearchIcon style={{ color: "gray" }} />
                          </Tooltip>
                        </InputAdornment>
                      ),
                    }} />

                  <div>
                    {selected.length === 0 ?
                      <div style={{ display: 'flex' }}>
                        <Button
                          type="button"
                          style={{
                            marginLeft: "1.5px",
                            marginRight: "1.5px",
                            height: '33px'
                          }}
                          variant="contained"
                          color="primary"
                          onClick={handleOpenContactModal}
                        >
                          <RecentActorsIcon />
                          &nbsp;
                          {i18n.t("contacts.buttons.newContact")}
                        </Button>
                        <div>
                          <Button
                            aria-controls="customized-menu"
                            aria-haspopup="true"
                            variant="contained"
                            color="primary"
                            onClick={handleMenuClick}
                          >
                            {i18n.t("contacts.buttons.importContacts")}
                          </Button>
                          <Menu
                            id="fade-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={open}
                            onClose={handleMenuClose}
                            TransitionComponent={Fade}
                          >
                            <MenuItem onClick={(e) => { setConfirmOpen(true); setDeletingContact(null); handleMenuClose(e); }}><WhatsAppIcon /> &nbsp;Importar do WhatsApp</MenuItem>
                            <MenuItem onClick={(e) => { handleMenuClose(e); handleOpenImportContactModal(e); }}> <FileCopyIcon />&nbsp;Importar de arquivo</MenuItem>
                          </Menu>
                        </div>
                      </div>
                      :
                      <div>
                        <EnhancedTableToolbar numSelected={selected.length} />
                      </div>}
                  </div>
                </div>
              </div>
              {/* end::Header */}
              {/* begin::Body */}
              <div className='card-body py-3' id="body_component">
                {/* begin::Table container */}
                <div className='table-responsive'>
                  <TableContainer>
                    <Table
                      className={classes.table}
                      aria-labelledby="tableTitle"
                      size={dense ? 'small' : 'medium'}
                      aria-label="enhanced table"
                    >
                      <EnhancedTableHead
                        classes={classes}
                        numSelected={selected.length >= quant ? selected.length : quant}
                        order={order}
                        orderBy={orderBy}
                        onSelectAllClick={e => { handleSelectAllClick(e); }}
                        onRequestSort={handleRequestSort}
                        rowCount={contacts.length} />
                      {loading ? <>
                        <TableRowSkeleton avatar columns={4} />
                      </>
                        :
                        <>
                          <TableBody>
                            {stableSort(contacts, getComparator(order, orderBy))
                              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                              .map((row, index) => {
                                const isItemSelected = isSelected(row.id);
                                const labelId = `enhanced-table-checkbox-${index}`;
                                return (
                                  <>
                                    <TableRow
                                      hover
                                      role="checkbox"
                                      aria-checked={isItemSelected}
                                      tabIndex={-1}
                                      key={row.id}
                                      selected={isItemSelected}
                                      style={{ position: "relative", height: "70px" }}
                                    >
                                      {user.profile === 'user' && row.createdBy !== user.id ?
                                        <div
                                          style={{ padding: '15px 0px 0px 14px' }}
                                        >
                                          <BlockIcon />
                                        </div>
                                        :
                                        <TableCell padding="checkbox"
                                          onClick={(event) => { handleClick(event, row.id); }}>
                                          <Checkbox
                                            checked={isItemSelected}
                                            inputProps={{ 'aria-labelledby': labelId }} />
                                        </TableCell>
                                      }
                                      <TableCell align="left">{row.moduleId == "2" ? "Instagram" : "WhatsApp"}</TableCell>
                                      <TableCell component="th" id={labelId} scope="row" padding="none">
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                          <div className='symbol symbol-45px me-5'>
                                            <Avatar style={{
                                              width: '35px',
                                              height: '35px'
                                            }} src={row.profilePicUrl} />
                                          </div>
                                          <div>
                                            {row.isGroup ? i18n.t('groupPrefix') : ''}{row.name}
                                          </div>
                                        </div>
                                      </TableCell>
                                      <TableCell align="left">{row.number}</TableCell>
                                      <TableCell align="left">{row.email}</TableCell>
                                      <TableCell align="left">{row.extraInfo.length > 1 ? row.extraInfo[1].value : ''}</TableCell>
                                      <TableCell align="left">{row.createdByName}</TableCell>
                                      <TableCell align="left">{row.connection}</TableCell>
                                      <td style={{ width: '135px' }}>
                                        <div style={{ width: '125px', padding: '5px 0px 5px 0px' }} className='d-flex justify-content-end flex-shrink-0'>
                                          {(row.moduleId != 2) &&
                                          <a
                                            href='#'
                                            title={i18n.t("contacts.titles.startConversation")}
                                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                            onClick={() => {
                                              const matchWhatsApp = whatsApps.find(
                                                (wpp) => wpp.numberConnection === row.connection
                                              );
                                              
                                              if (!matchWhatsApp || !matchWhatsApp?.status || String(matchWhatsApp?.status).toLowerCase() != 'connected' ) {
                                                toast.error("A conexão está indisponível para interagir.");
                                                return;
                                              }

                                              setContactTicket(row)
                                              setNewTicketModalOpen(true)
                                            }}
                                          >
                                            <WhatsAppIcon />
                                          </a>
                                          }
                                          <button
                                            href='#'
                                            title={i18n.t("contacts.titles.edit")}
                                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                            onClick={() => hadleEditContact(row.id)}
                                          >
                                            <EditIcon />
                                          </button>
                                          {!(user.profile === 'user' && row.createdBy !== user.id) &&
                                            <>
                                              <button
                                                title={i18n.t("contacts.titles.delete")}
                                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                                onClick={(e) => {
                                                  setConfirmOpen(true);
                                                  setDeletingContact(row);
                                                }}
                                              >
                                                <DeleteIcon />
                                              </button>
                                            </>
                                          }
                                        </div>
                                      </td>
                                      {(row.tagsId && Array.isArray(row.tagsId)) &&
                                        <TableCell className={classes.tagsRow}>
                                          {Array.isArray(row?.tagsId) && row.tagsId.map((tag) => (
                                            <Tooltip
                                              arrow
                                              className={classes.tooltip}
                                              title={<Typography fontSize={20}>{tag.name || "Não identificado"}</Typography>}
                                            >
                                              <Chip
                                                size="small"
                                                style={{
                                                  borderRadius: '5px',
                                                  height: '10px',
                                                  padding: '0px 10px',
                                                  margin: '0px 3px',
                                                  backgroundColor: tag.color,
                                                  '& .MuiChip-label': {
                                                    paddingLeft: '5px',
                                                    paddingRight: '5px',
                                                  },
                                                }}
                                              />
                                            </Tooltip>
                                          ))}
                                        </TableCell>
                                      }
                                    </TableRow>
                                  </>
                                );
                              })}
                          </TableBody>
                        </>}
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 50, 100, 200]}
                    component="div"
                    count={contacts.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    labelRowsPerPage={"Resultados por página"}
                    labelDisplayedRows={({ from, to, count }) => `${'Total'} ${count} / ${'De'} ${from} ${'à'} ${to}`}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage} />

                  {/* end::Table */}
                </div>

                {/* end::Table container */}
              </div>
              {/* begin::Body */}
            </div>
          </div>
        </div>
        {
          CheckSupportEmail(user.email)
          &&
          <Button
            style={{ position: "absolute", bottom: "5px", right: "5px" }}
            variant="contained"
            color="primary"
            onClick={() => unifyContacts()}
          >
            unificar contatos
          </Button>
        }
      </>
    </>
  );
}
