import { Typography, Button, Menu, MenuItem, Checkbox, FormControlLabel } from "@material-ui/core";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { useState, useCallback, useMemo, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { i18n } from "../../translate/i18n";

const useStyles = makeStyles(theme => ({
    filterButtons: {
        width: "100%",
        minWidth: "120px",
        height: "43px",
        color: "rgba(0, 0, 0, .8)!important",
        paddingRight: "30px",
        marginBottom: "10px",
        textAlign: "left",
        border: "1px solid rgb(196, 196, 196)!important",
        borderRadius: "3px",
        borderBottom: "1px solid #757575!important",
        display: "block!important",
        '&:disabled': {
            color: "rgba(0, 0, 0, .3)!important",
        }
    },
    filterButtonsIcon: {
        position: "absolute",
        right: "2px",
        color: "rgba(0,0,0,.55)!important"
    },
}));

const MultiSelect = ({ title, displayText, options, disabled, onSelected, selecteds }) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedOptions, setSelectedOptions] = useState(selecteds || []);

    const isAllSelected = useMemo(() => selectedOptions.length === options.length, [selectedOptions, options.length]);

    useEffect(() => {
        if (Array.isArray(selecteds)) {
            setSelectedOptions(selecteds);
        }
    }, [selecteds]);

    const handleMenuClose = useCallback(() => {
        setAnchorEl(null);
        onSelected(selectedOptions);
    }, [selectedOptions, onSelected]);

    const handleMenuClick = useCallback((event) => {
        setAnchorEl(event.currentTarget);
    }, []);

    const handleOptionToggle = useCallback((option) => {
        setSelectedOptions(prevSelected => 
            prevSelected.includes(option) ? prevSelected.filter(opt => opt !== option) : [...prevSelected, option]
        );
    }, []);

    const handleSelectAllToggle = useCallback(() => {
        setSelectedOptions(isAllSelected ? [] : options.map(opt => opt.key));
    }, [isAllSelected, options]);

    const renderDisplayText = () => {
        if (isAllSelected) return i18n.t("allSelected");
        if (!selectedOptions.length) return displayText;

        const selectedNames = options.filter(opt => selectedOptions.includes(opt.key)).map(opt => opt.value);
        return selectedNames.length > 0 ? selectedNames.join(', ') : displayText;
    };

    return (
        <>
            {title && <Typography style={{ marginBottom: "5px" }}>{title}</Typography>}

            <Button
                variant="outlined"
                onClick={handleMenuClick}
                disabled={disabled}
                className={classes.filterButtons}
            >
                {renderDisplayText()}
                <ArrowDropDownIcon className={classes.filterButtonsIcon} />
            </Button>

            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                PaperProps={{
                    style: {
                        maxHeight: "300px",
                        width: anchorEl?.offsetWidth || "300px",
                    },
                }}
            >
                <MenuItem dense key="select-all">
                    <FormControlLabel
                        control={
                            <Checkbox
                                size="small"
                                color="primary"
                                checked={isAllSelected}
                                onChange={() => { handleSelectAllToggle() }}
                            />
                        }
                        label={i18n.t("selectAll")}
                    />
                </MenuItem>
                {options
                    .sort((a, b) => a.value.localeCompare(b.value))
                    .map(opt => (
                        <MenuItem 
                            dense 
                            key={opt.key}
                        >
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        size="small"
                                        color="primary"
                                        checked={selectedOptions.includes(opt.key)}
                                        onChange={() => {handleOptionToggle(opt.key)}}
                                    />
                                }
                                label={opt.value}
                            />
                        </MenuItem>
                    ))}
            </Menu>
        </>
    );
};

export default MultiSelect;