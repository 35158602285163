import { useContext, useState } from "react";
import { useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";

import { AuthContext } from "../../context/Auth/AuthContext";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import ReopenTicketModal from "../ReopenTicketModal";

const useStyles = makeStyles(theme => ({
	actionButtons: {
		marginRight: 6,
		flex: "none",
		alignSelf: "center",
		"& > *": {
			margin: theme.spacing(1),
		},
	},
}));

const TicketActionButtons = ({ ticket }) => {
	const classes = useStyles();
	const history = useHistory();
	const [anchorEl, setAnchorEl] = useState(null);
	const [reopenTicketModalOpen, setReopenTicketModalOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const ticketOptionsMenuOpen = Boolean(anchorEl);
	const { user } = useContext(AuthContext);

	const handleUpdateTicketStatus = async (e, status, userId ) => {
		setLoading(true);
		try {
			await api.put(`/tickets/${ticket.id}`, {
				status: status,
				userId: userId || null,
				transfer: 1
			});
			setLoading(false);
			if (status === "open") {
				history.push(`/tickets/${ticket.uuid}`);
			} else {
				history.push("/tickets");
			}
		} catch (err) {
			console.error({err});
			setLoading(false);
			toastError(err);
		}
	};

	const reopenTicketOpenModal = () => {
		setReopenTicketModalOpen(true)
	}
	return (
		<>
			<ReopenTicketModal
				modalOpen={reopenTicketModalOpen}
				onClose={e => setReopenTicketModalOpen(false)}
				ticketId={ticket?.id}
			/>
			<div className={classes.actionButtons}>
				{/* {ticket.status === "closed" && (
					<ButtonWithSpinner
						style={{
							background: '#0b708c',
							padding: '10px 20px',
							borderRadius: '50px',
							color: '#fff',
							fontWeight: 600
						}}
						id="reopenButton"
						loading={loading}
						startIcon={<Replay />}
						size="small"
						onClick={e => reopenTicketOpenModal()}
					>
						{i18n.t("messagesList.header.buttons.reopen")}
					</ButtonWithSpinner>
				)} */}
				{/* {ticket.status === "pending" && (
					<ButtonWithSpinner
						style={{
							background: '#0b708c',
							padding: '10px 20px',
							borderRadius: '50px',
							color: '#fff',
							fontWeight: 600
						}}
						loading={loading}
						size="small"
						variant="contained"
						color="primary"
						onClick={e => handleUpdateTicketStatus(e, "open", user?.id)}
					>
						{i18n.t("messagesList.header.buttons.accept")}
					</ButtonWithSpinner>
				)} */}
			</div>
		</>
	);
};

export default TicketActionButtons;
