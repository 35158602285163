import { Grid } from "@material-ui/core";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/Auth/AuthContext";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import InfoIcon from '@material-ui/icons/Info';

const drawerWidth = 320;


const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const useStyles = makeStyles(theme => ({
    drawer: {
        width: 0,
        flexShrink: 0,
        transitionDuration: "0.3s"
    },
    drawerPaper: {
        width: drawerWidth,
        display: "flex",
        borderTop: "1px solid rgba(0, 0, 0, 0.12)",
        borderRight: "1px solid rgba(0, 0, 0, 0.12)",
        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
        borderTopRightRadius: 4,
        borderBottomRightRadius: 4,
    },
    header: {
        display: "flex",
        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
        backgroundColor: "#eee",
        alignItems: "center",
        padding: theme.spacing(0, 1),
        minHeight: "73px",
        justifyContent: "flex-start",
    },
    content: {
        display: "flex",
        backgroundColor: "#eee",
        flexDirection: "column",
        padding: "8px 0px 8px 8px",
        height: "100%",
        overflowY: "scroll",
        ...theme.scrollbarStyles,
    },

    contactAvatar: {
        margin: 15,
        width: 100,
        height: 100,
    },

    contactHeader: {
        display: "flex",
        padding: 8,
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        "& > *": {
            margin: 4,
        },
    },

    contactDetails: {
        marginTop: 8,
        padding: 8,
        display: "flex",
        flexDirection: "column",
    },
    contactExtraInfo: {
        marginTop: 4,
        padding: 6,
    },
    motivoInput: {
        height: "40px",
        width: "230px",
        borderRadius: "8px",
        borderColor: "#dfdfdf"
    },
    motivoBotao: {
        width: "min-content"
    }
}));

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

const BootstrapInput = withStyles(theme => ({
    root: { 'label + &': {} },
    input: {
        width: '100%',
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ced4da',
        fontSize: 16,
        padding: '10px 26px 10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}))(InputBase);

export default function CustomizedDialogs({ open, handleClose, onConcludeTicket }) {

    const classes = useStyles();
    const { user } = useContext(AuthContext);
    const [conclusionMessage, setConclusionMessage] = useState(null);
    const [selectedConclusionMessage, setSelectedConclusionMessage] = useState({ id: null, message: '' });
    const [reason, setReason] = useState("");

    useEffect(() => {
        if (open != 1) return;
        const fetchConclusionMessage = async () => {
            try {
                const { data } = await api.get("/conclusionMessagesAll/");
                setConclusionMessage(data.conclusionMessages);
            } catch (err) {
                console.error({err});
                toastError(err);
            }
        };
        fetchConclusionMessage();

    }, [open]);


    const handleConclusionMessage = (id) => {
        let find = conclusionMessage.find(cm => cm.id === id)
        setSelectedConclusionMessage({ id: find.id, message: find.message })
    }

    const handleUpdateTicketStatus = async (e, status, userId) => {
        if (!selectedConclusionMessage?.id) {
            toastError({ response: { data: { error: "ERR_CONCLUDE_FOUND" } } });
            return;
        }

        let message;

        if (selectedConclusionMessage?.message) {

            let data = selectedConclusionMessage.message;

            if (user?.signature && user?.name) data = `*${user.name.trim()}:*\n` + data.trim();

            message = {
                read: 1,
                fromMe: true,
                mediaInBody: "",
                body: data,
                cleanMsg: data.trim(),
                quotedMsg: null,
            };


        }

        onConcludeTicket("closed", {
            message, reason, userId: user.id, conclusionId: selectedConclusionMessage.id ? selectedConclusionMessage.id : null,
        });

    };

    const onClose = () => {
        setConclusionMessage(null);
        setReason("");
        setSelectedConclusionMessage({ id: null, message: '' });
        handleClose();
    }


    return (
        <div>
            <Dialog onClose={() => onClose()} aria-labelledby="customized-dialog-title" open={open}>
                <DialogTitle id="customized-dialog-title" onClose={() => onClose()}>
                    Finalização em Lote
                </DialogTitle>
                <DialogContent dividers>
                    <Grid container spacing={2} style={{color: "#b71c1c", marginBottom: "10px"}}>
                        <Grid item xs={12} mb={2}>
                            <InfoIcon style={{ display: "inline-block", marginRight: "10px" }} />
                            <Typography variant="body2" style={{ display: "inline-block" }}>
                                Ações em lote impossibilitam o envio de mensagens.
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid container spacing={2}>
                        <Grid item xs={12} mb={2}>
                            <label style={{ display: "block" }}>
                                Selecione uma finalização
                                <Typography component="span" style={{ marginLeft: '5px' }} color="secondary">*</Typography>
                            </label>
                            <Select
                                value={selectedConclusionMessage?.id}
                                onChange={e => handleConclusionMessage(e.target.value)}
                                input={<BootstrapInput />}
                                required
                                style={{width: "100%"}}
                            >
                                <MenuItem disabled value="">
                                    Selecione uma mensagem
                                </MenuItem>
                                {conclusionMessage?.map((msg, index) => (
                                    <MenuItem key={index} title={msg.message} value={msg.id}>
                                        {msg.shortcut}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>
                    </Grid>

                    <Grid container spacing={2}>
                        <Grid item xs={12} mb={2}>
                            <label>Descreva o motivo da finalização:</label>
                            <input
                                value={reason}
                                style={{
                                    borderColor: '#c4c4c4',
                                    borderRadius: '3px',
                                    width: '100%',
                                    height: '45px'
                                }}
                                type="text"
                                className='form-control'
                                onChange={e => setReason(e.target.value)}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => onClose()} color="primary">
                        Fechar
                    </Button>
                    <Button
                        className={classes.motivoBotao}
                        variant="contained"
                        color="primary"
                        onClick={e => handleUpdateTicketStatus(e, "closed", user?.id)}
                    >
                        {i18n.t("messagesList.header.buttons.conclude")}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
