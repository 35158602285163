import { i18n } from "../../translate/i18n";
import ChatIcon from '@material-ui/icons/Chat';
import FlashOnIcon from '@material-ui/icons/FlashOn';
import DoneIcon from '@material-ui/icons/Done';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import LabelIcon from '@material-ui/icons/Label';
import PeopleIcon from '@material-ui/icons/People';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import DashboardIcon from '@material-ui/icons/Dashboard';
import SettingsIcon from '@material-ui/icons/Settings';
import NearMeIcon from '@material-ui/icons/NearMe';
import BusinessIcon from '@material-ui/icons/Business';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import WebIcon from '@material-ui/icons/Web';

export const menuItems = [
  {
    text: i18n.t('menu.list.ticket'),
    to: '/tickets',
    icon: <ChatIcon />,
    name: 'tickets'
  },
  {
    text: i18n.t('menu.list.contact'),
    to: '/contacts',
    icon: <PeopleIcon />,
    name: 'contacts'
  },
  {
    text: i18n.t('menu.list.dashboard'),
    to: '/dashboard',
    icon: <DashboardIcon />,
    name: 'dashboard'
  },
  {
    text: i18n.t('menu.list.report.title'),
    icon: <EqualizerIcon />,
    name: 'relatory',
    items: [{
      text: i18n.t('menu.list.report.ticketsRelatory'),
      to: '/ticketRelatory',
      name: 'ticketRelatory'
    },{
      text: i18n.t('menu.list.report.tagsRelatory'),
      to: '/tagRelatory',
      name: 'tagRelatory'
    },{
      text: i18n.t('menu.list.report.generalCampaignsRelatory'),
      to: '/generalCampaignsRelatory',
      name: 'generalCampaignsRelatory'
    }]
  },
  {
    text: i18n.t('menu.list.campaign'),
    to: '/campaign',
    icon: <NearMeIcon />,
    name: 'campaign'
  },
];

export const adminItems = [
  {
    text: i18n.t('menu.list.admin.customerRelationship'),
    to: '/customerRelationship',
    icon: <WebIcon />,
    name: 'customer relationship manager'
  },
  {
    text: i18n.t('menu.list.admin.title'),
    icon: <SettingsIcon />,
    items: [{
      text: i18n.t('menu.list.admin.connection'),
      to: '/connections',
      name: 'connections'
    }, {
      text: i18n.t('menu.list.admin.queue'),
      to: '/queues',
      name: 'queues'
    }, {
      text: i18n.t('menu.list.admin.openingHour'),
      to: '/openingHour',
      name: 'openingHour'
    }, {
      text: i18n.t('menu.list.admin.quickAnswer'),
      to: '/quickAnswers',
      name: 'quickAnswers'
    }, {
      text: i18n.t('menu.list.admin.conclusionMessage'),
      to: '/conclusionMessages',
      name: 'conclusionMessages'
    }, {
      text: i18n.t('menu.list.admin.tag'),
      to: '/tags',
      name: 'tags'
    }, {
      text: i18n.t('menu.list.admin.user'),
      to: '/users',
      name: 'users'
    }, {
      text: i18n.t('menu.list.admin.integration'),
      to: '/integrations',
      name: 'integrations'
    }]
  }
];

export const adminSuperItems = [
  {
    text: i18n.t('menu.list.adminSuper.company'),
    to: '/company',
    icon: <BusinessIcon />,
    name: 'companies'
  },
  {
    text: i18n.t('menu.list.adminSuper.component'),
    to: '/component',
    icon: <ClearAllIcon />,
    name: 'components'
  },
];