
const accessRoutesPerComponent = [{
    componentName: 'crm',
    routes: ['customerRelationship']
}];

const CheckComponentAccess = (user, pathSegment) => {

    // identificar a qual componente pertence essa rota
    const componentIdx = accessRoutesPerComponent.findIndex(access => access.routes.includes(pathSegment));
    if (componentIdx == -1) return true;

    const components = user?.components;
    if (!components || !Array.isArray(components) || components.length == 0) return false;

    // verificar se o usuário tem acesso ao componente
    const cIdx = components.findIndex(comp => String(comp.name).toLowerCase() == accessRoutesPerComponent[componentIdx].componentName && comp.status == true);
    return cIdx == -1 ? false : true;
}

export default CheckComponentAccess;