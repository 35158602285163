import { FormControlLabel, FormGroup } from "@material-ui/core";
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/core/styles";
import { useEffect, useState, useRef } from "react";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";

// Estilos com makeStyles do MUI v4
const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
    margin: 0,
  },
  label: {
    margin: 0,
  },
  group: {
    display: 'contents',
  },
}));

const QueueSelect = ({ selectedQueueIds, onChange, ...props }) => {
  const classes = useStyles(); // Hook para aplicar estilos
  const [queues, setQueues] = useState([]);
  const [selectedQueues, setSelectedQueues] = useState(selectedQueueIds);
  const isMounted = useRef(false);

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;

      (async () => {
        try {
          const { data } = await api.get("/queue");
          setTimeout(() => {
            setQueues(data);
            setSelectedQueues(selectedQueueIds);
          }, 300);
        } catch (err) {
          console.error({err});
          toastError(err);
        }
      })();
    } else {
      setSelectedQueues(selectedQueueIds);
    }
  }, [selectedQueueIds]);

  const handleChange = () => {
    const box = [];
    const boxHandle = document.querySelectorAll("#queue_select input[type=checkbox]");
    boxHandle.forEach((input) => {
      if (input.value !== '') {
        const name = input.value;
        const value = input.checked;
        if (value) {
          box.push(name);
        }
      }
    });
    setSelectedQueues(box);
    onChange(box);
  };

  return (
    <div style={{ marginTop: 6 }}>
      <label className={classes.label}>
        {i18n.t("connections.form.queue")}
        <span className="text-danger ml-2">*</span>
      </label>
      <FormControl fullWidth margin="dense" required variant="outlined" className={classes.formControl}>
        <FormGroup id="queue_select" className={classes.group}>
          {queues.map((queue) => (
            <FormControlLabel
              key={queue.id}
              control={
                <Checkbox
                  defaultChecked={selectedQueues.includes(queue.id)}
                  color="primary"
                  id={queue.name}
                  name={queue.name}
                  onChange={handleChange}
                  value={queue.id}
                  {...props}
                />
              }
              label={queue.name}
              className={classes.label}
            />
          ))}
        </FormGroup>
      </FormControl>
    </div>
  );
};

export default QueueSelect;