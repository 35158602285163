// Bibliotecas e pacotes externos
import Alert from '@material-ui/lab/Alert';
import { Field, Form, Formik } from "formik";
import MicRecorder from 'mic-recorder-to-mp3';
import { useContext, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import * as XLSX from 'xlsx';
import noSpecialCharacter from "../../helpers/noSpecialCharacter";

// Material-UI: Componentes
import {
	Box,
	Button,
	Checkbox,
	Chip,
	CircularProgress,
	Dialog, DialogActions, DialogContent,
	DialogTitle, FormControlLabel, FormGroup,
	MenuItem, Select, Switch, TextField,
	Typography,
	Tooltip,
	FormControl,
	FormLabel,
	RadioGroup,
	Radio,
	Input,
	InputLabel
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import { makeStyles, useTheme } from "@material-ui/core";
import BackupIcon from '@material-ui/icons/Backup';
import SettingsVoiceIcon from '@material-ui/icons/SettingsVoice';
import StopIcon from '@material-ui/icons/Stop';

// Importações do projeto
import csvSvg from "../../assets/svg/csv.svg";
import headsetSvg from "../../assets/svg/headset.svg";
import musicSvg from "../../assets/svg/music.svg";
import pdfSvg from "../../assets/svg/pdf.svg";
import upload from "../../assets/svg/upload.svg";
import xlsxSvg from "../../assets/svg/xlsx.svg";
import excelContacts from "../../assets/xlsx/download.xlsx";
import { AuthContext } from "../../context/Auth/AuthContext";
import toastError from "../../errors/toastError";
import CheckUrl from "../../helpers/CheckUrl";
import PhoneValidator from "../../helpers/PhoneValidator";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import DatePickerBootstrap from "../DatePickerBootstrap";
import FileUploader from "../FileUploader";
import GroupedInputStyled from "../GroupedInputStyled";
import InputStyled from "../InputStyled";
import MultiSelectChip from "../MultiSelectChip";
import "./style.css";
import HelpIcon from '@material-ui/icons/Help';
import EmojiDataPicker from '../EmojiDataPicker';
import MultiSelect from '../InputStyled/multiselect';
import GetDate from '../../helpers/GetDate';
import JsonParse from '../../helpers/JsonParse';

const recorder = new MicRecorder({ bitRate: 128 });

function getDayOfWeek(dateString) {
	const daysOfWeek = ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sab'];
	const date = new Date(dateString + 'T00:00:00Z');
	const dayOfWeekNumber = date.getUTCDay();
	return daysOfWeek[dayOfWeekNumber];
};

const VALIDATION_ERROR_MESSAGES = {
	SELECT_MESSAGE_OPTION: 'Você deve selecionar uma opção de mensagem para enviar na campanha (Mensagem de texto, áudio ou arquivo).',
	SELECT_DISPARO_DAY: 'Selecione os dias de disparos da campanha.',
	SELECT_CONTACTS: 'Selecione os contatos para esta campanha.',
	DEFINE_CAMPAIGN_NAME: 'Defina um nome para a campanha.',
	DEFINE_VALID_WHATSAPP: 'Defina um whatsapp válido para disparar esta campanha.',
	DEFINE_CONTACT_NAME: 'Insira o nome do contato na mensagem.',
	DEFINE_BLACKLIST_MESSAGE: 'Insira a mensagem para opção de bloqueio de campanhas.'
};

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
		padding: '0px !important',
	},
	textField: {
		marginRight: theme.spacing(1),
		flex: 1,
	},
	emojiBox: {
		position: "absolute",
		bottom: '125px',
		width: 40,
		borderTop: "1px solid #e8e8e8",
	},
	btnWrapper: {
		position: "relative",
	},

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
	colorAdorment: {
		width: 20,
		height: 20,
	},
	chips: {
		display: "flex",
		flexWrap: "wrap",
	},
	chip: {
		margin: 2,
	},
	days: {
		margin: '0px 0px 0px 0px !important'
	},
	customButton: {
		padding: "7px",
		float: "right",
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.primary.contrastText,
		"&:hover": {
			backgroundColor: theme.palette.primary.main,
		},
		"& svg": {
			color: theme.palette.primary.contrastText,
			marginRight: '5px'
		},
	  },
}));

const weekDays = [
	{ name: 'dom', label: 'Domingo' },
	{ name: 'seg', label: 'Segunda' },
	{ name: 'ter', label: 'Terça' },
	{ name: 'qua', label: 'Quarta' },
	{ name: 'qui', label: 'Quinta' },
	{ name: 'sex', label: 'Sexta' },
	{ name: 'sab', label: 'Sabado' }];

const CampaignModal = ({ open, onClose, campaignId, duplicateCampaign, whatsappQuery: whatsappArray, resendCampaign }) => {

	const classes = useStyles();
	const initialState = {
		id: "",
		name: "",
		holiday: true,
		begin: "",
		end: "",
		days: [],
		message: "",
		whatsappId: "",
		queue: "",
		moveTo: "",
		sendBlackListMessage: true,
		blackListMessage: "Caso não queira mais receber nossas notificações, clique no link abaixo:"
	};
	const [campaign, setCampaign] = useState(initialState);
	const [contacts, setContacts] = useState([]);
	const [contactsError, setContactsError] = useState([]);
	const [loading, setLoading] = useState(false);
	const [mensagemVariavel, setMensagemVariavel] = useState([]);
	const [startDate, setDataInicial] = useState("");
	const [media64archive, setMedia64archive] = useState("");
	const [endDate, setEndDate] = useState("");
	const greetingRef = useRef();
	const { user: loggedInUser } = useContext(AuthContext);
	const [intervalSeconds, setIntervalSeconds] = useState("01");
	const [intervalMinutes, setIntervalMinutes] = useState("00");
	const [intervalHours, setIntervalHours] = useState("00");
	const [selectedTagsIds, setSelectedTagsIds] = useState([]);
	const [intervalSecondsToSend, setIntervalSecondsToSend] = useState("01");
	const [intervalMinutesToSend, setIntervalMinutesToSend] = useState("00");
	const [intervalHoursToSend, setIntervalHoursToSend] = useState("00");
	const [messegesPerSecond, setMessegesPerSecond] = useState("1");
	const [moveToStatus, setMoveToStatus] = useState("closed");
	const [blockedMessage, setBlockedMessage] = useState('enable');
	const [queues, setQueues] = useState([]); // filas sem filtro buscadas na base
	const [filteredQueues, setFilteredQueues] = useState([]); // filas filtradas para mostrar no select
	const [validatingContacts, setValidatingContacts] = useState(false);
	const [countContacts, setCountContact] = useState(null);
	const [timeEstimate, setTimeEstimate] = useState(null);
	const [picConnectionWarn, setPicConnectionWarn] = useState(null);
	const [weekDayBlocked, setWeekDayBlocked] = useState("");
	const [selecteDays, setSelectedDays] = useState([]);
	const [contactsFromTags, setContactsFromTags] = useState(true);
	const [addVariables, setAddVariables] = useState(false);
	const [resendContacts, setResendContacts] = useState([]);
	const theme = useTheme(); // Hook para acessar o tema v4
	const [apiConnectionIds, setApiConnectionIds] = useState([]);

	function addHours(numOfHours, date = new Date()) {
		if (date == 'Invalid Date') date = new Date();
		date.setTime(date.getTime() + numOfHours * 60 * 60 * 1000);
		return date.toISOString().replace(/\.\d{3}Z$/, '');
	}

	const formatInitialDate = () => {
		const currentDate = new Date();
		const dia = (currentDate.getDate().toString().padStart(2, '0'));
		const mes = (currentDate.getMonth() + 1).toString().padStart(2, '0');
		const ano = (currentDate.getFullYear());
		const formattedDate = `${ano}-${mes}-${dia}`
		return formattedDate;
	}

	// calcular estimativa de tempo
	const calcTimeEstimate = (qty) => {
		/*
			média de tempo para envio da campanha
			quantidade total x média em segundos do tempo de espera entre cada msg
		*/
		let estimate = qty * 52;
		
		// dividir total de contatos para adicionar estimativa de pausa;
		let separator = 10;
		let pauses = qty / separator;
		if (pauses > 1) {
			pauses = Math.floor(pauses);
			/*
				média de tempo para envio da campanha
				pausas x média em segundos do tempo de espera entre cada onda de mensagens
			*/
			estimate += (pauses * 90);
		}

		if (estimate >= 3600) setTimeEstimate(`${Math.ceil((estimate / 60) / 60)} horas`);
		else if (estimate >= 60) setTimeEstimate(`${Math.ceil(estimate / 60)} minutos`);
		else setTimeEstimate(`${estimate} segundos`);
	}

	// resgatar informações ao editar campanha
	useEffect(() => {
		if (!campaignId) return;

		const fetchCampaignData = async () => {
			if (!campaignId) return;
			clearInputs();
			setLoading(true);
			
			try {
				const idCampaign = (typeof campaignId === 'object' && campaignId !== null) ? campaignId.id : campaignId;

				const { data } = await api.get(`/campaign/${idCampaign}`);

				let formattedBeginDate = formatCampaignDate(data.begin);
				let formattedEndDate = formatCampaignDate(data.end);

				// verificar se a data inicial é menor que a atual, substituir para atual
				if (new Date(formattedBeginDate) < new Date()) formattedBeginDate = formatCampaignDate(GetDate(2));
				if (new Date(formattedEndDate) < new Date()) formattedEndDate = formatCampaignDate(GetDate(2));

				const campaignData = {
					id: data.id,
					name: resendCampaign
						? `${data.name} | Reenvio`
						: duplicateCampaign
							? `${data.name} | Duplicada`
							: data.name,
					begin: formattedBeginDate,
					end: formattedEndDate,
					days: JsonParse(data.days),
					holiday: data.holiday == true ? true : false,
					message: data.message === 'undefinedForUser' ? '' : data.message,
					whatsappId: data.whatsappId,
					queue: data.queue,
					moveTo: data.moveTo,
					sendBlackListMessage: data.sendBlackListMessage == true ? true : false,
					blackListMessage: data.blackListMessage
				};
				
				filterQueues(data.whatsappId);

				// resgatar variaveis dos contatos
				if (data?.contacts && data.contacts.length > 0) {
					let variables = [];
					for (const ctc of data.contacts) {
						for (const property in ctc) {
							if (property !== 'number' && property !== 'numero' && property !== 'status' && variables.includes(property) == false) variables.push(property);
						}
					}
					setMensagemVariavel(variables);
					// setar contatos para opção do usuário
					setResendContacts(data.contacts);
				}

				setMoveToStatus(data.moveTo);
				setDataInicial(formattedBeginDate);
				setEndDate(formattedEndDate);

				setCampaign(prevState => ({ ...prevState, ...campaignData }));

				// trazer arquivo para o modal
				if (data.media64archive) {
					base64ToInputFile(data.media64archive, data.mediaName);
					csvArhive();
				}
			} catch (err) {
				console.error({err});
				toastError(err);
			} finally {
				// Adicionado 'finally' para garantir que o loading seja definido como false independentemente do resultado.
				setTimeout(() => {
					setLoading(false);
				}, 500);
			}
		};

		const cleanupEffect = async () => {
			resetCampaignState();
		};

		const formatCampaignDate = (date) => {
			return addHours(-3, new Date(date)).toLocaleString("pt-BR", { timeZone: "America/Sao_Paulo" }).slice(0, 16);
		};

		const resetCampaignState = () => {
			setCampaign(initialState);
			setTimeEstimate(null);
			setPicConnectionWarn(null);
			setContactsFromTags(true);
			setAddVariables(false);
			setResendContacts([]);
		};

		fetchCampaignData();

		return cleanupEffect;
	}, [campaignId]);

	useEffect(() => {
		if (open == true) {
			const tmp = weekDays.filter(w => w.name != 'sab' && w.name != 'dom').map(w => w.name);
			setSelectedDays(tmp);

			// verificar se as conexões tem apis de integração vinculadas a elas
			(async () => {
				try {
					const resp = await api.get(`/integrations`);
					const { data } = resp;
					if (Array.isArray(data?.integrations)) {
						const apis = data?.integrations.filter(int => int.type == "api");
						const connectionsIds = [];
						for (const api of apis) {
							const fields = Array.isArray(api?.fields) ? api.fields : JsonParse(api.fields);
							const connectionId = fields.find(f => f.key == 'connectionId');
							if (connectionId?.value) connectionsIds.push(connectionId.value);
						}

						if (connectionsIds.length > 0) setApiConnectionIds(connectionsIds);

					}
				} catch (error) {
					console.error({ error });
				}
			})();
		}
	}, [open]);


	useEffect(() => {
		// Função para recuperar as filas e suas conexões
		async function getQueues() {
			setQueues([]); // Resetar lista

			try {
				const resp = await api.get(`/queueListWithConnections/`);
				const response_tmp = resp.data;

				if (!response_tmp || resp.status !== 200) {
					throw new Error();
				}

				setQueues(response_tmp);
			} catch (error) {
				toast.error('Erro ao resgatar filas, contate o administrador.');
			}
		}
		getQueues();
	}, []);

	// Função para filtrar filas com base no ID do WhatsApp
	function filterQueues(whatsappId) {
		// Resetar filas filtradas
		setFilteredQueues([]);

		if (!whatsappId) {
			return toast.error('Erro ao resgatar filas localmente, contate o administrador.');
		}

		const filtered = queues.filter(item => {
			if (item.whatsappConnections) {
				const whatsappIds = item.whatsappConnections.split(';');
				return whatsappIds.includes(String(whatsappId));
			}
			return false;
		}).map(item => ({
			id: item.id,
			name: item.name
		}));

		setFilteredQueues(filtered);
	}

	// Função para fechar
	const handleClose = (event, reason) => {
		if (reason !== 'backdropClick') {
			clearInputs();
			onClose();
			setCampaign(initialState);
		}
	};

	const showLoading = () => {
		const spinner = document.querySelector('#spinerLoader');
		spinner.style.display = 'flex';
	};

	const hideLoading = () => {
		const spinner = document.querySelector('#spinerLoader');
		spinner.style.display = 'none';
	};

	const handleSaveCampaign = async values => {
		try {
			if (campaignId && !duplicateCampaign && resendCampaign != true) {
				await api.put(`/campaign/${campaignId}`, values);
				toast.success("Campanha editada com sucesso!");
			} else if(duplicateCampaign) {
				await api.put(`/campaign/duplicate/${campaignId}`, values);
				toast.success("Campanha duplicada com sucesso!");
			} else {
				showLoading();

				await api.post("/campaign", values);
				toast.success("Campanha salva com sucesso!");
			}
			hideLoading();
			handleClose();
		} catch (err) {
			hideLoading();
			document.querySelector("#inputMessageCampaign").value = "";
			console.error({err});
			toastError(err);
		}
	};

	const countContactsByTags = async () => {
		if (!selectedTagsIds || !Array.isArray(selectedTagsIds) || selectedTagsIds.length == 0) {
			setTimeEstimate(null);
			setCountContact(null);
			return;
		}

		try {
			const { data } = await api.post("/countContactsByTags", { tagsId: selectedTagsIds });
			if (data.qty) {
				setCountContact(data.qty);
				// média de tempo para envio da campanha
				calcTimeEstimate(data.qty);
			}
			else {
				setCountContact(null);
				setTimeEstimate(null);
			}
		} catch (err) {
			hideLoading();
			document.querySelector("#inputMessageCampaign").value = "";
			console.error({err});
			toastError(err);
		}
	}

	const padTo2Digits = num => String(num).padStart(2, '0');

	const getCalcDays = {
		FormatData(data) {
			const date = new Date(new Date(data).getTime() + 15 * 60000);
			const days = padTo2Digits(date.getDate());
			const month = padTo2Digits(date.getMonth() + 1);
			const year = date.getFullYear();
			const hoursAndMinutes = `${padTo2Digits(date.getHours())}:${padTo2Digits(date.getMinutes())}`;
			const dataFormated = new Date().toLocaleString("pt-BR", {
				timeZone: "America/Sao_Paulo"
			});

			return {
				dataBr: `${days}-${month}-${year}`,
				dataInput: `${year}-${month}-${days}`,
				dataAndTime: dataFormated.substring(0, 16),
				dataBefore15Minutes: `${year}-${month}-${days} ${hoursAndMinutes}`
			};
		},
		getDataFormated(day) {
			const week = new Date();
			week.setDate(week.getDate() + day);
			return this.FormatData(week);
		},
		calcDays(contatos) {
			const dia = 86400;
			const calculo = dia / 30;
			const totalContatos = contatos;
			const result = Math.ceil(totalContatos / calculo);
			return {
				diasTotais: result,
				dataPrevista: this.getDataFormated(result).dataBr
			};
		}
	};

	const generateArchive = (media64, name) => {
		const type = media64.split(';base64')[0].split('data:')[1];
		let ext = media64.split(';base64')[0].split('/')[1];
		const base64 = media64.split(';base64,')[1];
		if (ext === 'vnd.openxmlformats-officedocument.wordprocessingml.document') {
			ext = 'docx';
		}
		const linkSource = `data:${type};base64,${base64}`;
		const downloadLink = document.createElement("a");
		downloadLink.href = linkSource;
		downloadLink.download = `${name}.${ext}`;
		downloadLink.click();
	};

	// função para converter stings base64 para o input de arquivo
	function base64ToInputFile(base64String, fileName) {
		// Separar o prefixo da string base64
		const [header, base64Data] = base64String.split(',');
	
		// Decodificar a string base64 para um array de bytes
		const byteCharacters = atob(base64Data);
		const byteNumbers = new Array(byteCharacters.length);
		for (let i = 0; i < byteCharacters.length; i++) {
			byteNumbers[i] = byteCharacters.charCodeAt(i);
		}
		const byteArray = new Uint8Array(byteNumbers);

		// Extrair o tipo de arquivo do prefixo
		const mimeType = header.match(/data:([^;]+);base64/)[1];
	
		// Criar um objeto Blob com os dados do arquivo
		const blob = new Blob([byteArray], { type: mimeType });
	
		// Criar um objeto File a partir do Blob
		const file = new File([blob], fileName, { type: mimeType });
	
		// Criar um elemento input do tipo file
		const dataTransfer = new DataTransfer();
		dataTransfer.items.add(file);

		const input = document.querySelector('#uploadArquivo');
		input.files = dataTransfer.files;
	
		return;
	}
	

	function archiveName(media64, name) {
		// Extrair extensão a partir da string base64
		let ext = media64.split(';base64')[0].split('/')[1];

		// Dicionário para mapear tipos MIME para extensões de arquivo
		const mimeToExtension = {
			'msword': 'doc',
			'vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
			'vnd.ms-excel': 'xls',
			'vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
			'pdf': 'pdf'
		};

		ext = mimeToExtension[ext] || ext;  // Use a extensão mapeada, ou a original se não existir mapeamento

		return `${name}.${ext}`;
	}

	//Transformar os arquivos em BASE64
	const csvArhive = () => {
		const MAX_SIZE = 8388608;  // 8MB em bytes
		let b64 = "";

		// Seletores de elementos DOM
		const imgUploadedUser = document.querySelector('#imgUploaded');
		const audioPlay = document.querySelector('#playerAudioUser');
		const playlist = document.querySelector('#playlist');
		const input = document.querySelector('#uploadArquivo');

		// Resetar exibições
		playlist.style.display = "none";
		imgUploadedUser.style.display = "none";

		const file = input.files[0];
		const reader = new FileReader();
		const fileSize = file.size;

		// Verificar tamanho do arquivo
		if (fileSize > MAX_SIZE) {
			input.value = "";
			return toast.error('O Arquivo não pode ter mais de 8MB');
		}

		const nameFile = file.name.replace(/\.[^/.]+$/, ""); // Remove extensão para obter nome do arquivo
		const extFile = file.name.split('.').pop();          // Obter extensão do arquivo

		reader.onloadend = () => {
			b64 = reader.result;
			const typeArchive = b64.split('/')[0].split(':')[1];

			if (typeArchive === 'image') {
				displayImage(imgUploadedUser, b64, "Imagem enviada pelo usuário");
			} else if (typeArchive === 'audio') {
				audioPlay.src = b64;
				displayImage(imgUploadedUser, musicSvg, "Áudio enviado pelo usuário");
				playlist.style.display = "";
			} else {
				switch (extFile) {
					case 'pdf':
						displayImage(imgUploadedUser, pdfSvg, "PDF enviado pelo usuário");
						break;
					case 'xlsx':
						displayImage(imgUploadedUser, xlsxSvg, "XLSX enviado pelo usuário");
						break;
					case 'csv':
						displayImage(imgUploadedUser, csvSvg, "CSV enviado pelo usuário");
						break;
					default:
						displayImage(imgUploadedUser, upload, "Arquivo enviado pelo usuário");
						break;
				}
			}

			if (b64.length < 15) {
				toast.error('Arquivo inválido para upload.');
				return;
			}

			setMedia64archive(`name(${nameFile})${b64}`);
		};

		reader.readAsDataURL(file);
		setBlockedMessage('enable');
	}

	// Função auxiliar para exibir imagens
	function displayImage(element, source, title) {
		element.style.display = "";
		element.src = source;
		element.title = title;
	}

	function getTimeMinToSend(total) {
		// Convertendo o total em segundos.
		const totalSeconds = total * 30;

		// Função auxiliar para converter segundos em formato HH:MM:SS.
		function secondsToHoursFormat(seconds) {
			const hours = padTo2Digits(Math.floor(seconds / 3600));
			const minutes = padTo2Digits(Math.floor((seconds % 3600) / 60));
			const remainingSeconds = padTo2Digits(seconds % 60);

			return `${hours}:${minutes}:${remainingSeconds}`;
		}

		return secondsToHoursFormat(totalSeconds);
	}

	var getDays = {};
	// função para validar contatos inseridos no mailling para campanha
	const VALID_EXTENSIONS = ['xlsx', 'csv'];

	async function processSheetData(file) {
		let data = [];
		let error = [];
		let percentage = 0;

		const sheets = file.SheetNames;
		for (let i = 0; i < sheets.length; i++) {
			const temp = XLSX.utils.sheet_to_json(file.Sheets[file.SheetNames[i]]);
			let numberArray = [];
			for (let ct = 0; ct < temp.length; ct++) {
				let res = temp[ct];
				percentage++

				res.numero = formatTelefone(res.numero);

				if (numberArray.includes(res.numero)) {
					// error.push(res);
				} else {
					numberArray.push(res.numero)

					let validation_status = await PhoneValidator(res.numero);

					if (!isContactValid(res, validation_status)) {
						error.push(res);
					} else {
						const obj = formatContact(res);
						data.push(obj);
					}
				}
				updateProgress(percentage, temp.length);
			}
			// média de tempo para envio da campanha
			calcTimeEstimate(numberArray.length);
		}
		return { data, error };
	}

	function isContactValid(contact, validation_status) {
		return (contact.nome == '' || !('nome' in contact) || !('numero' in contact) || validation_status == false) ? false : true;
	}

	function formatContact(contact) {
		let template = {};
		for (const property in contact) {
			let propertyParsed = noSpecialCharacter(property).toLowerCase();
			if (propertyParsed == 'nome') template['name'] = contact[property];
			else if (propertyParsed == 'numero') template['number'] = contact[property];
			else template[propertyParsed] = contact[property];
		}
		return template;
	}

	function formatTelefone(telefone) {
		try {
			if (!telefone) throw new Error('telefone não identificado');
			// remover caracteres especiais
			telefone = telefone.toString().replace(/\D/g, '');
			let add = false;
			// verificar quantidade minima e máxima de caracteres
			if (telefone.length < 10 || telefone.length > 13) return '';
			// verificar se não começar com o 55, adicionar
			if (telefone.substring(0, 2) != '55') add = true;
			else if (add == false && (telefone.length == 10 || telefone.length == 11)) add = true;

			return add == true ? '55' + telefone : telefone;
		} catch (err) {
			console.error(telefone);
			console.error(err);
			return '';
		}
	}

	function updateProgress(percentage, total) {
		const progressBar = document.querySelector("#progressBar");
		const progress = ((percentage * 100) / total).toFixed(0);
		progressBar.style.width = progress + '%';
		progressBar.innerHTML = progress + '%';
	}

	const csvUpload = async (e) => {
		const extFile = e.target.files[0].name.split('.').pop();

		if (!VALID_EXTENSIONS.includes(extFile)) {
			toast.error(`Selecione um arquivo correto para os contatos da campanha.`);
			return;
		}

		initUIForProcessing();

		const reader = new FileReader();
		reader.onload = async (e) => {
			const arquivo = e.target.result;
			const file = XLSX.readFile(arquivo);
			let { data, error } = await processSheetData(file);
			console.info({ data, error })
			updateUIPostProcessing(data, error);
			setContacts(data);
			setContactsError(error);
			setValidatingContacts(false);
			getColumnsFromFile(file);
		}
		reader.readAsArrayBuffer(e.target.files[0]);
	}

	function initUIForProcessing() {
		const resultRelatory = document.querySelector("#resultRelatory");
		const progressRelatory = document.querySelector("#progressRelatory");

		progressRelatory.style.display = '';
		resultRelatory.style.display = 'flex';
		resultRelatory.style.opacity = '0';

		setValidatingContacts(true);
		toast.info("Aguarde a validação dos contatos para prosseguir.");
	}

	function updateUIPostProcessing(data, error) {
		const successContacts = document.querySelector("#successContacts");
		const errorContacts = document.querySelector("#errorContacts");
		const contactsResult = document.querySelector("#contactsResult");
		const contactsEnd = document.querySelector("#contactsEnd");
		const resultRelatory = document.querySelector("#resultRelatory");

		getDays = getCalcDays.calcDays(data.length);
		contactsResult.innerHTML = `Enviados: ${data.length + error.length}`;
		errorContacts.innerHTML = `Erros: ${error.length}`;
		successContacts.innerHTML = `Válidos: ${data.length}`;
		contactsEnd.innerHTML = `Estimativa: ${getTimeMinToSend(data.length)}`;
		resultRelatory.style.opacity = '1';
	}

	function getColumnsFromFile(file) {
		// Pegar colunas adicionar na tabela
		var listColumnNames = file.SheetNames;
		let columnNames = [];
		for (var i = 0; i < listColumnNames.length; i++) {
			var worksheet = file.Sheets[listColumnNames[i]];
			for (let key in worksheet) {
				let regEx = new RegExp("^\(\\w\)\(1\){1}$");

				if (regEx.test(key) == true) {
					columnNames.push(worksheet[key].v);
				}

			}
		}
		let extraColumns = [];
		for (let i = 0; i < columnNames.length; i++) {
			if (columnNames[i] !== 'numero') {
				extraColumns.push(noSpecialCharacter(columnNames[i]).toLowerCase());
			}
		}
		setMensagemVariavel(extraColumns);
	}

	const HandleConnections = (whatsapps) => {
		// mapear conexões que estão ligadas as filas do usuário
		let conn = [];
		loggedInUser.queues.map((q) => {
			q.Whatsapps.map((w) => {
				if (conn.includes(w.id) == false) conn.push(w.id);
			});
		});

		let response = [];

		whatsapps.map(whatsapp => {
			if (!conn.includes(whatsapp.id)) return;
			const isApi = apiConnectionIds.includes(whatsapp.id);
			let disabled = whatsapp.status !== 'CONNECTED' || !conn.includes(whatsapp.id) || whatsapp.option == 'receptive' || isApi == true;
			let label = '';

			if (disabled && isApi == true) label = `${whatsapp.name} - Conexão utilizada em api`;
			else if (disabled && whatsapp.option == 'receptive') label = `${whatsapp.name} - Conexão apenas para atendimentos`;
			else label = `${whatsapp.name}${whatsapp.numberConnection ? ` - (${whatsapp.numberConnection}) - ` : ' '}${whatsapp.status === 'CONNECTED' ? 'Conectado' : 'Desconectado, escaneie o QRCODE.'}`;

			response.push({
				value: whatsapp.id,
				label,
				disabled,
			});
		});

		return response;
	};

	const HandleQueues = (queues) => {
		const filteredQueues = queues.filter(queue => loggedInUser?.queues?.some(uq => uq.id === queue.id));
		return filteredQueues.map(queue => ({
			value: queue.id,
			label: `${queue.name}`,
		}));
	};

	// Função auxiliar para manipulação de DOM
	const toggleElementDisplay = (elementId, displayValue) => {
		const element = document.querySelector(elementId);
		if (element) element.style.display = displayValue;
	};

	// Inicia a gravação
	const startRecording = () => {
		toggleElementDisplay('#start', "none");
		toggleElementDisplay('#stop', "flex");
		toggleElementDisplay('#playlist', "none");
		toggleElementDisplay('#imgUploaded', "none");

		setBlockedMessage('disable');

		recorder.start()
			.then(() => { /* No operation */ })
			.catch(e => console.error(e));
	};

	const stopRecording = () => {
		const imgUploadedUser = document.querySelector('#imgUploaded');
		const audioPlay = document.querySelector('#playerAudioUser');

		toggleElementDisplay('#start', "flex");
		toggleElementDisplay('#stop', "none");

		recorder.stop().getMp3()
			.then(([buffer, blob]) => {
				const file = new File(buffer, 'music.mp3', {
					type: blob.type,
					lastModified: Date.now()
				});
				const blobFile = URL.createObjectURL(file);
				const player = new Audio(blobFile);

				toggleElementDisplay('#playlist', "");

				player.controls = true;

				const reader = new FileReader();
				reader.readAsDataURL(blob);
				reader.onloadend = function () {
					const base64data = reader.result;
					audioPlay.src = base64data;
					if (base64data.length < 15) {
						toast.error(`Ocorreu um erro ao salvar o áudio, tente novamente.`);
						return;
					}
					setMedia64archive(`name(userRecAudio)${base64data}`);
				}

				toggleElementDisplay('#imgUploaded', "");
				imgUploadedUser.src = headsetSvg;
				imgUploadedUser.title = "Áudio gravado pelo usuário";
			})
			.catch((e) => {
				console.error(e);
			});
	}

	const playAudio = () => {
		const audio = document.querySelector('audio');
		audio.play();
		toggleElementDisplay('#playerStart', 'none');
		toggleElementDisplay('#playerEnd', '');
		audio.onended = () => {
			toggleElementDisplay('#playerStart', '');
			toggleElementDisplay('#playerEnd', 'none');
		}
	}

	const formatarRetorno = (formating) => {
		const [date, time] = formating.split('T');
		const [ano, mes, dia] = date.split('-');
		const [hora, minutos] = time.split(':');
		return [`${ano}-${mes}-${dia}`, hora, minutos];
	}

	useEffect(() => {
		if (campaignId && startDate && endDate && open) {
			const begin = formatarRetorno(startDate);
			const end = formatarRetorno(endDate);
	
			console.log(end);
	
			// Atualizando os valores diretamente nos inputs
			const startDateInput = document.querySelector("#start_date");
			const startHoursInput = document.querySelector("#start_hours");
			const startMinutesInput = document.querySelector("#start_minutes");
			const endDateInput = document.querySelector("#end_date");
			const endHoursInput = document.querySelector("#end_hours");
			const endMinutesInput = document.querySelector("#end_minutes");
	
			if (startDateInput) startDateInput.value = begin[0];
			if (startHoursInput) startHoursInput.value = begin[1];
			if (startMinutesInput) startMinutesInput.value = begin[2];
			if (endDateInput) endDateInput.value = end[0];
	
			// Função para atualizar o valor de end_hours e disparar o evento
			const atualizarEDispararEvento = (novoValor) => {
				if (endHoursInput) {
					// Alterando o valor do input
					endHoursInput.value = novoValor;
	
					// Criando e disparando um evento de entrada
					const inputEvent = new Event('input', { bubbles: true });
					endHoursInput.dispatchEvent(inputEvent);
	
					// Criando e disparando um evento de mudança (opcional)
					const changeEvent = new Event('change', { bubbles: true });
					endHoursInput.dispatchEvent(changeEvent);
				}
			};
	
			// Chamada para atualizar end_hours
			const novoEndHours = end[1];
			atualizarEDispararEvento(novoEndHours);
	
			getStartDateSet(prev => {
				if (prev.end_hours !== novoEndHours) {
					return { ...prev, end_hours: novoEndHours };
				}
				return prev;
			});
		}
	}, [campaignId, startDate, endDate, open]);


	const [startDateSet, getStartDateSet] = useState(
		(startDateSet, updates) => ({
			...startDateSet,
			start_date: formatInitialDate(),
			start_hours: '09',
			start_minutes: '00',
			end_date: formatInitialDate(),
			end_hours: '18',
			end_minutes: '00',
			...updates
		})
	);

	const checkDate = (e) => {
		var valor = e.target.value;
		var nome = e.target.name;

		if (nome == 'start_date') {
			const currentDay = getDayOfWeek(valor);
			setWeekDayBlocked(currentDay)
			handleSelect(currentDay)
			getStartDateSet({ ...startDateSet, [nome]: valor, end_date: valor })
		} else {
			getStartDateSet({ ...startDateSet, [nome]: valor })
		}
	}

	useEffect(() => {
		const currentDay = getDayOfWeek(startDateSet.start_date);
		setWeekDayBlocked(currentDay)
		handleSelect(currentDay)
	}, [startDateSet.start_date]);

	const formatar = (e) => {
		var valor = e.target.value.replace(/[^0-9]/g, '');
		var nome = e.target.name;

		if (nome === 'start_hours') {
			if (valor > 19) valor = 19;
		}

		if (nome === 'end_hours') {
			if (valor > 20) valor = 20;
		}

		if (nome === 'interval_hours') {
			if (valor > 23) valor = 23;
		}

		if (nome === 'start_minutes' || nome === 'end_minutes' || nome === 'interval_minutes') {
			if (valor > 59) valor = 59;
		}
		getStartDateSet(prev => ({ ...prev, [nome]: valor }));

	}

	function formatarMin(e) {
		var valor = e.target.value.replace(/[^0-9]/g, '');
		var nome = e.target.name;

		if (nome === 'start_hours') {
			if (valor < 8) valor = 8;
		}

		if (nome === 'end_hours') {
			if (valor < 9) valor = 9;
		}

		valor = ('00' + valor).slice(-2);

		getStartDateSet(prev => ({ ...prev, [nome]: valor }));
	}

	function format_time(time) {
		return time.toString().padStart(2, '0')
	}

	useEffect(() => {
		if (startDateSet) {
			if (startDateSet.start_date && startDateSet.start_hours && startDateSet.start_minutes) {
				var dataInicial = (`${startDateSet.start_date}T${format_time(startDateSet.start_hours)}:${format_time(startDateSet.start_minutes)}:00.000Z`)
				setDataInicial(addHours(0, new Date(dataInicial)).toLocaleString("pt-BR", { timeZone: "America/Sao_Paulo" }).slice(0, 16))
			}
			if (startDateSet.end_date && startDateSet.end_hours && startDateSet.end_minutes) {
				var dataFinal = (`${startDateSet.end_date}T${format_time(startDateSet.end_hours)}:${format_time(startDateSet.end_minutes)}:00.000Z`)
				setEndDate(addHours(0, new Date(dataFinal)).toLocaleString("pt-BR", { timeZone: "America/Sao_Paulo" }).slice(0, 16))
			}
		}
	}, [startDateSet]);

	const check = () => {

		let days = [];
		var aa = document.querySelectorAll("input[type=checkbox]");

		for (var i = 0; i < aa.length; i++) {
			if (aa[i].id != '') {
				var name = aa[i].id;
				var value = aa[i].checked;
				if (value) {
					if (name != 'holiday' && name != 'sendBlackListMessage') {
						days.push(name);
					}
				}
			}
		};

		return days;
	}

	function openDatePicker(input) {
		var inputDate = document.getElementById(input)
		if (!inputDate.disabled) {
			inputDate.showPicker()
		}
	}

	function clearInputs() {
		var clearInputs = document.getElementsByTagName("INPUT")
		for (let i = 0; i < clearInputs.length; i++) {
			clearInputs[i].value = '';
		}
		getStartDateSet({
			start_date: new Date().toISOString().split('T')[0],
			start_hours: '09',
			start_minutes: '00',
			end_date: new Date().toISOString().split('T')[0],
			end_hours: '18',
			end_minutes: '00'
		});
		setMoveToStatus("closed");
		setMessegesPerSecond(1);
		setIntervalHours("00");
		setIntervalMinutes("00");
		setIntervalSeconds("30");
		setIntervalHoursToSend("00");
		setIntervalMinutesToSend("00");
		setIntervalSecondsToSend("30");
		setContactsError([]);
		setFilteredQueues([]);
		setBlockedMessage('enable');
		setSelectedTagsIds([]);
		setContacts([]);
		setCountContact(null);
		setMedia64archive("");
		setApiConnectionIds([]);
	}

	const getErrosExcel = () => {
		var data = contactsError
		const ws = XLSX.utils.json_to_sheet(data)
		const wb = XLSX.utils.book_new()
		XLSX.utils.book_append_sheet(wb, ws, 'Responses')
		XLSX.writeFile(wb, 'contatos.xlsx')
	}

	const toMilliseconds = (hrs, min, sec) => {
		if (isNaN(hrs) || isNaN(min) || isNaN(sec)) return 30000;

		hrs = 3600000 * hrs;
		min = 60000 * min;
		sec = 1000 * sec;

		let result = hrs + min + sec;
		result = result < 30000 ? 30000 : result;
		return result
	};

	// Validação dos campos para envio
	const hasMessageOrMedia = (message, media64archive) => message || media64archive;
	const hasValidDays = (daysFunc) => daysFunc().length > 0;
	const hasContactsOrCampaignId = (contacts) => contacts.length > 0;

	const validateForm = (values, media64archive, check) => {
		if (!hasMessageOrMedia(values.message, media64archive)) {
			toast.error(VALIDATION_ERROR_MESSAGES.SELECT_MESSAGE_OPTION);
			return false;
		}
		if (!hasValidDays(check, values.campaignId)) {
			toast.error(VALIDATION_ERROR_MESSAGES.SELECT_DISPARO_DAY);
			return false;
		}

		if (!hasContactsOrCampaignId(contacts, values.campaignId) && (!selectedTagsIds || selectedTagsIds.length == 0)) {
			toast.error(VALIDATION_ERROR_MESSAGES.SELECT_CONTACTS);
			return false;
		}
		if (!values.name) {
			toast.error(VALIDATION_ERROR_MESSAGES.DEFINE_CAMPAIGN_NAME);
			return false;
		}
		if (!values.queue) {
			toast.error(VALIDATION_ERROR_MESSAGES.DEFINE_VALID_WHATSAPP);
			return false;
		}
		if (values.message && values.message.includes('{{nome}}') == false && values.message.includes('{{name}}') == false) {
			toast.error(VALIDATION_ERROR_MESSAGES.DEFINE_CONTACT_NAME);
			return false;
		}
		if (values.sendBlackListMessage == true && (!values.blackListMessage || String(values.blackListMessage).trim() == '')) {
			toast.error(VALIDATION_ERROR_MESSAGES.DEFINE_BLACKLIST_MESSAGE);
			return false;
		}
		return true;
	};

	const buildRequestBody = (values, media64archive, check, intervalDetails) => {
		const { intervalHours, intervalMinutes, intervalSeconds, intervalHoursToSend, intervalMinutesToSend, intervalSecondsToSend } = intervalDetails;
		return {
			...values,
			interval: toMilliseconds(intervalHours, intervalMinutes, intervalSeconds),
			intervalToSend: toMilliseconds(intervalHoursToSend, intervalMinutesToSend, intervalSecondsToSend),
			messagesPerInterval: Number(messegesPerSecond),
			contacts,
			media: media64archive,
			days: !values.campaignId ? JSON.stringify(check()) : undefined,
			begin: !values.campaignId ? startDateSet.start_date + ' ' + startDateSet?.start_hours + ':' + startDateSet?.start_minutes + ':00' : '',
			end: !values.campaignId ? startDateSet.end_date + ' ' + startDateSet?.end_hours + ':' + startDateSet?.end_minutes + ':00' : '',
			tagsId: selectedTagsIds,
			moveTo: moveToStatus
		};
	};

	const checkIsCkeched = (day, selected) => day === selected;

	const handleSelect = (day) => {
		setSelectedDays((oldArray) => {
			const isDaySelected = oldArray.includes(day);
			return isDaySelected ? oldArray.filter((selectedDay) => selectedDay !== day) : [...oldArray, day];
		});
	};

	const handleResendContactOptions = (options) => {
		// resgatar contatos conforme os filtros selecionados
		if (options && Array.isArray(options) && options.length > 0 && resendContacts.length > 0) {
			let contacts = [];
			for (let ctc of resendContacts) {
				if (options.includes(ctc.status)) {
					delete ctc.status;
					contacts.push(ctc);
				}
			}
			// média de tempo para envio da campanha
			setCountContact(contacts.length);
			calcTimeEstimate(contacts.length);
			setContacts(contacts);
		} else {
			setContacts([]);
		}
	}

	return (
		<Box className={classes.root}>
			<Dialog style={{ padding: '0px' }}
				PaperProps={{
					style: {
						maxWidth: '100%',
					},
				}}
				open={open} onClose={handleClose} >
				<Box
					style={{
						display: 'flex',
						justifyContent: 'space-between'
					}}
				>

					<DialogTitle style={{ marginTop: '10px' }}>
						{
							resendCampaign == true 
							? 'Reenviar Campanha'
							: campaignId && !duplicateCampaign
							? `Editar Campanha`
							: duplicateCampaign
							? `Duplicar Campanha`
							: `Criar Campanha`
						}
					</DialogTitle>
				</Box>
				<Formik
					initialValues={campaign}
					enableReinitialize={true}
					onSubmit={(values, actions) => {
						const intervalDetails = { intervalHours, intervalMinutes, intervalSeconds, intervalHoursToSend, intervalMinutesToSend, intervalSecondsToSend };

						if (validateForm(values, media64archive, check)) {
							setTimeout(() => {
								const body = buildRequestBody(values, media64archive, check, intervalDetails);
								handleSaveCampaign(body);
								actions.setSubmitting(false);
							}, 400);
						} else {
							actions.setSubmitting(false);
						}
					}}
				>
					{({ isSubmitting, values }) => (
						<Form style={{ padding: "10px", maxWidth: '660px' }}>
							<DialogContent dividers>
								<Box enforceFocus={false} className="form-row">
									<Field style={{ display: 'none' }} as={TextField} type="hidden" name="id" />
									<InputStyled
										label="Nome"
										name="name"
										primaryClass="form-group col-md-5"
										style={{ borderColor: '#c4c4c4', height: '35px', borderRadius: '3px' }}
										required
										placeholder={i18n.t("queueModal.form.name")}
										//disabled={campaignId && !resendCampaign}
										className='form-control'
									/>
									<InputStyled
										as={Select}
										size="small"
										label="Manter contatos em:"
										name="moveToStatus"
										primaryClass="form-group col-md-4"
										style={{ borderColor: '#c4c4c4', height: '35px', borderRadius: '3px' }}
										value={moveToStatus}
										onChange={e => setMoveToStatus(e.target.value)}
										disabled={!values.name}
										className='form-control'
										options={[
											{ value: 'pending', label: 'Aguardando' },
											{ value: 'closed', label: 'Finalizados' }
										]}
									/>
									<InputStyled
										as={Switch}
										label="Evitar feriados"
										name="holiday"
										color="primary"
										checked={values.holiday}
										primaryClass="form-group col-md-3 pl-1"
										style={{ margin: '0px', position: 'absolute', bottom: '2px', left: 0 }}
										disabled={!values.name}
									/>
									<DatePickerBootstrap
										name="start_date"
										id="start_date"
										className="form-control"
										primaryClass="form-group col-md-6"
										label="Data de inicio"
										secondClass="input-group mb-2"
										minDate={formatInitialDate()}
										value={startDateSet.start_date}
										disabled={!values.name}
										onClickElement={e => openDatePicker('start_date')}
										onChangeDate={e => checkDate(e)}
										required
									/>
									<GroupedInputStyled
										primaryClass="form-group col-md-6"
										inputsProps={[
											{
												name: "start_hours",
												id: 'start_hours',
												value: startDateSet?.start_hours,
												placeholder: "00",
												required: true,
												onChange: (e) => formatar(e),
												onBlur: (e) => formatarMin(e)
											},
											{
												name: "start_minutes",
												id: 'start_minutes',
												value: startDateSet?.start_minutes,
												placeholder: "00",
												required: true,
												onChange: (e) => formatar(e)
											}
										]}
										separator=":"
										className="form-control"
										label="Horário de início:"
										style={{ height: '35px', width: '25px' }}
										disabled={!values.name}
										required
									/>
									<DatePickerBootstrap
										name="end_date"
										id="end_date"
										className="form-control"
										primaryClass="form-group col-md-6"
										label="Data de finalização"
										secondClass="input-group mb-2"
										minDate={startDateSet.start_date}
										value={startDateSet.end_date}
										disabled={!values.name}
										onClickElement={e => openDatePicker('end_date')}
										onChangeDate={e => checkDate(e)}
										required
									/>
									<GroupedInputStyled
										primaryClass="form-group col-md-6"
										inputsProps={[
											{
												name: "end_hours",
												id: 'end_hours',
												value: startDateSet.end_hours,
												placeholder: "00",
												required: true,
												onChange: (e) => formatar(e),
												onBlur: (e) => formatarMin(e)
											},
											{
												name: "end_minutes",
												id: 'end_minutes',
												value: startDateSet.end_minutes,
												placeholder: "00",
												required: true,
												onChange: (e) => formatar(e)
											}
										]}
										separator=":"
										className="form-control"
										label="Horário de finalização:"
										style={{ height: '35px', width: '25px' }}
										disabled={!values.name}
										required
									/>
									<Box className="form-group col-md-12">
										<label>
											Dias da semana para envio
											<span className="text-danger ml-2">*</span>
										</label>
										<Box style={{ width: '560px', margin: '0px 0px 0px 17px' }}>
											<FormGroup style={{ display: 'contents' }}>
												{weekDays.map((day, i) => (
													<FormControlLabel
														key={day.name}
														control={
															<Checkbox
																id={day.name}
																name={day.name}
																onChange={() => handleSelect(day.name)}
																disabled={!values.name || weekDayBlocked === day.name}
																value={day.name}
																checked={(selecteDays.findIndex((selectedDay) => selectedDay === day.name) != -1 || checkIsCkeched(day.name, weekDayBlocked))}
															/>
														}
														label={day.label}
														style={{ margin: '0px' }}
													/>
												))}
											</FormGroup>
										</Box>
									</Box>

									<InputStyled
										as={Select}
										size="small"
										label="Whatsapp Disparo:"
										name="whatsappId"
										primaryClass="form-group col-md-6"
										style={{
											borderColor: '#c4c4c4',
											borderRadius: '3px',
											height: '43px'
										}}
										displayEmpty
										required
										value={values.whatsappId}
										onChange={(e) => {
											values.whatsappId = e.target.value;
											filterQueues(e.target.value);
										}}
										disabled={!values.name}
										className='form-control'
										options={whatsappArray ? HandleConnections(JsonParse(whatsappArray)) : []}
										onBlur={() => {
											let whatsapps = JsonParse(whatsappArray);
											if (values.whatsappId && whatsapps) {
												let index = whatsapps.findIndex(w => w.id == values.whatsappId);
												if (index) {
													let whatsapp = whatsapps[index];
													if (String(whatsapp.status).toLocaleLowerCase() == 'connected' && !whatsapp.picConnection) {
														setPicConnectionWarn('Não identificamos a imagem de perfil da conexão, isso pode interferir na sua campanha.')
													}
												}
											}
										}}
									/>
									<InputStyled
										as={Select}
										size="small"
										label="Fila de Disparo:"
										name="queue"
										primaryClass="form-group col-md-6"
										style={{
											borderColor: '#c4c4c4',
											borderRadius: '3px',
											height: '43px'
										}}
										displayEmpty
										required
										value={values.queue}
										disabled={filteredQueues.length < 1}
										className='form-control'
										options={filteredQueues ? HandleQueues(filteredQueues) : []}
									/>

									{/* Seleção de contatos para reenvio de campanhas */}
									<Box className="form-group col-md-12" hidden={!campaignId || resendCampaign != true}>
										<MultiSelect
											title="Reenviar para: "
											displayText="Selecionar contatos"
											disabled={!values.name || (campaignId && resendCampaign != true)}
											options={[{ key: 'error', value: `Com erros` }, { key: '', value: `Não enviados` }, { key: 'sent', value: `Enviados` }, { key: 'answered', value: `Respondidos` }]}
											onSelected={handleResendContactOptions}
										/>
									</Box>

									<Box className="form-group col-md-12" hidden={resendCampaign == true ? true : false}>
										<FormControl>
											<FormLabel style={{ color: "#000000" }}>Selecione a origem da base de contatos:</FormLabel>
											<RadioGroup
												row
												name="selectOrigin"
											>
												<FormControlLabel control={<Radio color="primary" disabled={!values.queue} checked={contactsFromTags} onClick={() => setContactsFromTags(!contactsFromTags)} />} label="Etiquetas" />
												<FormControlLabel
													control={<Radio color="primary" disabled={!values.queue} checked={!contactsFromTags} onClick={() => setContactsFromTags(!contactsFromTags)} />}
													label={
														<>
															Importação de planilha
															<Tooltip
																title={
																	<>
																		<Typography gutterBottom color="inherit">Para adicionar contatos na campanha via importação de planilha, siga os passos abaixo:</Typography>
																		<Typography gutterBottom color="inherit">1º - Clique no botão "Download do Modelo";</Typography>
																		<Typography gutterBottom color="inherit">2º - Na planilha baixada, mantenha os nomes das colunas já existentes (essas informações não podem ser alteradas);</Typography>
																		<Typography gutterBottom color="inherit">3º - Adicione as informações dos contatos, formatando os números dos contatos como DDI+DDD+NUMERO;</Typography>
																		<Typography gutterBottom color="inherit">4º - Caso queira adicionar mais variáveis na mensagem da campanha, adicione novas colunas nomeando as varáveis na primeira linha das novas colunas e abaixo as informações das variáveis;</Typography>
																		<Typography gutterBottom color="inherit">5º Importe a planilha ajustada para a Plataforma através do botão "Enviar Contatos".</Typography>
																	</>
																}
																placement="bottom"
																style={{ marginLeft: "10px" }}
															>
																<HelpIcon />
															</Tooltip>
														</>
													}
												/>
											</RadioGroup>
										</FormControl>
									</Box>

									{/* Etiquetas */}
									<Box style={{ position: "relative", width: "100%", minHeight: "43px" }} className="col-md-12">
										<Box
											hidden={!contactsFromTags || resendCampaign == true}
										>
											<MultiSelectChip
												disabled={!values.queue || contacts.length >= 1}
												selectedTagsIds={selectedTagsIds}
												onChange={(values) => {
													setSelectedTagsIds(values);
													setMensagemVariavel(['nome']);
												}}
												onClose={() => countContactsByTags()}
												formGroup={false}
											/>
										</Box>
										{(countContacts && countContacts >= 0) &&
											<Box style={{
												width: "100%",
												textAlign: "right",
												position: "absolute",
												paddingRight: "10px"
											}}>
												Qtd de contatos: {countContacts}
											</Box>
										}
									</Box>

									<Box className="col-md-12" hidden={!contactsFromTags || resendCampaign == true}>
										<InputStyled
											as={Switch}
											label={
												<>
													Adicionar variáveis na mensagem da campanha
													<Tooltip
														title={
															<>
																<Typography gutterBottom color="inherit">Para adicionar mais variáveis na sua mensagem, siga os passos abaixo:</Typography>
																<Typography gutterBottom color="inherit">1º - Clique no botão “Download do Modelo”;</Typography>
																<Typography gutterBottom color="inherit">2º - Na planilha baixada, mantenha os nomes das colunas e informações já existentes (essas informações não podem ser alteradas);</Typography>
																<Typography gutterBottom color="inherit">3º - Adicione novas colunas nomeando as varáveis na primeira linha das novas colunas e abaixo as informações das variáveis;</Typography>
																<Typography gutterBottom color="inherit">4º - Após concluir, salve o arquivo com as alterações realizadas;</Typography>
																<Typography gutterBottom color="inherit">5º - Importe a planilha ajustada para a Plataforma através do botão “Enviar Arquivo”.</Typography>
															</>

														}
														placement="bottom"
														style={{ marginLeft: "10px" }}
													>
														<HelpIcon />
													</Tooltip>
												</>
											}
											name="holiday"
											color="primary"
											checked={addVariables}
											onChange={() => setAddVariables(!addVariables)}
											disabled={!selectedTagsIds || selectedTagsIds.length == 0}
										/>
									</Box>

									{/* botão de modelo */}
									<Box style={{ position: "relative", width: "100%" }} className="col-md-12" hidden={(contactsFromTags && !addVariables) || resendCampaign == true}>
										<Button
											style={{ width: "100%" }}
											disabled={!values.queue}
											type="submit"
											color="primary"
											variant="contained"
											className={classes.btnWrapper}
											href={excelContacts}
											download="Contatos.xlsx"
											target='_blank'
											onClick={(e) => {
												if (selectedTagsIds.length > 0) {
													e.preventDefault();

													const fetchSpreadsheet = async () => {
														try {
															setLoading(true);
															const { data } = await api.post('/campaign/getModelByTags', { tagsId: selectedTagsIds });

															if (data.contacts) {
																// Cria uma nova planilha
																const worksheet = XLSX.utils.json_to_sheet(data.contacts);
																const workbook = XLSX.utils.book_new();
																XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

																// Converte o workbook para binário
																const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });

																// Define o nome do arquivo
																const fileName = "Contatos.xlsx";

																// Função auxiliar para converter string em buffer
																const s2ab = (s) => {
																	const buf = new ArrayBuffer(s.length);
																	const view = new Uint8Array(buf);
																	for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xFF;
																	return buf;
																};

																// Cria um blob a partir do buffer
																const blob = new Blob([s2ab(wbout)], { type: "application/octet-stream" });

																const url = window.URL.createObjectURL(blob);
																const a = document.createElement('a');
																a.style.display = 'none';
																a.href = url;
																a.download = fileName;
																document.body.appendChild(a);
																a.click();
																window.URL.revokeObjectURL(url);

																// Remove o link e libera o objeto URL
																window.URL.revokeObjectURL(url);
																document.body.removeChild(a);

															}

														} catch (err) {
															console.error({err});
															toastError(err);
														} finally {
															setLoading(false);
														}
													}

													fetchSpreadsheet();
												}
											}}
										>
											Download do Modelo
										</Button>
									</Box>

									{/* contatos */}
									<Box hidden={(contactsFromTags && !addVariables) || resendCampaign == true} className="col-md-12">
										<FileUploader
											disabled={!values.queue}
											disabledInput={!values.queue}
											csvUpload={csvUpload}
											contactsError={contactsError}
											getErrosExcel={getErrosExcel}
											formGroup={false}
										/>
									</Box>
									{
										timeEstimate != null &&
										<Alert severity="warning" style={{ width: '100%', marginBottom: '20px' }}>
											Tempo aproximado de {timeEstimate} para efetuar o envio da campanha.
										</Alert>
									}
									{
										picConnectionWarn != null &&
										<Alert severity="warning" style={{ width: '100%', marginBottom: '20px' }}>{picConnectionWarn}</Alert>
									}
									{campaignId && values.media &&
										<>
											<Box className="form-group col-md-6">
												<p style={{ margin: '1px 0px -10px 0px' }}>
													Arquivo enviado anteriormente.
												</p>
												<a onClick={() => { generateArchive(values.media, values.name); }} title="Download do arquivo que ja foi enviado anteriormente na campanha" className="btn btn-sm btn-secondary"
													style={{
														borderRadius: '5px',
														marginTop: '12px',
														padding: '4px',
														maxWidth: '260px',
													}}>
													<BackupIcon />
													<p style={{
														width: '238px',
														overflow: 'hidden',
														whiteSpace: 'nowrap',
														textOverflow: 'ellipsis',
													}}> {archiveName(values.media, values.name)}</p>
												</a>
											</Box>
										</>}


									<Box hidden={loading} className="form-group col-md-6">
										<p style={{ margin: '0px 0px -5px' }}>
											Envie arquivo
										</p>
										<input
											disabled={contacts.length <= 0 && selectedTagsIds.length == 0}
											accept=".doc,.docx,.xls,.xlsx,.ppt,.pptx,.pdf,.txt,.mp4,.mkv,.mov,.avi,.jpg,.jpeg,.png,.gif"
											style={{ display: 'none' }}
											id="uploadArquivo"
											type="file"
											name="arquivo"
											onChange={e => csvArhive(e)}
										/>
										<label htmlFor="uploadArquivo" style={{ marginTop: '6px', width: '100%' }}>
											<Button variant="contained" component="span" className={classes.button} color="primary" disabled={contacts.length <= 0 && selectedTagsIds.length == 0} style={{ padding: '13px 23px 13px 30px', width: '100%' }}>
												<p style={{ margin: '0px 5px -2px 0px' }}>ARQUIVO</p>
												<BackupIcon />
											</Button>
										</label>
									</Box>
									<Box hidden={loading} className="form-group col-md-6">
										<p style={{ margin: '0px 0px -5px' }}>
											Grave áudio
										</p>
										<Box>
											<Button
												onClick={() => {
													values.message = '';
													startRecording()
												}}
												title="Gravar áudio"
												id="start"
												className="btn btn-sm btn-success"
												disabled={contacts.length <= 0 && selectedTagsIds.length == 0}
												style={{
													height: '44px',
													borderRadius: '5px',
													marginTop: '6px',
													padding: '5px 30px 5px 30px',
													display: 'flex',
													width: '100%'
												}}
											>
												<SettingsVoiceIcon />
												<p style={{ marginTop: '15px' }}> Gravar</p>
											</Button>
											<Button onClick={() => { stopRecording() }} title="Parar Gravacao" id="stop" className="btn btn-sm btn-danger" style={{
												height: '42px',
												borderRadius: '5px',
												marginTop: '6px',
												padding: '14px',
												display: 'none',
												width: '100%'
											}}>
												<StopIcon color="black" />
												<p id="timerRec" style={{ marginTop: '15px' }}>Gravando...</p>
											</Button>
										</Box>
									</Box>
									<Box className="form-group col-md-12" style={{ display: 'flex', justifyContent: 'space-between' }}>
										<>
											<img id="imgUploaded" src="" style={{ height: '45px', borderRadius: '5px', display: 'none' }} />
											<p id="playlist" style={{ display: 'none' }}>
												<audio id="playerAudioUser" controls="controls" autobuffer="autobuffer"></audio>
											</p>
											<a onClick={() => { playAudio() }} title="Reproduzir Audio" id="play" className={classes.customButton} style={{ height: '35px', borderRadius: '30px', width: '35px', background: '#b1b1b1', margin: '0.57rem 10px 0px 10px', display: 'none' }}>
												<Box id="play_pause">
													<svg id="playerStart" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-play" viewBox="0 0 16 16" style={{ margin: "0px 0px 0px -8px" }}>
														<path d="M10.804 8 5 4.633v6.734L10.804 8zm.792-.696a.802.802 0 0 1 0 1.392l-6.363 3.692C4.713 12.69 4 12.345 4 11.692V4.308c0-.653.713-.998 1.233-.696l6.363 3.692z" />
													</svg>
													<svg id="playerEnd" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pause-fill" viewBox="0 0 16 16" style={{ margin: "0px 0px 0px -8px", display: 'none' }}>
														<path d="M5.5 3.5A1.5 1.5 0 0 1 7 5v6a1.5 1.5 0 0 1-3 0V5a1.5 1.5 0 0 1 1.5-1.5zm5 0A1.5 1.5 0 0 1 12 5v6a1.5 1.5 0 0 1-3 0V5a1.5 1.5 0 0 1 1.5-1.5z" />
													</svg>
												</Box>
											</a>
										</>
									</Box>

									<Box className="form-group col-md-12" style={{ display: 'flex', justifyContent: 'space-between' }}>
										<InputStyled
											as={Switch}
											label={
												<>
													Enviar mensagem com link para se descadastrar do recebimento de campanhas?
													<Tooltip
														title={
															<>
																<Typography gutterBottom color="inherit">A utilização deste recurso diminui a probabilidade de banimento do número da empresa, uma vez que o destinatário da mensagem terá a opção de se descadastrar dos recebimentos de campanhas realizadas através do número de disparo configurado na campanha, evitando desta forma que o destinatário clique na opção de "Spam" do WhatsApp.</Typography>
																<Typography gutterBottom color="inherit">Ao ativar esta função, o bloqueio aplica-se somente para envio de mensagens de campanhas do número WhatsApp da empresa que foi configurado na campanha (esta regra é por número WhatsApp da empresa), podendo interagir normalmente via atendimento.</Typography>
															</>
														}
														placement="bottom"
														style={{ marginLeft: "10px" }}
													>
														<HelpIcon />
													</Tooltip>
												</>
											}
											name="sendBlackListMessage"
											color="primary"
											checked={values.sendBlackListMessage}
											primaryClass="form-group"
											disabled={contacts.length <= 0 && selectedTagsIds.length == 0}
										/>
									</Box>

									<Box
										className="form-group col-md-12"
										style={{ display: values?.sendBlackListMessage == true ? 'flex' : 'none', justifyContent: 'space-between', marginBottom: 0 }}
									>
										<Box style={{ display: 'flex', width: '100%' }}>
											<Field
												id="inputBlackListMessage"
												as="textarea"
												style={{
													borderColor: '#c4c4c4',
													borderRadius: '3px',
													float: 'left'
												}}
												inputRef={greetingRef}
												rows='4'
												className='form-control'
												type="message"
												placeholder="Mensagem..."
												name="blackListMessage"
												maxLength="220"
												disabled={contacts.length <= 0 && selectedTagsIds.length == 0}
											/>
											<Box
												style={{
													float: "right",
													display: contacts.length <= 0 && selectedTagsIds.length == 0 ? 'none' : ''
												}}
											>
												<EmojiDataPicker onEmojiSelect={
													emoji => {
														setCampaign(() => {
															// verificar se já chegou no limite do textbox
															if (!isNaN(values.blackListMessage.length) && !isNaN(document.querySelector('#inputBlackListMessage').maxLength)) {
																if (values.blackListMessage.length >= (document.querySelector('#inputBlackListMessage').maxLength - 2)) return values;
															}
															return { ...values, blackListMessage: `${values?.blackListMessage}${emoji}` };
														})
													}}
													disabled={false}
												/>
											</Box>
										</Box>
									</Box>

									<Box
										className="form-group col-md-12"
										style={{ display: values?.sendBlackListMessage == true ? 'flex' : 'none', justifyContent: 'space-between' }}
									>
										<Typography style={{ display: 'inline-block', width: '100%' }}>
											{`${values.blackListMessage.length}/220`}
										</Typography>
									</Box>

									<Box>
										{
											values.sendBlackListMessage != true &&
											<Alert severity="warning" style={{ width: '100%', marginBottom: '20px' }}>
												Não utilizar a opção de envio do link de descadastramento de campanhas, pode aumentar a possibilidade de banimento de sua conexão/número WhatsApp.
											</Alert>
										}
									</Box>


									<Box hidden={loading} className={`form-group col-md-12 ${blockedMessage == 'disable' ? 'blockedMessage' : ''}`}>
										<label>
											Mensagem de disparo da campanha
											<span className="text-danger ml-2">*</span>
										</label>
										{mensagemVariavel.length > 0 && values.queue &&
											<Box style={{ display: values.queue || !campaignId ? 'flex' : 'none' }}>
												{mensagemVariavel.map(msg => (
													<Box style={{ padding: '5px' }}>
														<Chip
															color="primary"
															label={msg}
															onClick={e => {
																setCampaign(() => {
																	return { ...values, message: `${values?.message}{{${msg}}}` };
																})
																// voltar cursor para campo de texto
																let text_tmp = document.querySelector('textarea[name="message"]');
																if (text_tmp.setSelectionRange) {
																	text_tmp.focus();
																	text_tmp.setSelectionRange(-1, -1)
																} else if (text_tmp.createTextRange) {
																	let range = text_tmp.createTextRange();
																	range.collapse(true);
																	range.moveEnd('character', -1);
																	range.moveStart('character', -1);
																	range.select();
																}
															}}
														/>
													</Box>
												))}
											</Box>
										}
										<Box>
											<Box style={{ display: 'flex' }}>
												<Field
													disabled={contacts.length <= 0 && selectedTagsIds.length == 0}
													id="inputMessageCampaign"
													as="textarea"
													style={{
														borderColor: '#c4c4c4',
														borderRadius: '3px'
													}}
													inputRef={greetingRef}
													rows='4'
													className='form-control'
													type="message"
													placeholder="Mensagem..."
													name="message"
													maxLength={values?.sendBlackListMessage == true ? 800 : 1020}
													onBlur={() => {
														// certificar que todos os links tenha https
														if (!values.message) return;
														let array = values.message.split(" ");
														let response = '';
														for (let i = 0; i < array.length; i++) {
															if (CheckUrl(array[i]) == true && array[i].indexOf('https://') == -1) {
																let tmp = array[i].split('://');
																tmp = tmp[tmp.length - 1];
																response += `https://${tmp}`;
															}
															else { response += array[i] }

															// se não for a ultima posição adicionar um espaço
															if (i < (array.length - 1)) response += ' ';
														};

														setCampaign({ ...values, message: response });
													}}
												/>
												<Box>
													<EmojiDataPicker onEmojiSelect={
														emoji => {

															setCampaign(() => {
																// verificar se já chegou no limite do textbox
																if (!isNaN(values.message.length) && !isNaN(document.querySelector('#inputMessageCampaign').maxLength)) {
																	if (values.message.length >= (document.querySelector('#inputMessageCampaign').maxLength - 2)) return values;
																}
																return { ...values, message: `${values?.message}${emoji}` };
															})
														}}
														disabled={false}
													/>
												</Box>
											</Box>
											<Typography>
												{values?.sendBlackListMessage ? `${values.message.length}/800` : `${values.message.length}/1020`}
											</Typography>
										</Box>
									</Box>
								</Box>
							</DialogContent>
							<DialogActions>
								<Button
									onClick={handleClose}
									color="secondary"
									disabled={isSubmitting || validatingContacts}
									variant="outlined"
								>
									{i18n.t("queueModal.buttons.cancel")}
								</Button>

								<Button
									type="submit"
									color="primary"
									disabled={isSubmitting == true || validatingContacts == true ? true : false}
									variant="contained"
									className={classes.btnWrapper}
								>
									{campaignId
										? `${i18n.t("queueModal.buttons.okEdit")}`
										: `${i18n.t("queueModal.buttons.okAdd")}`}
									{isSubmitting && (
										<CircularProgress
											size={24}
											className={classes.buttonProgress}
										/>
									)}
								</Button>
							</DialogActions>
						</Form>
					)}
				</Formik>
			</Dialog >
		</Box>
	);
};

export default CampaignModal;