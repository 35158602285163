import { useContext, useEffect, useState } from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";

import { Grid, ListItemText, MenuItem, Select } from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { AuthContext } from "../../context/Auth/AuthContext";
import { i18n } from "../../translate/i18n";
import ButtonWithSpinner from "../ButtonWithSpinner";
import api from "../../services/api";
import toastError from "../../errors/toastError";

const BatchReopenTicketModal = ({ open, handleClose, onSave, loading }) => {
	const [whatsapps, setWhatsapps] = useState([]);
	const [selectedWhatsapp, setSelectedWhatsapp] = useState("");
	const [selectedQueue, setSelectedQueue] = useState("");
	const { user } = useContext(AuthContext);

  useEffect(() => {
	if (!open) {
		// Reset the state when the modal is closed
		setSelectedWhatsapp("");
	} else {
		const getWhatsapp = async () => {
			try {
				const { data } = await api.get("/whatsappListByUser");
				setWhatsapps(data);
			} catch (err) {
				console.error({err});
				toastError(err);
			}
		}

		getWhatsapp();
	}
  }, [open]);

  useEffect(() => {
	setSelectedQueue("");
  },[selectedWhatsapp])

  const handleCancel = () => {
		// Reset the state of SelectedQueue and close the modal when the user cancels
		setSelectedWhatsapp("");
		setSelectedQueue("");
		handleClose();
  };

	return (
		<>
			<Dialog open={open} onClose={handleClose}>
				<DialogTitle id="form-dialog-title">
					{i18n.t("batchReopen.title")}
				</DialogTitle>
				<DialogContent dividers>
					<Grid style={{ width: 300 }} container spacing={2}>
						<Grid style={{ marginTop: '15px' }} xs={12} item>
							<Select
								placeholder={i18n.t("batchReopen.whatsapp")}
								fullWidth
								inputProps={{ padding: 0 }}
								style={{
									padding: '0px 0px 4px 0px',
									margin: '-15px 1px -2px',
									height: '45px',
									fontSize: '15px'
								}}
								displayEmpty
								variant="outlined"
								value={selectedWhatsapp}
								onChange={(e) => { setSelectedWhatsapp(e.target.value) }}
								MenuProps={{
									anchorOrigin: {
										vertical: "bottom",
										horizontal: "left",
									},
									transformOrigin: {
										vertical: "top",
										horizontal: "left",
									},
									getContentAnchorEl: null,
								}}
								renderValue={() => {
									if (selectedWhatsapp === "") return i18n.t("batchReopen.whatsapp");

									const wpp = whatsapps.find(q => q.id === selectedWhatsapp)
									return wpp.name
								}}
							>
								{whatsapps?.length > 0 &&
									whatsapps.map((wpp, key) => (
										<MenuItem dense key={key} value={wpp.id}>
											<ListItemText primary={wpp.name} />
										</MenuItem>
									))}
							</Select>
						</Grid>
					</Grid>
					<Grid style={{ width: 300 }} container spacing={2}>
						<Grid style={{ marginTop: '15px' }} xs={12} item>
							<Select
								placeholder={i18n.t("batchReopen.queue")}
								fullWidth
								inputProps={{ padding: 0 }}
								style={{
									padding: '0px 0px 4px 0px',
									margin: '-15px 1px -2px',
									height: '45px',
									fontSize: '15px'
								}}
								displayEmpty
								variant="outlined"
								value={selectedQueue}
								onChange={(e) => { setSelectedQueue(e.target.value) }}
								disabled={selectedWhatsapp === ""}
								MenuProps={{
									anchorOrigin: {
										vertical: "bottom",
										horizontal: "left",
									},
									transformOrigin: {
										vertical: "top",
										horizontal: "left",
									},
									getContentAnchorEl: null,
								}}
								renderValue={() => {
									if (selectedQueue === "" || selectedWhatsapp === "" || !whatsapps[whatsapps.findIndex(wps => wps.id == selectedWhatsapp)]) return i18n.t("batchReopen.queue");

									const queue = whatsapps[whatsapps.findIndex(wps => wps.id == selectedWhatsapp)].Queues?.find(q => q.id === selectedQueue)
									return queue?.name || ""
								}}
							>
								{(selectedWhatsapp != '' && whatsapps[whatsapps.findIndex(wps => wps.id == selectedWhatsapp)].Queues?.length > 0) &&
									whatsapps[whatsapps.findIndex(wps => wps.id == selectedWhatsapp)].Queues.map((queue, key) => (
										<MenuItem dense key={key} value={queue.id}>
											<ListItemText primary={queue.name} />
										</MenuItem>
									))}
							</Select>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button
                        onClick={handleCancel}						
                        color="secondary"
						variant="outlined"
					>
						{i18n.t("batchReopen.buttons.cancel")}
					</Button>
					<ButtonWithSpinner
						variant="contained"
						type="button"
						disabled={!selectedQueue}
                        // onClick={handleSave}
						onClick={(event) => { onSave(event, "open", { whatsappId: selectedWhatsapp, queueId: selectedQueue, userId: user.id }) }}
						color="primary"
					>
						{i18n.t("batchReopen.buttons.confirm")}
					</ButtonWithSpinner>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default BatchReopenTicketModal;
