import { Container, Grid, Typography, Button } from "@material-ui/core"; // Corrigido para Material-UI v4
import { Can } from "../../components/Can";
import { i18n } from "../../translate/i18n";
import { KTSVG } from "../../../_metronic/helpers";
import { useContext, useState, useCallback } from "react";
import { AuthContext } from "../../context/Auth/AuthContext";
import WhatsAppTable from "../../components/WhatsApp/table";
import WhatsAppModal from "../../components/WhatsApp/modal";
import { makeStyles } from "@material-ui/core";

// Estilos usando makeStyles para manter o código organizado
const useStyles = makeStyles((theme) => ({
  container: {
    margin: "20px 10px",
    backgroundColor: "#fff",
    borderRadius: "10px",
    padding: "10px",
    width: "auto",
    maxWidth: "100%",
  },
  button: {
    padding: "7px",
    float: "right",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
	"&:hover": {
		backgroundColor: theme.palette.primary.main,
	},
    "& svg": {
		color: theme.palette.primary.contrastText,
		marginRight: '5px'
    },
  },
}));

const Connections = () => {
  const { user } = useContext(AuthContext);
  const [selectedWhatsApp, setSelectedWhatsApp] = useState(null);
  const [whatsAppModalOpen, setWhatsAppModalOpen] = useState(false);
  const [qrModalOpen, setQrModalOpen] = useState(false);
  const classes = useStyles(); // Hook para acessar as classes de estilo

  const handleOpenWhatsAppModal = () => {
    setSelectedWhatsApp(null);
    setWhatsAppModalOpen(true);
  };

  const handleCloseWhatsAppModal = useCallback(() => {
    setWhatsAppModalOpen(false);
    setSelectedWhatsApp(null);
  }, [setSelectedWhatsApp, setWhatsAppModalOpen]);

  return (
    <Container className={classes.container}>
      <WhatsAppModal
        open={whatsAppModalOpen}
        onClose={handleCloseWhatsAppModal}
        whatsAppId={!qrModalOpen && selectedWhatsApp?.id}
        userProfile={user.profile}
      />

      <Grid container spacing={5}>
        <Grid item sm={12} xs={12}>
          <Typography variant="h6">
            {i18n.t("connections.title")}
          </Typography>

          <Can
            role={user.profile}
            perform="connections-page:addConnection"
            yes={() => (
              <Button
                size="small"
                variant="contained"
                className={classes.button} // Aplicando estilos do makeStyles
                onClick={handleOpenWhatsAppModal}
              >
                <KTSVG
                  path="media/icons/duotune/arrows/arr075.svg"
                  className="svg-icon-3"
                />
                {i18n.t("connections.buttons.add")}
              </Button>
            )}
          />
        </Grid>
      </Grid>

      <WhatsAppTable />
    </Container>
  );
};

export default Connections;
