import { Grid, Popover, Tooltip, Typography } from '@material-ui/core';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { Button } from '@mui/material';
import React from 'react';
import { ReactComponent as FilterIcon } from '../../assets/svg/filter.svg';
import { i18n } from "../../translate/i18n";
import TicketsQueueSelect from "../TicketsQueueSelect";
import TicketsTagSelect from '../TicketsTagSelect';

const useStyles = makeStyles((theme) => ({
    typography: {
        padding: theme.spacing(2),
        fontSize: "0.875rem"
    },
    customPaper: {
        padding: '10px!important',
        boxShadow: '0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2)',
        width: '250px!important',
        borderRadius: '0!important'
    },
    icon: {
        height: '100%',
        padding: '8px',
        color: '#616161',
        cursor: 'pointer'
    },
    tooltip: {
        display: "flex",
        alignItems: "center"
    },
    title: {
        fontSize: "1.2rem",
        fontWeight: "700",
        marginBottom: "15px",
        borderBottom: "1px solid #eeeeee",
        paddingBottom: "5px",
        color: "#757575"
    },
    subtitle: {
        fontSize: "1.0rem",
        fontWeight: "500",
        marginBottom: "5px",
        color: "#757575",
        display: "block"
    },
    grid: {
        marginBottom: "10px"
    }
}));

export default function TicketSearch({ userQueues, selectedQueueIds = [], checked, onChange, onChecked, tags, tagsFilter, setTagsFilter }) {

    const classes = useStyles();
    const anchorRef = React.useRef(null);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'filter-popover' : undefined;

    return (
        <ButtonGroup variant="contained" color="primary" ref={anchorRef} aria-label="split button" style={{ boxShadow: "none" }}>
            <Tooltip
                title={
                    <Typography
                        variant="label"
                        className={classes.typography}
                    >
                        {i18n.t("ticketsSearch.tooltip")}
                    </Typography>
                }
                placement="bottom"
                className={classes.tooltip}
            >
                <FilterIcon aria-describedby={id} onClick={handleClick} className={classes.icon} />
            </Tooltip>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                classes={{ paper: classes.customPaper }}
            >
                <Typography
                    variant='h5'
                    className={classes.title}
                >
                    {i18n.t("ticketsSearch.title")}
                </Typography>

                <Grid container spacing={2} className={classes.grid}>
                    <Grid item xs={12}>
                        <Typography
                            variant="label"
                            className={classes.subtitle}
                        >
                            {i18n.t("ticketsSearch.queues")}
                        </Typography>
                        <TicketsQueueSelect
                            selectedQueueIds={selectedQueueIds}
                            userQueues={userQueues}
                            onChange={onChange}
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={2} className={classes.grid} style={{position: "relative"}}>
                    <Grid item xs={12}>
                        <Typography
                            variant="label"
                            className={classes.subtitle}
                        >
                            {i18n.t("ticketsSearch.tags")}
                        </Typography>
                        <TicketsTagSelect
                            selectedTagsIds={tagsFilter}
                            userTags={tags}
                            onChange={(e) => {
                                // minimizar atendimento ao trocar filtro de etiquetas
                                const btnClose = document.querySelector('#btnCloseTicketOnHeader');
                                if (btnClose) btnClose.click();
                                setTagsFilter(e)
                            }}
                            useText={
                                <Button variant="outlined" style={{ width: "100%", height: "35px", color: "rgba(0, 0, 0, .8)", borderColor: "rgba(0, 0, 0, 0.23)", textAlign: "left", display: "block", textTransform: "capitalize", paddingLeft: "10px" }}>
                                    {i18n.t("contacts.filters.tags")}
                                    <ArrowDropDownIcon style={{ position: "absolute", right: "7px", color: "rgba(0,0,0,.55)" }} />
                                </Button>
                            }
                        />
                    </Grid>
                </Grid>
            </Popover>
        </ButtonGroup>
    );
}
