import { useContext, useEffect, useRef, useState } from "react";

import { Field, Form, Formik } from "formik";
import { toast } from "react-toastify";
import * as Yup from "yup";
import AppBar from '@material-ui/core/AppBar';
import {
    Box,
    DialogContent,
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogTitle, FormControl, FormControlLabel, FormGroup,
    InputLabel, Select, MenuItem,
    ClickAwayListener,
    IconButton,
    TextField,
    makeStyles,
    Grid,
    Typography,
    Tab,
    Tabs
} from "@material-ui/core";
import Switch from '@material-ui/core/Switch';
import { AuthContext } from "../../context/Auth/AuthContext";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import MoodIcon from "@material-ui/icons/Mood";
import "./openingHourModal.css";
import EmojiDataPicker from "../EmojiDataPicker";

const useStyles = makeStyles((theme) => ({
    root: {
        flexWrap: "wrap",
        maxWidth: '500px'
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginRight: theme.spacing(1),
        width: "100%",
    },

    btnWrapper: {
        position: "relative",
    },

    buttonProgress: {
        //color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
    textTagContainer: {
        width: "100%",
    },
    tab: {
        backgroundColor: "transparent",
        color: "#000",
        boxShadow: "none"
    },
    tabPanel: {
        overflowY: "auto"
    },
    emojiBox: {
        position: "absolute",
        zIndex: 10,
    },
}));

const OpeningHourModal = ({
    open,
    onClose,
    openingHourId,
    initialValues,
    onSave,
}) => {
    const classes = useStyles();
    const isMounted = useRef(false);
    const { user } = useContext(AuthContext);
    const [tabValue, setTabValue] = useState(0);
    const [holidays, setHolidays] = useState([]);
    const [loading, setLoading] = useState(false);
    const [queues, setQueues] = useState([]);
    const [showEmoji, setShowEmoji] = useState(false);

    const initialState = {
        name: "",
        message: "",
        settings: {
            weekDays: [],
            holidays: [],
            startTime: "09:00",
            endTime: "18:00"
        },
        status: true,
        queues: []
    };
    const [openingHour, setOpeningHour] = useState(initialState);

    const weekDays = [
        { name: 'sun' },
        { name: 'mon' },
        { name: 'tue' },
        { name: 'wed' },
        { name: 'thu' },
        { name: 'fry' },
        { name: 'sat' }
    ];

    const OpeningHourSchema = Yup.object().shape({
        name: Yup.string()
            .required(i18n.t("openingHourModal.invalidMessages.name.required"))
            .max(15, i18n.t("openingHourModal.invalidMessages.name.max")),
        message: Yup.string()
            .required(i18n.t("openingHourModal.invalidMessages.message.required"))
            .max(1024, i18n.t("openingHourModal.invalidMessages.message.max")),
        weekDays: Yup.array()
            .test(
                "required",
                i18n.t("openingHourModal.invalidMessages.weekDays.required"),
                value => {
                    return !value || value.length <= 0 ? false : true;
                }
            ),
        startTime: Yup.string()
            .required(i18n.t("openingHourModal.invalidMessages.startTime.required")),
        endTime: Yup.string()
            .required(i18n.t("openingHourModal.invalidMessages.endTime.required")),
        status: Yup.boolean()
            .required(i18n.t("openingHourModal.invalidMessages.status.required")),
    });

    // reordenar para seguir sequencia correta
    if (OpeningHourSchema['_nodes']) OpeningHourSchema['_nodes'] = OpeningHourSchema['_nodes'].reverse();

    useEffect(() => {
        if (!isMounted.current) {
            isMounted.current = true;
            const fetchHolidays = async () => {
                setLoading(true);
                try {
                    const response = await fetch('https://brasilapi.com.br/api/feriados/v1/2024');
                    let data = await response.json();
                    // ordenar pelo nome
                    const orderByname = (arr) => {
                        arr.sort((a, b) => {
                            const nameA = a.name.toUpperCase();
                            const nameB = b.name.toUpperCase();

                            if (nameA < nameB) return -1;
                            else if (nameA > nameB) return 1;
                            else return 0;
                        });

                        return arr;
                    }
                    data = orderByname(data)
                    data.unshift({ id: 'selectAll', name: i18n.t("openingHourModal.buttons.selectAllHolidays"), date: 'selectAll' });
                    setHolidays(data);
                } catch (error) {
                    console.error('Erro ao buscar feriados:', error);
                } finally {
                    setLoading(false);
                }
            }

            const fetchQueues = async () => {
                setLoading(true);
                try {
                    let { data } = await api.get("/queue");
                    data.unshift({ id: 'selectAll', name: i18n.t("openingHourModal.buttons.selectAllQueues") });
                    setQueues(data);
                } catch (err) {
                    console.error({err});
                    toastError(err);
                } finally {
                    setLoading(false);
                }
            }

            fetchHolidays();
            fetchQueues();
        }
    }, []);

    useEffect(() => {
        if (open == false || !openingHourId) return;
        const fetchOpeningHour = async () => {
            setLoading(true);
            try {
                const { data } = await api.get(`/openingHour/${openingHourId}`);
                const props = {
                    name: data?.openingHours.name || '',
                    message: data?.openingHours.message || '',
                    settings: {
                        weekDays: data?.openingHours.settings?.weekDays || [],
                        holidays: data?.openingHours.settings?.holidays || [],
                        startTime: data?.openingHours.settings?.startTime || '09:00',
                        endTime: data?.openingHours.settings?.endTime || '18:00'
                    },
                    status: data?.openingHours.status == undefined ? true : data.openingHours.status,
                    queues: data?.openingHours?.queueId ? data.openingHours.queueId.map(q => String(q)) : []
                }
                if (props.queues.length == (queues.length - 1)) props.queues = ['selectAll', ...props.queues];
                if (props.settings.holidays.length == (holidays.length - 1)) props.settings.holidays = ['selectAll', ...props.settings.holidays];
                setOpeningHour(props);
            } catch (err) {
                console.error({ err });
                toastError(err);
            } finally {
                setLoading(false);
            }
        };

        fetchOpeningHour();
    }, [open]);

    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box p={3}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const handleSave = async () => {

        setLoading(true);
        // validações
        let validate = false;

        try {
            const validateData = {
                name: openingHour.name,
                message: openingHour.message,
                weekDays: openingHour.settings.weekDays,
                startTime: openingHour.settings.startTime,
                endTime: openingHour.settings.endTime,
                status: openingHour.status,
            };

            await OpeningHourSchema.validate(validateData);
            validate = true;

            let openingProps = openingHour;
            openingProps = { ...openingProps, settings: { ...openingProps.settings, holidays: openingProps.settings.holidays.filter(id => String(id).toLowerCase() != 'selectall') } };
            openingProps = { ...openingProps, queues: openingProps.queues.filter(id => String(id).toLowerCase() != 'selectall') };

            if (!openingHourId) await api.post("/openingHour", openingProps);
            else await api.put(`/openingHour/${openingHourId}`, openingProps);

            toast.success(i18n.t(`openingHourModal.successMessages.save`));
            handleClose();
        } catch (err) {
            console.error({err});
            if (validate == false) toastError({ response: { data: { error: err?.message || 'INTERNAL_ERROR' } } });
            else { toastError(err); }
        } finally {
            setLoading(false);
        }
    }

    const handleTabChange = (event, newValue) => setTabValue(newValue);

    const handleClose = () => {
        onClose();
        setOpeningHour(initialState);
        setTabValue(0);
    };

    const handleQueuesChange = (e) => {
        // verificar se já está selecionado, se não adicionar
        if (!e?.target?.value) return;

        const val = e.target.value;
        const index = openingHour.queues.findIndex((sq) => sq == val);

        // verificar se é selecionar todos
        if (String(val).toLowerCase() == 'selectall') {
            // verificar se é para selecionar ou deselecionar
            if (index === -1) {
                // selecionar
                setOpeningHour({ ...openingHour, queues: queues.map(q => String(q.id)) });
            } else {
                // deselecionar
                setOpeningHour({ ...openingHour, queues: [] });
            }
            return;
        }

        let queuesTmp = openingHour.queues;
        if (index === -1) setOpeningHour({ ...openingHour, queues: [...queuesTmp, val] });
        else setOpeningHour({ ...openingHour, queues: queuesTmp.filter((sq) => sq != val) });
    }

    const handleHolidaysChange = (e) => {
        // verificar se já está selecionado, se não adicionar
        if (!e?.target?.value) return;
        const val = e.target.value;
        const index = openingHour.settings.holidays.findIndex((sh) => sh == val);
        // verificar se é selecionar todos
        if (String(val).toLowerCase() == 'selectall') {
            // verificar se é para selecionar ou deselecionar
            if (index === -1) {
                // selecionar
                setOpeningHour({ ...openingHour, settings: { ...openingHour.settings, holidays: holidays.map(h => h.date) } });
            } else {
                // deselecionar
                setOpeningHour({ ...openingHour, settings: { ...openingHour.settings, holidays: [] } });
            }
            return;
        }

        let holidaysTmp = openingHour.settings.holidays;
        if (index === -1) setOpeningHour({ ...openingHour, settings: { ...openingHour.settings, holidays: [...holidaysTmp, val] } });
        else setOpeningHour({ ...openingHour, settings: { ...openingHour.settings, holidays: holidaysTmp.filter((sh) => sh != val) } });

    }

    const handleWeekdaysChange = (str) => {
        // verificar se já está selecionado, se não adicionar
        if (!str) return;

        const val = str;
        const index = openingHour.settings.weekDays.findIndex((sh) => sh == val);

        let weekDaysTmp = openingHour.settings.weekDays;
        if (index === -1) setOpeningHour({ ...openingHour, settings: { ...openingHour.settings, weekDays: [...weekDaysTmp, val] } });
        else setOpeningHour({ ...openingHour, settings: { ...openingHour.settings, weekDays: weekDaysTmp.filter((sh) => sh != val) } });
    }

    const showPicker = (elementId) => {
        const element = document.getElementById(elementId);
        if (!element) return;
        element.showPicker();
    }

    const formatDates = (date) => {
        const parts = date.split('-');
        return `${parts[2]}/${parts[1]}/${parts[0]}`;
    }

    return (
        <div className={classes.root}>
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="md"
                fullWidth
                scroll="paper"
            >
                <DialogTitle
                    id="form-dialog-title"
                    style={{
                        position: "sticky",
                        top: "0",
                        background: "#fff",
                        zIndex: "10"
                    }}
                >
                    {openingHourId
                        ? `${i18n.t("openingHourModal.title.edit")}`
                        : `${i18n.t("openingHourModal.title.add")}`}
                </DialogTitle>
                <Formik
                    initialValues={openingHour}
                    enableReinitialize={true}
                    onSubmit={(values, actions) => {
                        handleSave();
                    }}
                >
                    {({ values, errors, touched }) => (
                        <Form style={{ padding: "10px" }}>
                            <DialogContent
                                style={{
                                    overflowY: "hidden"
                                }}
                            >

                                <Grid container spacing={5}>
                                    <Grid item sm={12} xs={12} mb={2}>
                                        <Typography component="label">
                                            {i18n.t("openingHourModal.form.name")}
                                            <Typography component="span" className="text-danger ml-2">*</Typography>
                                        </Typography>
                                        <Field
                                            style={{
                                                borderColor: '#c4c4c4',
                                                borderRadius: '3px'
                                            }}
                                            className='form-control'
                                            placeholder={i18n.t("openingHourModal.form.name")}
                                            name="name"
                                            id="name"
                                            autoFocus
                                            maxLength={50}
                                            onChange={(e) => { setOpeningHour({ ...openingHour, name: e.target.value }) }}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container spacing={5}>
                                    <Grid item sm={12} xs={12} mb={2} style={{ position: "relative" }}>
                                        <Typography component="label">
                                            {i18n.t("openingHourModal.form.message")}
                                            <Typography component="span" className="text-danger ml-2">*</Typography>
                                        </Typography>
                                        <Field
                                            style={{
                                                borderColor: '#c4c4c4',
                                                borderRadius: '3px',
                                                height: '100px'
                                            }}
                                            className='form-control'
                                            placeholder={i18n.t("openingHourModal.form.message")}
                                            name="message"
                                            id="message"
                                            as="textarea"
                                            type="message"
                                            maxLength={1024}
                                            onBlur={(e) => {
                                                setOpeningHour({ ...openingHour, message: e.target.value });
                                            }}
                                        />
                                        <Box style={{ width: "fit-content", position: "absolute", top: "42px", right: "25px" }}>
                                            <EmojiDataPicker
                                                onEmojiSelect={
                                                    emoji => {
                                                        setOpeningHour(() => {
                                                            // verificar se já chegou no limite do textbox
                                                            if (!isNaN(openingHour.message.length) && !isNaN(document.querySelector('#message').maxLength)) {
                                                                if (openingHour.message.length >= (document.querySelector('#message').maxLength - 2)) return values;
                                                            }
                                                            return { ...openingHour, message: `${openingHour.message} ${emoji}` }
                                                        })
                                                    }}
                                            />

                                        </Box>
                                        <Typography>{`${openingHour.message.length}/1024`}</Typography>
                                    </Grid>
                                </Grid>

                                <Grid container spacing={5}>
                                    <Grid item sm={12} xs={12} mb={2}>
                                        <Typography component="label">
                                            {i18n.t("openingHourModal.form.weekdays")}
                                            <Typography component="span" className="text-danger ml-2">*</Typography>
                                        </Typography>
                                        <>
                                            <Box>
                                                <FormGroup style={{ display: 'contents' }}>
                                                    {weekDays.map((day, i) => (
                                                        <FormControlLabel
                                                            key={day.name}
                                                            name="weekDays"
                                                            control={
                                                                <Checkbox
                                                                    name={day.name}
                                                                    onChange={() => handleWeekdaysChange(day.name)}
                                                                    value={day.name}
                                                                    checked={openingHour.settings.weekDays.includes(day.name)}
                                                                />
                                                            }
                                                            label={i18n.t(`openingHourModal.form.weekDays.${day.name}`)}
                                                            style={{ margin: '0px' }}
                                                        />
                                                    ))}
                                                </FormGroup>
                                            </Box>
                                        </>
                                    </Grid>
                                </Grid>

                                <Grid container spacing={5}>
                                    <Grid item sm={6} xs={12} mb={2}>
                                        <Typography component="label">
                                            {i18n.t("openingHourModal.form.hourStartName")}
                                            <Typography component="span" style={{ marginLeft: '5px' }} color="secondary">*</Typography>
                                        </Typography>
                                        <TextField
                                            id="startTime"
                                            name="startTime"
                                            type="time"
                                            value={openingHour?.settings?.startTime ? openingHour.settings.startTime : "09:00"}
                                            className={classes.textField}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onFocus={() => showPicker('startTime')}
                                            inputProps={{ step: 300 }}
                                            onChange={(e) => { setOpeningHour({ ...openingHour, settings: { ...openingHour.settings, startTime: e.target.value } }) }}
                                        />
                                    </Grid>
                                    <Grid item sm={6} xs={12} mb={2}>
                                        <Typography component="label">
                                            {i18n.t("openingHourModal.form.hourEndName")}
                                            <Typography component="span" style={{ marginLeft: '5px' }} color="secondary">*</Typography>
                                        </Typography>
                                        <TextField
                                            id="endTime"
                                            name="endTime"
                                            type="time"
                                            defaultValue={openingHour?.settings?.endTime || "18:00"}
                                            value={openingHour?.settings?.endTime ? openingHour.settings.endTime : "18:00"}
                                            className={classes.textField}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onFocus={() => showPicker('endTime')}
                                            inputProps={{ step: 300, }}
                                            onChange={(e) => { setOpeningHour({ ...openingHour, settings: { ...openingHour.settings, endTime: e.target.value } }) }}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container spacing={5}>
                                    <Grid item sm={12} xs={12} mb={2}>
                                        <Typography component="label">
                                            {i18n.t("openingHourModal.form.status")}
                                            <Typography component="span" className="text-danger ml-2">*</Typography>
                                        </Typography>
                                        <Field
                                            as={Switch}
                                            color="primary"
                                            name="status"
                                            id="status"
                                            checked={openingHour.status}
                                            onChange={(e) => { setOpeningHour({ ...openingHour, status: e.target.checked }) }}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container spacing={5}>
                                    <Grid item sm={12} xs={12} mb={2}>
                                        <AppBar position="static" className={classes.tab}>
                                            <Tabs value={tabValue} onChange={handleTabChange} aria-label="simple tabs" TabIndicatorProps={{ style: { background: '#0b708c' } }}>
                                                <Tab label={i18n.t("openingHourModal.form.tabs.holidays")}
                                                    {...a11yProps(0)}
                                                />
                                                <Tab label={i18n.t("openingHourModal.form.tabs.linkQueues")}
                                                    {...a11yProps(1)}
                                                />
                                            </Tabs>
                                        </AppBar>
                                        <TabPanel value={tabValue} index={0} className={classes.tabPanel}>
                                            {
                                                holidays.map(holiday => (
                                                    <Grid container spacing={1}>
                                                        <Grid item sm={12} xs={12} mb={2}>
                                                            <FormControlLabel
                                                                color="success"
                                                                style={{ margin: '0px' }}
                                                                control={
                                                                    <Checkbox
                                                                        defaultChecked={openingHour.settings.holidays.includes(holiday.date)}
                                                                        id={holiday.name}
                                                                        name={holiday.name}
                                                                        onChange={e => handleHolidaysChange(e)}
                                                                        value={holiday.date} />
                                                                }
                                                                label={String(holiday.date).toLowerCase() == 'selectall' ? `${holiday.name}` : `${holiday.name} - ${formatDates(holiday.date)}`} />

                                                        </Grid>
                                                    </Grid>
                                                ))
                                            }
                                        </TabPanel>
                                        <TabPanel value={tabValue} index={1} className={classes.tabPanel}>
                                            {
                                                queues.map(queue => (
                                                    <Grid container spacing={1}>
                                                        <Grid item sm={12} xs={12} mb={2}>
                                                            <FormControlLabel
                                                                color="success"
                                                                style={{ margin: '0px' }}
                                                                control={
                                                                    <Checkbox
                                                                        defaultChecked={openingHour.queues.includes(String(queue.id))}
                                                                        id={queue.name}
                                                                        name={queue.name}
                                                                        onChange={e => handleQueuesChange(e)}
                                                                        value={queue.id} />
                                                                }
                                                                label={queue.name} />

                                                        </Grid>
                                                    </Grid>
                                                ))
                                            }
                                        </TabPanel>
                                    </Grid>
                                </Grid>

                            </DialogContent>
                            <DialogActions
                                style={{
                                    position: "sticky",
                                    bottom: "0",
                                    right: "0",
                                    zIndex: "10",
                                    background: "#fff"
                                }}
                            >
                                <Button
                                    onClick={handleClose}
                                    color="secondary"
                                    disabled={loading}
                                    variant="outlined"
                                >
                                    {i18n.t("openingHourModal.buttons.cancel")}
                                </Button>
                                <Button
                                    type="submit"
                                    color="primary"
                                    disabled={loading}
                                    variant="contained"
                                    className={classes.btnWrapper}
                                >
                                    {openingHourId
                                        ? `${i18n.t("openingHourModal.buttons.okEdit")}`
                                        : `${i18n.t("openingHourModal.buttons.okAdd")}`}
                                    {loading && (
                                        <CircularProgress
                                            size={24}
                                            className={classes.buttonProgress}
                                        />
                                    )}
                                </Button>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </Dialog>
        </div >
    );
};

export default OpeningHourModal;