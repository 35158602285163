import { Checkbox, IconButton, Menu, MenuItem } from "@material-ui/core";
import Box from '@material-ui/core/Box';
import TagIcon from '@material-ui/icons/LocalOffer';
import { FormControlLabel } from "@mui/material";
import Tooltip from '@mui/material/Tooltip';
import { useState } from 'react';

const TicketsTagSelect = ({ userTags, selectedTagsIds = [], onChange, useText = null }) => {
	const [anchorEl, setAnchorEl] = useState(null);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleTagSelection = (tagId) => {

		// verificar se é selecionar todos
		if (String(tagId).toLowerCase() == 'selectall') {
			// verificar se é para selecionar ou deselecionar
			if (!selectedTagsIds.includes(tagId)) {
				// selecionar
				const tagsMapped = userTags.map(t => t.id);
				onChange(tagsMapped);
			} else {
				// deselecionar
				onChange([]);
			}
			return;
		}

		let updatedTags;

		if (selectedTagsIds.includes(tagId)) {
			updatedTags = selectedTagsIds.filter(id => id !== tagId);
		} else {
			updatedTags = [...selectedTagsIds, tagId];
		}

		onChange(updatedTags);
	};

	const formatQueues = (queues) => {
		return queues.findIndex(x => x.id === null) === -1 ? queues : queues;
	}

	if (userTags) {
		userTags = formatQueues(userTags);
	}

	return (
		<>
			<Tooltip title={!useText && "Etiquetas"}>
				{!useText ?
					<IconButton style={{ padding: '0px' }} onClick={handleClick}>
						<TagIcon style={{ cursor: 'pointer', height: '15px', width: '15px' }} margin="dense" variant="outlined" color="action" />
					</IconButton>
					:
					<Box component="span" onClick={handleClick} style={{ position: "relative", height: '43px' }} aria-haspopup="true" aria-controls="tags-menu">
						{useText}
					</Box>
				}
			</Tooltip>
			<Menu
				id="tags-menu"
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'left',
				}}
				PaperProps={{
					style: {
						maxHeight: "300px",
						width: "230px",
						marginTop: "17px"
					},
				}}
				keepMounted
			>
				{userTags?.length > 0 &&
					userTags.map(queue => (
						<MenuItem dense key={queue.id}>
							{/* <ListItemText primary={queue.name} /> */}
							<FormControlLabel control={<Checkbox
								style={{
									color: queue.color,
								}}
								size="small"
								color="primary"
								checked={selectedTagsIds.includes(queue.id)}
								onChange={() => handleTagSelection(queue.id)}
							/>} label={queue.name} />
						</MenuItem>
					))}
			</Menu>
		</>
	);
};

export default TicketsTagSelect;
