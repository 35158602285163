import JsonParse from "./JsonParse";
import { io } from "socket.io-client";
import socket from "../hooks/useSocket";

// Constantes para limites e intervalos
const LOOP_MINUTES = 60;
const MINUTES_LIMIT = 4320; // 3 dias em minutos
const MILLISECONDS_IN_MINUTE = 1000 * 60;

// Função utilitária para emitir eventos via socket
const emitDraftEvent = (action, payload) => {
    socket.emit('drafts', { action, payload });
};

// Salvar ou atualizar rascunho no localStorage
export const saveDraft = (uuid, text, adminId, userId) => {
    const sanitizedText = text?.trim() && text !== 'undefined' ? text.trim() : '';
    const drafts = listDrafts();
    const idx = drafts.findIndex(draft => draft.uuid === uuid);

    const props = {
        uuid,
        text: sanitizedText,
        timestamp: Date.now(),
        userId,
        adminId
    };

    if (idx !== -1) {
        drafts[idx] = props;
    } else {
        drafts.push(props);
    }

    localStorage.setItem('drafts', JSON.stringify(drafts));
    emitDraftEvent('update', props);
};

// Excluir rascunho do localStorage
export const deleteDraft = (uuid, adminId, userId) => {
    const drafts = listDrafts();
    const idx = drafts.findIndex(draft => draft.uuid === uuid);

    if (idx !== -1) {
        drafts.splice(idx, 1);
        localStorage.setItem('drafts', JSON.stringify(drafts));
        emitDraftEvent('delete', { uuid, userId, adminId });
    }
};

// Listar todos os rascunhos
export const listDrafts = () => {
    const drafts = JsonParse(localStorage.getItem('drafts')) || [];
    return Array.isArray(drafts) ? drafts : [];
};

// Visualizar rascunho específico
export const showDraft = (uuid) => listDrafts().find(draft => draft.uuid === uuid);

// Função para verificar rascunhos antigos e apagá-los se necessário
const checkTimestampDifference = () => {
    const drafts = listDrafts();
    const currentTime = Date.now();

    drafts.forEach((draft, index) => {
        if (!draft?.timestamp) return;

        const differenceInMinutes = Math.round((currentTime - draft.timestamp) / MILLISECONDS_IN_MINUTE);

        if (differenceInMinutes > MINUTES_LIMIT) {
            emitDraftEvent('delete', {
                uuid: draft.uuid,
                userId: draft.userId,
                adminId: draft.adminId
            });
            drafts.splice(index, 1);
        }
    });

    localStorage.setItem('drafts', JSON.stringify(drafts));

    // Reexecuta a função após LOOP_MINUTES minutos
    setTimeout(checkTimestampDifference, LOOP_MINUTES * MILLISECONDS_IN_MINUTE);
};

// Inicializa a verificação de timestamps
checkTimestampDifference();