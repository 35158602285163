const GetShort = (str) => {
    if (!str) return '';
    const arr = str.split(" ");
    if (arr.length >= 2 && arr[arr.length - 1][0]) {
        return arr[0][0] + arr[arr.length - 1][0];
    } else {
        return arr[0][0] + arr[0][Math.floor(arr[0].length/2)];
    }
};

export default GetShort;