import { useEffect, useReducer, useState } from "react";

import { Switch } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { format, parseISO } from "date-fns";
import { toast } from "react-toastify";
import openSocket from "socket.io-client";
import MainContainer from "../../components/MainContainer";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import socket from "../../hooks/useSocket";
import { TableCell, TableBody, TableRow, Table, Grid, TableHead, Container, Typography } from "@material-ui/core";
import { i18n } from "../../translate/i18n";

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
  modalStyle: {
    minWidth: '310px',
    width: 'auto',
    margin: '30px 20px!important',
    padding: '30px',
    background: '#fff',
    borderRadius: '10px',
    maxWidth: 'calc(100% - 20px)!important',
    "@media (max-width: 740px)": {
      padding: '0px !important',
      marginTop: '65px'
    }
  },
}));
const CustomerRelationship = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  return (
    <MainContainer>
      <Container
        className={classes.modalStyle}
      >

        <Grid container spacing={5}>
          <Grid item sm={12} xs={12} mb={2}>
            <Typography variant="h6">tchururu</Typography>
          </Grid>
        </Grid>

        <Grid container spacing={5}>
          <Grid item sm={12} xs={12} mb={2}>
          asdasdasdasdasdasdasdasd
          </Grid>
        </Grid>
      </Container>
    </MainContainer>

  )
}

export default CustomerRelationship;
