import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogTitle,
    makeStyles,
    Tab,
    Tabs
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import { Box, DialogContent, Grid, IconButton, MenuItem, Select, Switch, TextField, Typography } from "@mui/material";
import { Field, Form, Formik } from "formik";
import PropTypes from 'prop-types';
import React, { useEffect, useState } from "react";
import { i18n } from "../../../translate/i18n";
import integrationNames from "./integrationNames.json";
import integrationMethods from "./integrationMethods.json";
import api from "../../../services/api";
import { toast } from "react-toastify";
import { withStyles } from '@material-ui/core/styles';
import IntegrationHeaderTab from "../Tabs/Headers";
import * as Yup from "yup";
import toastError from "../../../errors/toastError";

const useStyles = makeStyles((theme) => ({
    root: {
        flexWrap: "wrap",
        maxWidth: '500px'
    },
    textField: {
        marginRight: theme.spacing(1),
        width: "100%",
    },

    btnWrapper: {
        position: "relative",
    },

    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
    textTagContainer: {
        width: "100%",
    },
    tabs: {
        background: 'transparent',
        color: '#000'
    }
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Box
            role="tabpanel"
            hidden={value !== index}
            id={`interation-tabpanel-${index}`}
            aria-labelledby={`interations-${index}`}
            component="div"
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </Box>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `interation-tab-${index}`,
        'aria-controls': `interation-tabpanel-${index}`,
    };
}

const ApiModal = ({ open, onClose, integrationId }) => {
    const classes = useStyles();
    const [submittingContent, setSubmittingContent] = useState(false);
    const [connections, setConnections] = useState([]);
    const [value, setValue] = useState(0);

    const initialState = {
        id: null,
        title: '',
        name: integrationNames['api'][0],
        url: '',
        method: integrationMethods['api'][0],
        body: 'JSON',
        type: 'api',
        auth: { option: 'NOAUTH', user: '', password: '' },
        headers: [
            { key: "Access-Control-Allow-Origin", value: "*" },
            { key: "Access-Control-Allow-Headers", value: "Authorization" },
        ],
        fields: []
    };
    const [integration, setIntegration] = useState(initialState);

    useEffect(() => {
        if (open == false) return;
        
        // Função para recuperar suas conexões
        (async () => {
            try {
                const resp = await api.get('/whatsappListAll', { params: { disabled: '1' } });
                const { data } = resp;

                if (!resp || resp.status !== 200) throw new Error();

                if (data) setConnections(data.filter(dt => dt.state == 1))

                if (integrationId) {
                    const { data: integrationData } = await api.get(`/integrations/${integrationId}`);
                    setIntegration(integrationData);
                }
            } catch (error) {
                console.error({ err: error });
                toast.error('Erro ao resgatar conexões, contate o administrador.');
            }
        })()
    }, [open]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    // Handlers
    const handleClose = () => {
        setIntegration(initialState);
        setConnections([]);
        setValue(0);
        onClose();
    };

    const handleSave = async () => {
        // validações
        setSubmittingContent(true);

        // validações
        let validate = false;
        try {
            const validateData = {
                title: integration.title,
                name: integration.name,
                method: integration.method,
            };
            await CheckApiSchema.validate(validateData);
            validate = true;

            if (!integrationId) {
                // cadastrar
                await api.post("/integrations", integration);
            } else {
                // atualizar
                await api.put(`/integrations/${integrationId}`, integration);
            }

            toast.success(i18n.t(`integrations.api.form.validations.success`));
            handleClose();
        } catch (err) {
            console.error({ err });
            if (validate == false) toastError({ response: { data: { error: err?.message || 'INTERNAL_ERROR' } } });
            else {
                toastError(err);
            }
        } finally {
            setTimeout(() => {
                setSubmittingContent(false);
            }, 500);
        }

    }

    // gravar conexão nos fields da integração
    const handleConnection = (connectionId) => {
        let fields = integration?.fields;
        if (!fields || !Array.isArray(fields)) return;
        const idx = fields.findIndex(field => field.key == 'connectionId');
        const obj = { key: 'connectionId', value: connectionId };

        if (idx >= 0) fields[idx] = obj;
        else fields.push(obj);

        setIntegration({ ...integration, fields });
    }

    // gravar escolha do reenvio de respostas
    const handleSendContactAnswer = (status) => {
        let fields = integration?.fields;
        if (!fields || !Array.isArray(fields)) return;
        const idx = fields.findIndex(field => field.key == 'sendContactAnswer');
        const obj = { key: 'sendContactAnswer', value: status };

        if (idx >= 0) fields[idx] = obj;
        else fields.push(obj);

        setIntegration({ ...integration, fields });
    }

    // gravar url de reenvio
    const handleSendContactAnswerUrl = (url) => {
        let fields = integration?.fields;
        if (!fields || !Array.isArray(fields)) return;
        const idx = fields.findIndex(field => field.key == 'sendContactAnswerUrl');
        const obj = { key: 'sendContactAnswerUrl', value: url };

        if (idx >= 0) fields[idx] = obj;
        else fields.push(obj);

        setIntegration({ ...integration, fields });
    }

    // gravar mensagem de resposta ao contato
    const handleAnswerContactMessage = (url) => {
        let fields = integration?.fields;
        if (!fields || !Array.isArray(fields)) return;
        const idx = fields.findIndex(field => field.key == 'answerContactMessage');
        const obj = { key: 'answerContactMessage', value: url };

        if (idx >= 0) fields[idx] = obj;
        else fields.push(obj);

        setIntegration({ ...integration, fields });
    }

    // Switch customizado
    const AntSwitch = withStyles((theme) => ({
        root: {
            width: 28,
            height: 16,
            padding: 0,
            display: 'flex',
        },
        switchBase: {
            padding: 2,
            color: theme.palette.grey[500],
            '&$checked': {
                transform: 'translateX(12px)',
                color: theme.palette.common.white,
                '& + $track': {
                    opacity: 1,
                    backgroundColor: theme.palette.primary.main,
                    borderColor: theme.palette.primary.main,
                },
            },
        },
        thumb: {
            width: 12,
            height: 12,
            boxShadow: 'none',
        },
        track: {
            border: `1px solid ${theme.palette.grey[500]}`,
            borderRadius: 16 / 2,
            opacity: 1,
            backgroundColor: theme.palette.common.white,
        },
        checked: {},
    }))(Switch);

    // validação de campos
    const CheckApiSchema = Yup.object().shape({
        title: Yup.string()
            .required(i18n.t(`integrations.api.form.validations.titleRequired`))
            .min(5, i18n.t(`integrations.api.form.validations.titleMin`)),
        name: Yup.string()
            .required(i18n.t(`integrations.api.form.validations.nameRequired`))
            .min(5, i18n.t(`integrations.api.form.validations.nameMin`)),
        method: Yup.string()
            .required(i18n.t(`integrations.api.form.validations.methodRequired`))
            .min(2, i18n.t(`integrations.api.form.validations.methodMin`)),
        connectionId: Yup.string()
            .test(
                "check-connectionId",
                i18n.t(`integrations.api.form.validations.connectionRequired`),
                async value => {
                    const connectionId = integration?.fields?.find(field => field.key == 'connectionId')?.value || '';
                    return (!connectionId || connectionId == '') ? false : true;
                }
            ),
        url: Yup.string()
            .test(
                "check-url",
                i18n.t(`integrations.api.form.validations.urlRequired`),
                async value => {
                    const sendContactAnswer = integration?.fields?.find(field => field.key == 'sendContactAnswer')?.value == true ? true : false;
                    if (!sendContactAnswer) return true;
                    const url = integration?.fields?.find(field => field.key == 'sendContactAnswerUrl')?.value || '';
                    return !url || url == '' ? false : true;
                }
            ),
    });
    // reordenar para seguir sequencia correta
    if (CheckApiSchema['_nodes']) CheckApiSchema['_nodes'] = CheckApiSchema['_nodes'].reverse();

    // Renderização
    return (
        <div className={classes.root}>
            <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth scroll="paper" disableEscapeKeyDown={true} disableBackdropClick={true}>
                <DialogTitle id="form-dialog-title" style={{ paddingBottom: 0 }}>
                    {
                        !integrationId
                            ?
                            i18n.t(`integrations.api.form.create`)
                            :
                            i18n.t(`integrations.api.form.update`)
                    }
                </DialogTitle>
                <Formik
                    initialValues={integration}
                    enableReinitialize={true}
                    onSubmit={(values, actions) => {
                        handleSave();
                    }}
                >
                    {({ values, errors, touched }) => (
                        <Form style={{ padding: "10px" }}>
                            <DialogContent>
                                <Field
                                    as={TextField}
                                    type="hidden"
                                    name="adminId"
                                    hidden={true}
                                />

                                {/* nome e método */}
                                <Grid container spacing={2}>
                                    <Grid item xs={8} mb={2}>
                                        <Typography component="label">
                                            {i18n.t(`integrations.api.form.name`)}
                                            <Typography component="span" className="text-danger ml-2">*</Typography>
                                        </Typography>
                                        <Field
                                            style={{
                                                height: '45px'
                                            }}
                                            className='form-control'
                                            variant="outlined"
                                            as={Select}
                                            value={integration.name || ''}
                                            onChange={(e) => setIntegration({ ...integration, name: e.target.value })}
                                        >
                                            {integrationNames['api'].map((name, index) => (
                                                <MenuItem value={name} >{name}</MenuItem>
                                            ))}
                                        </Field>
                                    </Grid>
                                    <Grid item xs={4} mb={2}>
                                        <Typography component="label">
                                            {i18n.t(`integrations.api.form.method`)}
                                            <Typography component="span" className="text-danger ml-2">*</Typography>
                                        </Typography>
                                        <Field
                                            style={{
                                                height: '45px'
                                            }}
                                            className='form-control'
                                            variant="outlined"
                                            as={Select}
                                            disabled={integration.name == 'SEND_MESSAGES'}
                                            value={integration.method || ''}
                                        >
                                            {integrationMethods['api'].map(m => (
                                                <MenuItem value={m}>{m}</MenuItem>
                                            ))}
                                        </Field>
                                    </Grid>
                                </Grid>

                                {/* titulo */}
                                <Grid container spacing={2}>
                                    <Grid item xs={12} mb={2}>
                                        <Typography component="label">
                                            {i18n.t(`integrations.api.form.title`)}
                                            <Typography component="span" className="text-danger ml-2">*</Typography>
                                        </Typography>
                                        <Field
                                            style={{
                                                borderColor: '#c4c4c4',
                                                borderRadius: '3px',
                                                resize: 'none',
                                            }}
                                            className='form-control'
                                            placeholder={i18n.t(`integrations.api.form.title`)}
                                            maxLength={100}
                                            value={integration.title || ''}
                                            onChange={(e) => setIntegration({ ...integration, title: e.target.value })}
                                        />
                                    </Grid>
                                </Grid>

                                {
                                    integration.name == 'SEND_MESSAGES' &&
                                    <>

                                        {/* conexão */}
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} mb={2}>
                                                <Typography component="label">
                                                    {i18n.t(`integrations.api.form.connection`)}
                                                    <Typography component="span" className="text-danger ml-2">*</Typography>
                                                </Typography>
                                                <Field
                                                    style={{
                                                        height: '45px'
                                                    }}
                                                    className='form-control'
                                                    variant="outlined"
                                                    as={Select}
                                                    value={integration?.fields?.find(field => field.key == 'connectionId')?.value || ''}
                                                    onChange={(e) => handleConnection(e.target.value)}
                                                >
                                                    {connections.map((connection, index) => (
                                                        <MenuItem
                                                            value={connection.id}
                                                            disabled={String(connection.option).toLowerCase() != 'massive' || String(connection.status).toLowerCase() != 'connected'}
                                                        >
                                                            {
                                                                String(connection.option).toLowerCase() != 'massive'
                                                                    ?
                                                                    `${connection.name} - Conexão apenas para atendimentos`
                                                                    :
                                                                    String(connection.status).toLowerCase() != 'connected'
                                                                        ?
                                                                        `${connection.name} - Desconectado, escaneie o QRCODE`
                                                                        :
                                                                        `${connection.name} - (${connection.numberConnection}) - Conectado`

                                                            }
                                                        </MenuItem>
                                                    ))}
                                                </Field>
                                            </Grid>
                                        </Grid>

                                        {/* resposta padrão para quando o usuário responder */}
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} mb={2}>
                                                <Typography component="label">
                                                    {i18n.t(`integrations.api.form.answerContact`)}
                                                </Typography>
                                                <Field
                                                    style={{
                                                        borderColor: '#c4c4c4',
                                                        borderRadius: '3px',
                                                        resize: 'none',
                                                    }}
                                                    className='form-control'
                                                    placeholder={i18n.t(`integrations.api.form.answerContactPlaceholder`)}
                                                    maxLength={255}
                                                    value={integration?.fields?.find(field => field.key == 'answerContactMessage')?.value || ''}
                                                    onChange={(e) => handleAnswerContactMessage(e.target.value)}
                                                    component="textarea"
                                                    resize={false}
                                                />
                                            </Grid>
                                        </Grid>

                                        {/* enviar resposta do contato */}
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} mb={2}>
                                                <Grid item style={{ display: "inline-block" }}>
                                                    <AntSwitch
                                                        checked={integration?.fields?.find(field => field.key == 'sendContactAnswer')?.value == true || false}
                                                        onChange={(e) => handleSendContactAnswer(e.target.checked ? true : false)}
                                                        name="sendContactAnswer"
                                                    />
                                                </Grid>
                                                <Grid item style={{ display: "inline-block" }}>{i18n.t(`integrations.api.form.sendContactAnswer`)}</Grid>
                                            </Grid>
                                        </Grid>

                                        {/* url para retornar a resposta do contato */}
                                        <Grid container spacing={2} hidden={integration?.fields?.find(field => field.key == 'sendContactAnswer')?.value != true}>
                                            <Grid item xs={12} mb={2}>
                                                <Typography component="label">
                                                    {i18n.t(`integrations.api.form.url`)}
                                                    <Typography component="span" className="text-danger ml-2">*</Typography>
                                                </Typography>
                                                <Field
                                                    style={{
                                                        borderColor: '#c4c4c4',
                                                        borderRadius: '3px'
                                                    }}
                                                    className='form-control'
                                                    placeholder={i18n.t(`integrations.api.form.url`)}
                                                    maxLength={255}
                                                    value={integration?.fields?.find(field => field.key == 'sendContactAnswerUrl')?.value || ''}
                                                    onChange={(e) => handleSendContactAnswerUrl(e.target.value)}
                                                />
                                            </Grid>
                                        </Grid>

                                        {/* cabeçalhos e campos extras */}
                                        <Grid container spacing={2} hidden={integration?.fields?.find(field => field.key == 'sendContactAnswer')?.value != true}>
                                            <Grid item xs={12} mb={2}>
                                                <Box
                                                    component="div"
                                                    style={{ height: "350px", overflowY: "auto" }}
                                                >
                                                    <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                                                        <Tab label={i18n.t(`integrations.api.form.headers`)} {...a11yProps(0)} />
                                                    </Tabs>
                                                    <TabPanel value={value} index={0}>
                                                        <IntegrationHeaderTab integrationData={integration} setIntegrationData={setIntegration} />
                                                    </TabPanel>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </>
                                }

                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClose} color="secondary" disabled={submittingContent} variant="outlined">
                                    {i18n.t(`integrations.api.buttons.cancel`)}
                                </Button>
                                <Button
                                    type="submit"
                                    color="primary"
                                    disabled={submittingContent}
                                    variant="contained"
                                >
                                    {i18n.t(`integrations.api.buttons.save`)}
                                    {submittingContent && <CircularProgress size={24} className={classes.buttonProgress} />}
                                </Button>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </Dialog>
        </div>
    );
};

export default ApiModal;