import React, { useState, useEffect, useReducer } from "react";

import { toast } from "react-toastify";
import openSocket from "socket.io-client";
import MainContainer from "../../components/MainContainer";
import api from "../../services/api";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import CompanyModal from "../../components/Company/CompanyModal";
import ConfirmationModal from "../../components/ConfirmationModal";
import CompanyPaymentModal from "../../components/Company/CompanyPaymentModal";
import CompanyUserModal from "../../components/Company/CompanyUserModal";
import toastError from "../../errors/toastError";
import { makeStyles } from "@material-ui/core/styles";
import { KTSVG } from '../../../_metronic/helpers'
import { Field } from "formik";
import { Switch } from "@material-ui/core";
import { format, parseISO } from "date-fns";
import {
  Select,
  FormControl,
  Grid,
  MenuItem,
} from '@material-ui/core/';
import socket from "../../hooks/useSocket";

const reducer = (state, action) => {
  if (action.type === "LOAD_COMPANIES") {
    const companys = action.payload;
    const newCompanys = [];

    companys.forEach((company) => {
      const companyIndex = state.findIndex((u) => u.id === company.id);
      if (companyIndex !== -1) {
        state[companyIndex] = company;
      } else {
        newCompanys.push(company);
      }
    });

    return [...state, ...newCompanys];
  }

  if (action.type === "UPDATE_COMPANIES") {
    const companys = action.payload;
    const companyIndex = state.findIndex((u) => u.id === companys.id);

    if (companyIndex !== -1) {
      state[companyIndex] = companys;
      return [...state];
    } else {
      return [companys, ...state];
    }
  }

  if (action.type === "DELETE_COMPANIES") {
    const companyId = action.payload;

    const companyIndex = state.findIndex((u) => u.id === companyId);
    if (companyIndex !== -1) {
      state.splice(companyIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};


const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
  modalStyle: {
    margin: '20px 10px',
    padding: '10px',
    minWidth: '310px',
    "@media (max-width: 740px)": {
      padding: '0px !important',
      marginTop: '65px'
    }
  }
}));
const CompanySignature = () => {
  const classes = useStyles();

  const [company, setCompany] = useState({ nomeEmpresa: "", cnpj: "", maxWhatsapps: "", maxCampaign: "", MaxUsers: ""});
  const [companyUserModalOpen, setCompanyUserModalOpen] = useState(false);
  const [companyPaymentModalOpen, setCompanyPaymentModalOpen] = useState(false);
  const [searchParam, setSearchParam] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [deletingCompany, setDeletingCompany] = useState(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [companys, dispatch] = useReducer(reducer, []);

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam]);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchCompany = async () => {
        try {
          const { data } = await api.get("/companySignature/", {
            params: { searchParam, pageNumber },
          });
          dispatch({ type: "LOAD_COMPANIES", payload: data });
          setHasMore(data.hasMore);
          setLoading(false);
        } catch (err) {
          console.error({err});
          toastError(err);
        }
      };
      fetchCompany();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, pageNumber]);

  useEffect(() => {
    socket.on("company", (data) => {
      if (data.action === "update" || data.action === "create") {
        dispatch({ type: "UPDATE_COMPANIES", payload: data.company });
      }

      if (data.action === "delete") {
        dispatch({ type: "DELETE_COMPANIES", payload: +data.companyId });
      }
    });

    return () => {};
  }, []);

  const filteredCompanies = companys.filter(company => {
    if (selectedStatus === "") {
      return true;
    } else {
      return company.status === (selectedStatus === "true");
    }
  });
  //fazer pesquisa 
  const handleSearch = (event) => {
    setSearchParam(event.target.value.toLowerCase());
  };
  //Abrir modal para cadastrar empresa
  const handleOpenCompanyUserModal = () => {
    setSelectedCompany(null);
    setCompanyUserModalOpen(true);
  };

    //Abrir modal para cadastrar empresa
    const handleOpenCompanyPaymentModal = () => {
      setSelectedCompany(null);
      setCompanyPaymentModalOpen(true);
    };
  

  //fechar modal empresa 
  const handleCloseCompanyUserModal = () => {
    setSelectedCompany(null);
    setCompanyUserModalOpen(false);
  };

  //fechar modal empresa 
  const handleClosePaymentCompanyModal = () => {
    setSelectedCompany(null);
    setCompanyPaymentModalOpen(false);
  };
  const loadMore = () => {
    setPageNumber((prevState) => prevState + 1);
  };
  const handleScroll = (e) => {
    if (!hasMore || loading) return;
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
    }
  };
  const handleEditCompany = (company) => {
    setSelectedCompany(company);

    setCompanyUserModalOpen(true);
  };

  const color = company.status ? 'white' : 'white';
  const backgroundColor = company.status ? '#f44336' : '#4caf50';
  const boxShadow = company.status ? '0 0 10px #f44336' : '0 0 10px #4caf50';
  const borderRadius = '5px';

  const handleDeleteCompany = async (companyId) => {
    try {
      await api.delete(`/company/${companyId}`);
      toast.success("Empresa Excluida com sucesso");
    } catch (err) {
      console.error({err});
      toastError(err);
    }
    setDeletingCompany(null);
    setSearchParam("");
    setPageNumber(1);
  };
  return (
    <MainContainer>
      <ConfirmationModal
        title={
          deletingCompany &&
          `${'Deseja realmente deletar a empresa'} ${deletingCompany.name
          }?`
        }
        open={confirmModalOpen}
        onClose={setConfirmModalOpen}
        onConfirm={() => handleDeleteCompany(deletingCompany.id)}
      >
        {"deletar empresa"}
      </ConfirmationModal>
      <CompanyUserModal
        open={companyUserModalOpen}
        onClose={handleCloseCompanyUserModal}
        aria-labelledby="form-dialog-title"
        companyId={selectedCompany && selectedCompany.id}
        />
         <CompanyPaymentModal
        open={companyPaymentModalOpen}
        onClose={handleClosePaymentCompanyModal}
        aria-labelledby="form-dialog-title"
        companyId={selectedCompany && selectedCompany.id}
        />

      {/*<MainHeader>
        <Title>Cadastrar Empresa</Title>
        <MainHeaderButtonsWrapper>
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenCompanyModal}
          >
            Adicionar Empresa
          </Button>
        </MainHeaderButtonsWrapper>
      </MainHeader>
      <Paper
        className={classes.mainPaper}
        variant="outlined"
        onScroll={handleScroll}
      >
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center">
                {'Nome Empresa'}
              </TableCell>
              <TableCell align="center">
                {"Cnpj"}
              </TableCell>
              <TableCell align="center">
                {'N° Whatsapps'}
              </TableCell>
              <TableCell align="center">
                {'N° usuarios'}
              </TableCell>
              <TableCell align="center">
                {'Ações'}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <>
              {companys.map((company) => (
                <TableRow key={company.id}>
                  <TableCell align="center">{company.name}</TableCell>
                  <TableCell align="center">{company.cnpj}</TableCell>
                  <TableCell align="center">{company.maxWhatsapps}</TableCell>
                  <TableCell align="center">{company.maxUsers}</TableCell>
                  <TableCell align="center">
                    <IconButton
                      size="small"
                      onClick={() => handleEditCompany(company)}
                    >
                      <EditIcon />
                    </IconButton>

                    <IconButton
                      size="small"
                      onClick={(e) => {
                        setConfirmModalOpen(true);
                        setDeletingCompany(company);
                      }}
                    >
                      <DeleteOutlineIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
              {loading && <TableRowSkeleton columns={5} />}
            </>
          </TableBody>
        </Table>
      </Paper>*/}
      <>
        <div class='row gy-5 gx-xl-8'
          className={classes.modalStyle}
          onScroll={handleScroll}>
          <div className='col-xl-15'>
            <div className='card card-xxl-stretch-5 mb-15 mb-xl-8'>
              {/* begin::Header */}
              <div className='card-header border-0 pt-5'>
                <h3 className='card-title align-items-start flex-column'>
                  <span className='card-label fw-bolder fs-3 mb-1'>Pacotes</span>
                  {/*<span className='text-muted mt-1 fw-bold fs-7'>Over 500 members</span>*/}
                </h3>
              </div>
              {/* end::Header */}
              {/* begin::Body */}
              <div className='card-body py-3'>
                {/* begin::Table container */}
                <div className='table-responsive'>
                  {/* begin::Table */}
                  <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                    {/* begin::Table head */}
                    <thead>
                      <tr className='fw-bolder text-muted' >
                        <th className='min-w-150px'>Razão social</th>
                        <th className='min-w-120px'>Cnpj</th>
                        <th className='min-w-120px'>Email</th>
                        <th className='min-w-100px'>N° Whatsapps</th>
                        <th className='min-w-100px'>N° usuarios</th>
                        <th className='min-w-100px'>Pacote</th>
                        <th className='min-w-100px text-end'>Ações</th>
                      </tr>
                    </thead>
                    {/* end::Table head */}
                    {/* begin::Table body */}
                    <tbody>
                      <>
                        {filteredCompanies.map((company) => (
                          <>
                            
                            <tr >
                              <td>
                                <div>
                                  <div className='d-flex justify-content-start flex-column'>
                                    <a href='#' className='text-dark fw-bolder text-hover-primary fs-6'>
                                      {company.name}
                                    </a>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <a className='text-dark fw-bolder text-hover-primary d-block fs-6'>
                                  {company.cnpj}
                                </a>
                              </td>
                              <td>
                                <a href={`mailto:${company.email}?subject=Ola%20contato!`} className='text-dark fw-bolder text-hover-primary d-block fs-6'>
                                  {company.email}
                                </a>
                              </td>
                              <td>
                                <a className='text-dark fw-bolder text-hover-primary d-block fs-6'>
                                  {company.maxWhatsapps}
                                </a>
                              </td>
                              <td>
                                <a className='text-dark fw-bolder text-hover-primary  fs-6'>
                                  {company.maxUsers}
                                </a>
                              </td>
                              <td>
                              <a className='fw-bolder text-hover-primary  fs-6'
                                  style={{ color, backgroundColor, boxShadow, borderRadius, padding:'5px' }}>
																			{(company.status) ? ((company.status), "Ativo") : "Inativo"}
                                </a>
                              </td>
                          
                              <td>
                                
                                <div className='d-flex justify-content-end flex-shrink-0'>

                                  <button

                                    title='Editar'
                                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                    onClick={() => handleEditCompany(company)}
                                  >
                                    <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                                  </button>
          
                                </div>
                              </td>
                            </tr>
                          </>
                        ))}
                        {loading && <TableRowSkeleton columns={5} />}
                      </>
                    </tbody>
                    {/* end::Table body */}
                  </table>
                  {/* end::Table */}
                </div>
                {/* end::Table container */}
              </div>
              {/* begin::Body */}
            </div>
          </div>
        </div>
      </>
    </MainContainer>

  )
}

export default CompanySignature;
