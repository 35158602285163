import { makeStyles } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import { green } from "@material-ui/core/colors";
import { CheckCircle } from "@material-ui/icons";
import SearchIcon from "@material-ui/icons/Search";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { format, parseISO } from "date-fns";
import { ptBR } from 'date-fns/locale';
import React, { useContext, useEffect, useReducer, useState } from "react";
import { toast } from "react-toastify";
import { KTSVG } from '../../../_metronic/helpers';
import CampaignModal from "../../components/CampaignModal";
import ConfirmationModal from "../../components/ConfirmationModal";
import MainContainer from "../../components/MainContainer";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import TestMessageCampaignModal from "../../components/TestMessageCampaignModal";
import { AuthContext } from "../../context/Auth/AuthContext";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import FormControl from "@material-ui/core/FormControl";
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { useHistory } from "react-router-dom";
import socket from "../../hooks/useSocket";
import JsonParse from "../../helpers/JsonParse";
import { Button } from '@material-ui/core/';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';


const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
  customTableCell: {
    display: "flex",
    alignItems: "center",
  },
  modalStyle: {
    padding: '20px 10px',
    maxheight: '95vh',
    minWidth: '310px',
    "@media (max-width: 740px)": {
      padding: '0px !important',
      marginTop: '65px'
    }
  }
}));

function ListItemLink(props) {
  const { days } = props;
  const daysOk = JsonParse(days)
  return (
    daysOk.map((dias) => (
      <div style={{
        backgroundColor: '#0B708C',
        margin: '1px',
        height: '25px',
        borderRadius: '25px'
      }}>
        <p style={{
          width: '25px',
          height: '25px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          color: '#ffffff',
          fontFamily: 'initial',
          fontSize: '10px'
        }}>
          {dias}
        </p>
      </div>
    ))
  );
}

const reducer = (state, action) => {

  if (action.type === "LOAD_QUEUES") {
    const campanhas = action.payload;
    const newQueues = [];
    campanhas.forEach((campanha) => {
      const queueIndex = state.findIndex((q) => q.id === campanha.id);
      if (queueIndex !== -1) {
        state[queueIndex] = campanha;
      } else {
        newQueues.push(campanha);
      }
    });
    return [...state, ...newQueues];
  }

  if (action.type === "UPDATE_QUEUES") {
    const campanha = action.payload;

    const queueIndex = state.findIndex((u) => u.id === campanha.id);

    if (queueIndex !== -1) {
      state[queueIndex] = campanha;
      return [...state];
    } else {
      return [campanha, ...state];
    }
  }

  if (action.type === "RESET") {
    return [];
  }
};

const Campaign = () => {
  const classes = useStyles();

  const { user } = useContext(AuthContext);
  const [campanhas, dispatch] = useReducer(reducer, []);
  const [whatsapp, setWhatsapp] = useState();
  const [loading, setLoading] = useState(false);
  const [campaignModalOpen, setCampaignModalOpen] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [selectedDuplicateCampaign, setSelectedDuplicateCampaign] = useState(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [testCampaignModalOpen, setTestCampaignModalOpen] = useState(false);
  const [resendCampaignModalOpen, setResendCampaignModalOpen] = useState(false);
  const [searchParam, setSearchParam] = useState("");
  const [getCreatedBy, setGetCreatedBy] = useState('');
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [getUsers, setGetUsers] = useState([]);
  const [selectedUserFilter, setSelectedUserFilter] = useState("");
  const [loadingSend, setLoadingSend] = useState(false);
  const history = useHistory();


  useEffect(() => {
    const fetchSession = async () => {
      try {
        const { data } = await api.get("/whatsapp/");
        setWhatsapp(JSON.stringify(data))
      } catch (err) {
        console.error({err});
        toastError(err);
      }
    };
    fetchSession();
  }, []);

  useEffect(() => {
    socket.on(`campaign-${user.adminId}`, (data) => {
      if ((data.action === "update" || data.action === "create")) {
        dispatch({ type: "UPDATE_QUEUES", payload: data.campaign });
      }
    });
    return () => {};
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      const fetchContacts = async () => {
        try {
          const { data } = await api.get("/usersList/");
          setGetUsers(data)
        } catch (err) {
          console.error({err});
          toastError(err);
        }
      };

      fetchContacts();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, []);

  const reloadPage = async () => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchContacts = async () => {
        try {
          const { data } = await api.get("/campaignSearch/", {
            params: { searchParam, pageNumber, getCreatedBy },
          });
          dispatch({ type: "LOAD_QUEUES", payload: data.contacts });
          setHasMore(data.hasMore);
          setLoading(false);
        } catch (err) {
          console.error({err});
          toastError(err);
        }
      };
      fetchContacts();
    }, 500);
    return () => clearTimeout(delayDebounceFn);

  }

  //=========== PESQUISA NA PAGINA ===========//

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam, getCreatedBy]);

  const handleChange = async (event) => {
    if (event.target.value === "") {
      setGetCreatedBy('')
    } else {
      setGetCreatedBy(event.target.value)
    }
    setSelectedUserFilter(event.target.value)
  };

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchContacts = async () => {
        try {
          const { data } = await api.get("/campaignSearch/", {
            params: { searchParam, pageNumber, getCreatedBy },
          });
          dispatch({ type: "LOAD_QUEUES", payload: data.contacts });
          setHasMore(data.hasMore);
          setLoading(false);
        } catch (err) {
          console.error({err});
          toastError(err);
        }
      };
      fetchContacts();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, pageNumber, getCreatedBy]);

  const loadMore = () => {
    setPageNumber((prevState) => prevState + 1);
  };

  const handleSearch = (event) => {
    setSearchParam(event.target.value.toLowerCase());
  };

  const handleScroll = (e) => {
    if (!hasMore || loading) return;
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
    }
  };

  //=============================================//

  const handleOpenQueueModal = () => {
    setCampaignModalOpen(true);
    setSelectedCampaign(null);
  };

  const hadleEditCampaign = (campaingId) => {
    setSelectedCampaign(campaingId);
    setCampaignModalOpen(true);
  };

  const hadleDuplicateCampaign = (campaingId) => {
    setSelectedCampaign(campaingId);
    setSelectedDuplicateCampaign(true);
    setCampaignModalOpen(true);
  };

  const handleCloseCampaignModal = async () => {
    setCampaignModalOpen(false);
    setSelectedCampaign(null);
    setSelectedDuplicateCampaign(null);
    setResendCampaignModalOpen(false);
    await reloadPage();
  };

  const handleCloseConfirmationModal = () => {
    setConfirmModalOpen(false);
    setSelectedCampaign(null);
  };

  const handleCloseTestCampaignModal = () => {
    setTestCampaignModalOpen(false);
    setSelectedCampaign(null);
  };

  const handleDeleteCampaign = async (id) => {
    setLoading(true);
    try {
      await api.delete(`/campaign/${id}`);
      const deleteColuna = document.getElementById(`${id}`);
      deleteColuna.remove();
      setTimeout(async () => {
        await reloadPage();
        toast.success(i18n.t("Campanha deletada com sucesso!"));
      }, 1000);
    } catch (err) {
      console.error({err});
      toastError(err);
      setLoading(false);
    }
    setSelectedCampaign(null);
  };

  const handleStatusCampaign = async (id, status) => {
    setLoading(true);
    try {
      var value = { statusCampaign: status }
      await api.put(`/campaign/status/${id}`, value);
      await reloadPage();
      if (value.statusCampaign === 'paused') { toast.success(i18n.t(`A campanha foi pausada.`)) }
      if (value.statusCampaign === 'running') { toast.success(i18n.t(`A campanha foi reiniciada!`)) }
    } catch (err) {
      console.error({err});
      toastError(err);
      setLoading(false);
    }
  };

  function addHours(numOfHours, date = new Date()) {
    date.setTime(date.getTime() + numOfHours * 60 * 60 * 1000);
    return date.toISOString().replace(/\.\d{3}Z$/, '');
  }

  function renderArchiveInfo() {
    return <>
      <div title='Esta campanha tem um arquivo' style={{ display: 'flex', marginTop: '10px' }}>
        Arquivo
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark-zip" viewBox="0 0 16 16">
          <path d="M5 7.5a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v.938l.4 1.599a1 1 0 0 1-.416 1.074l-.93.62a1 1 0 0 1-1.11 0l-.929-.62a1 1 0 0 1-.415-1.074L5 8.438V7.5zm2 0H6v.938a1 1 0 0 1-.03.243l-.4 1.598.93.62.929-.62-.4-1.598A1 1 0 0 1 7 8.438V7.5z" />
          <path d="M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5L14 4.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1h-2v1h-1v1h1v1h-1v1h1v1H6V5H5V4h1V3H5V2h1V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5h-2z" />
        </svg>
      </div>
    </>
  }

  function setData(data) {
    history.push(`/relatory/${data}`);
  }

  const handleSendMessageCampaign = async (campaignId, number, messageKeys, file) => {

    const fetchSession = async () => {
      setLoadingSend(true);
      try {
        const formData = new FormData();
        formData.append("campaignId", campaignId);
        formData.append("number", number);
        formData.append("messageKeys", JSON.stringify(messageKeys));
        if (file) formData.append("medias", file);

        await api.post("/campaignTest", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        handleCloseTestCampaignModal();
      } catch (err) {
        console.error({err});
        toastError(err);
      } finally {
        setLoadingSend(false);
        handleCloseTestCampaignModal();
      }
    };
    fetchSession();
  }

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }));


  return (
    <MainContainer>
      <ConfirmationModal
        title={
          selectedCampaign &&
          `${i18n.t("queues.confirmationModal.deleteTitle")} ${selectedCampaign.name}?`
        }
        open={confirmModalOpen}
        onClose={handleCloseConfirmationModal}
        onConfirm={() => handleDeleteCampaign(selectedCampaign.id)}
      >
        {i18n.t("queues.confirmationModal.deleteMessage")}
      </ConfirmationModal>
      <TestMessageCampaignModal
        title={
          selectedCampaign &&
          `Enviando teste da campanha ${selectedCampaign.name}`
        }
        loading={loadingSend}
        open={testCampaignModalOpen}
        onClose={handleCloseTestCampaignModal}
        campaignId={selectedCampaign?.id}
        onConfirm={(campaignId, number, messageKeys, file) => handleSendMessageCampaign(campaignId, number, messageKeys, file)}
      >
      </TestMessageCampaignModal>
      <CampaignModal
        open={campaignModalOpen}
        onClose={handleCloseCampaignModal}
        campaignId={selectedCampaign}
        duplicateCampaign={selectedDuplicateCampaign}
        whatsappQuery={whatsapp}
        resendCampaign={resendCampaignModalOpen}
      />
      <div class='row gy-5 gx-xl-8'
        className={classes.modalStyle}
        id="box"
        onScroll={handleScroll}
      >
        <div className='col-xl-15'>
          <div className='card card-xxl-stretch-5 mb-15 mb-xl-8'>
            {/* begin::Header */}
            <div className='card-header border-0 pt-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder fs-3 mb-1'>Campanhas</span>
                {/*<span className='text-muted mt-1 fw-bold fs-7'>Over 500 members</span>*/}
              </h3>
              <div
                className='card-toolbar '
                data-bs-toggle='tooltip'
                data-bs-placement='top'
                data-bs-trigger='hover'
              >
                <FormControl variant="outlined" hidden={user.profile != 'admin'}>
                  <Select
                    value={selectedUserFilter}
                    displayEmpty
                    style={{
                      padding: '1px 0px 4px',
                      margin: '4px 10px -2px 0px',
                      height: '30px',
                      width: '173px',
                      fontSize: '15px'
                    }}
                    inputProps={{
                      style: {
                        padding: 5
                      }
                    }}
                    onChange={e => handleChange(e)}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}

                  >
                    <MenuItem value="">
                      <em>Todos os usuários</em>
                    </MenuItem>
                    {getUsers.map((user, key) => (
                      <MenuItem dense key={key} value={user.id}>
                        <ListItemText primary={user.name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TextField
                  placeholder={i18n.t("contacts.filters.searchPlaceholder")}
                  type="search"
                  value={searchParam}
                  style={{ marginRight: '10px' }}
                  onChange={handleSearch}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon style={{ color: "gray" }} />
                      </InputAdornment>
                    ),
                  }}
                />
                <Button
                  style={{
                    marginLeft: "1.5px",
                  }}
                  variant="contained"
                  color="primary"
                  onClick={handleOpenQueueModal}
                >
                  <KTSVG path='media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
                  Adicionar Campanha
                </Button>
              </div>
            </div>
            {/* end::Header */}
            {/* begin::Body */}
            <div className='card-body py-3' id="body_component">
              {/* begin::Table container */}
              <div className='table-responsive'>
                {/* begin::Table */}
                <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                  {/* begin::Table head */}
                  <thead>
                    <tr className='fw-bolder text-muted' >
                      <th className='min-w-100px'>Nome</th>
                      <th className='min-w-50px'>Início</th>
                      <th className='min-w-50px'>Término</th>
                      <th className='min-w-50px'>Dias da semana</th>
                      <th className='min-w-50px'>Feriados</th>
                      <th className='min-w-50px'>Conexão</th>
                      <th className='min-w-50px'>Usuário criador</th>
                      <th className='min-w-50px'>Mensagem</th>
                      <th className='min-w-100px text-end'>Ações</th>
                    </tr>
                  </thead>
                  {/* end::Table head */}
                  {/* begin::Table body */}
                  <tbody>

                    <>
                      {campanhas.map((campanha) =>
                        <>
                          <tr id={campanha.id}>
                            <td>
                              <div>
                                <div className='d-flex justify-content-start flex-column'>
                                  <a href='#' className='text-dark fw-bolder text-hover-primary fs-6'>
                                    {campanha.name}
                                  </a>
                                </div>
                              </div>
                            </td>
                            <td>
                              <a className='text-dark fw-bolder text-hover-primary d-block fs-6'>
                                {format(parseISO(addHours(-3, new Date(campanha.begin)).toLocaleString("pt-BR", { timeZone: "America/Sao_Paulo" }).slice(0, 16).replace("T", " ")), "dd/MM/yyyy HH:mm", { locale: ptBR })}
                              </a>
                            </td>
                            <td>
                              <a className='text-dark fw-bolder text-hover-primary d-block fs-6'>
                                {format(parseISO(addHours(-3, new Date(campanha.end)).toLocaleString("pt-BR", { timeZone: "America/Sao_Paulo" }).slice(0, 16).replace("T", " ")), "dd/MM/yyyy HH:mm", { locale: ptBR })}
                              </a>
                            </td>
                            <td>
                              <a className='text-dark fw-bolder text-hover-primary d-block fs-6'>
                                <div id='weekdays' style={{ display: 'flex' }}>
                                  <>
                                    <ListItemLink
                                      days={campanha.days}
                                    />
                                  </>
                                </div>
                              </a>
                            </td>
                            <td>
                              <a className='text-dark fw-bolder text-hover-primary d-block fs-6'>
                                {campanha.holiday && (
                                  <div className={classes.customTableCell}>
                                    <CheckCircle style={{ color: '#0B708C' }} />
                                  </div>
                                )}
                              </a>
                            </td>
                            <td>
                              <div>
                                <div className='d-flex justify-content-start flex-column'>
                                  <a href='#' className='text-dark fw-bolder text-hover-primary fs-6'>
                                    {campanha.connection}
                                  </a>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div>
                                <div className='d-flex justify-content-start flex-column'>
                                  <a href='#' className='text-dark fw-bolder text-hover-primary fs-6'>
                                    {campanha.userName}
                                  </a>
                                </div>
                              </div>
                            </td>
                            <style>
                              {`#breakText {
                                overflow: hidden;
                                width: 200px;
                                display: -webkit-box !important;
                                -webkit-line-clamp: 2;
                                -webkit-box-orient: vertical;
                              }`
                              }
                            </style>
                            <td>

                              <HtmlTooltip
                                title={
                                  <React.Fragment>
                                    <p style={{ fontSize: 12 }}>
                                      {campanha.message == 'undefinedForUser' //Se o status for started, mostre os botões de controle de campanha
                                        ? renderArchiveInfo()
                                        : campanha.message && campanha.media ? <>
                                          {campanha.message} + {renderArchiveInfo()}
                                        </>
                                          : campanha.message
                                      }
                                    </p>
                                  </React.Fragment>
                                }
                              >
                                <a id="breakText" className='breakText text-dark fw-bolder text-hover-primary d-block fs-6'>
                                  {campanha.message == 'undefinedForUser' //Se o status for started, mostre os botões de controle de campanha
                                    ? renderArchiveInfo()
                                    : campanha.message && campanha.media ? <>
                                      {campanha.message} + {renderArchiveInfo()}
                                    </>
                                      : campanha.message
                                  }</a>
                              </HtmlTooltip>
                            </td>
                            <td>
                              <div className='d-flex justify-content-end flex-shrink-0'>
                                {/* {campanha.status != 'finished' &&
                                  <>
                                    <button
                                      //onClick={() => { setData(campanha.id); }}
                                      onClick={() => hadleEditCampaign(campanha.id)}
                                      title='Editar Campanha'
                                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                    >
                                      <KTSVG path='/media/icons/duotune/general/gen055.svg' className='svg-icon-3' />
                                    </button>
                                  </>
                                } */}

                                <button                                  
                                  onClick={() => hadleDuplicateCampaign(campanha.id)}
                                  title='Duplicar Campanha'
                                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                >
                                  <KTSVG path='/media/icons/duotune/general/gen054.svg' className='svg-icon-3' />
                                </button>
                                                          
                                {campanha.status == 'waiting' ?
                                  ''
                                  :

                                  <button onClick={() => { setData(campanha.id) }}
                                    title='Relatório'
                                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>

                                    <KTSVG path='/media/icons/duotune/general/gen032.svg' className='svg-icon-3' />
                                  </button>
                                }
                                {campanha.status !== 'finished' && <>
                                  <button
                                    title='Enviar mensagem de teste'
                                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                    onClick={() => {
                                      setTestCampaignModalOpen(true);
                                      setSelectedCampaign(campanha);
                                    }}
                                  >
                                    <KTSVG path='/media/icons/duotune/communication/com012.svg' className='svg-icon-3' />
                                  </button>
                                </>}
                                {campanha.status == 'started' //Se o status for started, mostre os botões de controle de campanha
                                  ?
                                  <>
                                    {campanha.campaignStatus == 'running' ? //Se o status foi running, mostre o botão de pausa 
                                      <button
                                        title='Pausar Campanha'
                                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                        onClick={() => handleStatusCampaign(campanha.id, 'paused')}
                                      >
                                        <KTSVG path='/media/icons/duotune/general/gen009.svg' className='svg-icon-3' />
                                      </button>
                                      : campanha.campaignStatus == 'paused' ? //Se o status foi paused, mostre o botão de retorno 
                                        <button
                                          title='Voltar Campanha'
                                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                          onClick={() => handleStatusCampaign(campanha.id, 'running')}
                                        >
                                          <KTSVG path='/media/icons/duotune/arrows/arr027.svg' className='svg-icon-3' />
                                        </button>
                                        : '' //Se o status for nenhum dos dois, faça nada
                                    }
                                  </>
                                  : campanha.status == 'waiting' ? //Se o status for waiting, mostre os clock com o titulo de ainda nao iniciada

                                    <button
                                      title='A campanha ainda não chegou a sua data de início.'
                                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                    >
                                      <KTSVG path='/media/icons/duotune/general/gen014.svg' className='svg-icon-3' />
                                    </button>
                                    : //Se o status for nenhum dos dois, mostre o botão de indicativo de finalização de campanha.
                                    <button
                                      title='A campanha foi finalizada!'
                                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                    >
                                      <KTSVG path='/media/icons/duotune/arrows/arr086.svg' className='svg-icon-3' />
                                    </button>
                                }
                                {/* {
                                  campanha.status == 'finished' &&
                                  <>
                                    <button
                                      title='Reenviar'
                                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                      onClick={() => {
                                        setCampaignModalOpen(true);
                                        setSelectedCampaign(campanha);
                                        setResendCampaignModalOpen(true);
                                      }}
                                    >
                                      <KTSVG path='/media/icons/duotune/general/gen016.svg' className='svg-icon-3' />
                                    </button>
                                  </>  
                                } */}
                                <button
                                  title='Excluir'
                                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                  onClick={() => {
                                    setConfirmModalOpen(true);
                                    setSelectedCampaign(campanha);
                                  }}
                                >
                                  <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
                                </button>
                              </div>
                            </td>
                          </tr>
                        </>
                      )}
                    </>
                    {loading && <TableRowSkeleton columns={8} />}
                  </tbody>
                  {/* end::Table body */}
                </table>
                {/* end::Table */}
              </div>
              {/* end::Table container */}
            </div>
            {/* begin::Body */}
          </div>
        </div>
      </div>
    </MainContainer >
  );
};

export default Campaign;
