import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory, useParams } from "react-router-dom";
import Hidden from "@material-ui/core/Hidden";
import Broadcast from "../../components/Broadcast";
import Ticket from "../../components/Ticket";
import Schedule from "../../components/Schedule";
import TicketsManager from "../../components/TicketsManager";
import { i18n } from "../../translate/i18n";
import { useContext } from "react";
import { AuthContext } from "../../context/Auth/AuthContext";
import Logo from "../../assets/logo_login.png";
import LogoNatalino from "../../assets/logo_natalino.png";
const useStyles = makeStyles((theme) => ({
  chatContainer: {
    flex: 1,
    height: `100%`,
    overflowY: "hidden",
  },

  chatPapper: {
    // backgroundColor: "red",
    display: "flex",
    height: "100%",
  },

  contactsWrapper: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
    overflowY: "hidden",
    width: 'calc(1vh - 5px)'
  },
  contactsWrapperSmall: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
    overflowY: "hidden",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  messagessWrapper: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
  },
  welcomeMsg: {
    paddingTop: "22%",
    backgroundColor: "#eee",
    justifyContent: "space-evenly",
    alignItems: "center",
    height: "100%",
    textAlign: "center",
    borderRadius: 0,
  },
  ticketsManager: {},
  ticketsManagerClosed: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  contentMarginTop: {
    marginTop: '5px'
  }
}));

document.onkeydown = function (e) {
  if (e.key === 'Escape') {
    const btnClose = document.querySelector('#btnCloseTicketOnHeader');
    if (btnClose) btnClose.click();
  }
}

const Chat = () => {
  const classes = useStyles();
  const { ticketId, broadcastId, contactId } = useParams();
  //trocar getParams
  const history = useHistory();
  const { user } = useContext(AuthContext);
  const ticketIdUrl = +history.location.pathname.split("/")[2];

  const RenderPage = ({ ticketId, broadcastId, contactId }) => {
    if (ticketId) {
      return <Ticket />
    }
    if (broadcastId) {
      return <Broadcast />
    }
    if (contactId) {
      return <Schedule />
    }
  };

  return (
    <div className={classes.chatContainer}>
      <div className={classes.chatPapper}>
        <Grid container spacing={0} id="ticketPage">
          <Grid
            item
            xs={12}
            md={4}
            className={
              ticketId ? classes.contactsWrapperSmall : classes.contactsWrapper
            }
          >
            <TicketsManager />
          </Grid>
          <Grid item xs={12} md={8} className={classes.messagessWrapper}>
            {(ticketId || broadcastId || contactId) ? (
              <RenderPage
                ticketId={ticketId}
                broadcastId={broadcastId}
                contactId={contactId}
              />
            ) : (
              <Hidden only={["sm", "xs"]}>
                <Paper className={classes.welcomeMsg}>
                    <img
                      src={LogoNatalino}
                      style={{
                        height: "180px",
                        width: "auto",
                        margin: "0 auto 10px auto",
                        display: "block"
                      }}
                    />
                  <b>{i18n.t("chat.noTicketMessage")}</b>
                </Paper>
              </Hidden>
            )}
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Chat;