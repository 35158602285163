import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Avatar, Card, CardHeader, TextField, Box, Button, FormControl, Select, MenuItem } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	ticketHeader: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		backgroundColor: "#f5f5f5",
		borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
		padding: theme.spacing(1.5),
	},
	searchBox: {
		display: "flex",
		alignItems: "center",
		justifyContent: 'flex-end',
		width: "60%",
		gap: theme.spacing(2),
		[theme.breakpoints.down('sm')]: {
			flexDirection: "column",
			alignItems: "stretch",
		},
	},
	formControl: {
		width: "150px"
	},
	textField: {
		width: "40%",
		[theme.breakpoints.down('sm')]: {
			width: "100%",
		},
	},
	newScheduleButton: {
		width: "30%",
		textTransform: "none",
		padding: '3px 0px 10px 0px',
		[theme.breakpoints.down('sm')]: {
			width: "100%",
		},
	},
}));

const ScheduleHeader = ({ contact, total, searchTerm, state, setState, handleSearchChange, openNewSchedule }) => {
	const classes = useStyles();

	const handleChange = (event) => {
		setState({
			...state,
			status: event.target.value,
		});
	};

	return (
		<Card square className={classes.ticketHeader}>
			<CardHeader
				avatar={<Avatar alt="contact_image" src={contact?.profilePicUrl} />}
				title={contact?.name}
				subheader={`${total} Agendamento(s)`}
				style={{ padding: 0 }}
				titleTypographyProps={{ noWrap: true, variant: "h6" }}
				subheaderTypographyProps={{ noWrap: true, variant: "body2", color: "textSecondary" }}
			/>
			<Box className={classes.searchBox}>
				<Box className={classes.textField}>
					<TextField
						placeholder="Buscar agendamento"
						variant="outlined"
						size="small"
						value={searchTerm}
						onChange={handleSearchChange}
						fullWidth
					/>
				</Box>
				<Box className={classes.formControl}>
					<FormControl variant="outlined" fullWidth size="small">
						<Select
							value={state.status}
							onChange={handleChange}
						>
							<MenuItem value={'all'}>Todos</MenuItem>
							<MenuItem value={'await'}>Aguardando</MenuItem>
							<MenuItem value={'started'}>Iniciados</MenuItem>
							<MenuItem value={'finished'}>Finalizados</MenuItem>
							<MenuItem value={'error'}>Com erros</MenuItem>
						</Select>
					</FormControl>
				</Box>
				<Box className={classes.newScheduleButton}>
					<Button
						variant="contained"
						color="primary"
						fullWidth
						onClick={openNewSchedule}
					>
						Novo agendamento
					</Button>
				</Box>
			</Box>
		</Card>
	);
};

export default ScheduleHeader;
