import React, { useState, useEffect, useRef, useContext } from "react";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";

import {
  makeStyles,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";
import Switch from '@material-ui/core/Switch';
import imageSvg from "../../assets/svg/image.svg";
import csvSvg from "../../assets/svg/csv.svg";
import xlsxSvg from "../../assets/svg/xlsx.svg";
import pdfSvg from "../../assets/svg/pdf.svg";
import musicSvg from "../../assets/svg/music.svg";
import { Can } from "../Can";
const useStyles = makeStyles((theme) => ({
  root: {
    flexWrap: "wrap",
    maxWidth: '500px'
  },
  textField: {
    marginRight: theme.spacing(1),
    width: "100%",
  },

  btnWrapper: {
    position: "relative",
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  textQuickAnswerContainer: {
    width: "100%",
  },
}));

const QuickAnswerSchema = Yup.object().shape({
  shortcut: Yup.string()
    .min(2, "Muito Curto(a)!")
    .max(15, "Muito Longo(a)!")
    .required("Obrigatório"),
  message: Yup.string()
    .min(8, "Muito Curto(a)!")
    .max(30000, "Muito Longo(a)!")
    .required("Obrigatório"),
});

const QuickAnswersModal = ({
  open,
  onClose,
  quickAnswerId,
  initialValues,
  onSave,
}) => {
  const classes = useStyles();
  const isMounted = useRef(true);
  const { user } = useContext(AuthContext);
  const { user: loggedInUser } = useContext(AuthContext);
  const [submittingContent, setSubmittingContent] = useState(false);

  const initialState = {
    adminId: loggedInUser.adminId,
    createdBy: "",
    shortcut: "",
    message: "",
    media: "",
    forAll: false
  };

  const [quickAnswer, setQuickAnswer] = useState(initialState);

  function typeExtencion(archive) {
    var type = ""
    switch (archive) {
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        type = ".xlsx"
        break;
      case 'text/csv':
        type = ".csv"
        break;
      case 'application/pdf':
        type = ".pdf"
        break;
      case "application/msword":
        type = ".doc"
        break;
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        type = ".doc"
        break;
      case "application/vnd.ms-excel":
        type = ".xls"
        break;
      case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
        type = ".pptx"
        break;
      case "application/msword":
        type = ".doc"
        break;
    }
    return type
  }
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    const fetchQuickAnswer = async () => {
      if (initialValues) {
        setQuickAnswer((prevState) => {
          return { ...prevState, ...initialValues };
        });
      }

      if (!quickAnswerId) return;

      try {
        const { data } = await api.get(`/quickAnswers/${quickAnswerId}`);
        if (isMounted.current) {
          const array = {
            'adminId': data.adminId,
            'shortcut': data.shortcut,
            'message': data.message,
            'forAll': data.forAll,
            'media': data.media
          }
          if (data.media) {
            var typeArchive = String((data.media).split('/')[0].split(':')[1]);
            var toDownload = String((data.media).split(')')[1]);
            var ext = String((data.media).split(';base64,')[0].split(')data:')[1]);
            var hrefLink = document.querySelector("#linkArchive");
            var mountImg = document.querySelector("#typeArchive");
            switch (typeArchive) {
              case "audio":
                mountImg.src = musicSvg
                mountImg.title = "Esta mensagem tem um audio (clique para baixar)"
                hrefLink.href = String(toDownload);
                hrefLink.download = 'arquivo.mp3';
                break;
              case "image":
                mountImg.src = imageSvg
                mountImg.title = "Esta mensagem tem um Imagem (clique para baixar)"
                hrefLink.href = String(toDownload);
                hrefLink.download = 'arquivo.png';
                break;
              case "application":
                mountImg.src = "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d5/TK_archive_icon.svg/100px-TK_archive_icon.svg.png"
                mountImg.title = "Esta mensagem tem um Arquivo (clique para baixar)"
                hrefLink.href = String(toDownload);
                hrefLink.download = `arquivo${typeExtencion(ext)}`;
                break;
            }
          }
          setQuickAnswer(array);
        }
      } catch (err) {
        console.error({err});
        toastError(err);
      }
    };

    fetchQuickAnswer();
  }, [quickAnswerId, open, initialValues]);

  const handleClose = () => {
    onClose();
    setQuickAnswer(initialState);
  };

  const handleSaveQuickAnswer = async (values) => {
    try {
      if (quickAnswerId) {
        await api.put(`/quickAnswers/${quickAnswerId}`, values);
        handleClose();
      } else {
        const { data } = await api.post("/quickAnswers", values);
        if (onSave) {
          onSave(data);
        }
        handleClose();
      }
      toast.success(i18n.t("quickAnswersModal.success"));
      setSubmittingContent(false);
    } catch (err) {
      console.error({err});
      err.response.data.error = i18n.t(err.response.data.error);
      toastError(err);
      setSubmittingContent(false);
    }
  };


  var mediaBASE64 = "";
  const uploadFile = () => {
    mediaBASE64 = "";
    const imgUploadedUser = document.querySelector("#typeArchive")
    const file = document.querySelector('#uploadArquivoMensagem').files[0];
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      mediaBASE64 = `name(${file.name})` + reader.result;
      var typeArchive = String((reader.result).split('/')[0].split(':')[1]);
      var extFile = file.name.split('.').pop();
      if (typeArchive === 'image') {
        imgUploadedUser.style.display = "";
        imgUploadedUser.src = reader.result;
        imgUploadedUser.title = "Imagem enviada pelo usuário";
      } else if (typeArchive === 'audio') {
        imgUploadedUser.style.display = "";
        imgUploadedUser.src = musicSvg;
        imgUploadedUser.title = "Audio enviado pelo usuário";
      } else {
        if (extFile === 'pdf') {
          imgUploadedUser.style.display = "";
          imgUploadedUser.src = pdfSvg;
          imgUploadedUser.title = "Arquivo enviado pelo usuário";
        } else if (extFile === 'xlsx') {
          imgUploadedUser.style.display = "";
          imgUploadedUser.src = xlsxSvg;
          imgUploadedUser.title = "Arquivo enviado pelo usuário";
        } else if (extFile === 'csv') {
          imgUploadedUser.style.display = "";
          imgUploadedUser.src = csvSvg;
          imgUploadedUser.title = "Arquivo enviado pelo usuário";
        }
      }
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }

  useEffect(() => {
    const fetchQuickAnswer = async () => {
      if (initialValues) {
        setQuickAnswer((prevState) => {
          return { ...prevState, ...initialValues };
        });
      }

      if (!quickAnswerId) return;
      try {
        const { data } = await api.get(`/quickAnswers/${quickAnswerId}`);
        if (isMounted.current) {
          const array = {
            'adminId': data.adminId,
            'shortcut': data.shortcut,
            'message': data.message,
            'media': data.media,
            'forAll': data.forAll
          }
          if (data.media) {
            var typeArchive = String((data.media).split('/')[0].split(':')[1]);
            var toDownload = String((data.media).split(')')[1]);
            var ext = String((data.media).split(';base64,')[0].split(')data:')[1]);
            var hrefLink = document.querySelector("#linkArchive");
            var mountImg = document.querySelector("#typeArchive");
            switch (typeArchive) {
              case "audio":
                mountImg.src = musicSvg
                mountImg.title = "Esta mensagem tem um audio (clique para baixar)"
                hrefLink.href = String(toDownload);
                hrefLink.download = 'arquivo.mp3';
                break;
              case "image":
                mountImg.src = imageSvg
                mountImg.title = "Esta mensagem tem um Imagem (clique para baixar)"
                hrefLink.href = String(toDownload);
                hrefLink.download = 'arquivo.png';
                break;
              case "application":
                mountImg.src = "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d5/TK_archive_icon.svg/100px-TK_archive_icon.svg.png"
                mountImg.title = "Esta mensagem tem um Arquivo (clique para baixar)"
                hrefLink.href = String(toDownload);
                hrefLink.download = `arquivo${typeExtencion(ext)}`;
                break;
            }
            mediaBASE64 = data.media
          }
          setQuickAnswer(array);
        }
      } catch (err) {
        console.error({err});
        toastError(err);
      }
    };

    fetchQuickAnswer();
  }, [quickAnswerId, open, initialValues]);


  return (
    <div className={classes.root}>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
        scroll="paper"
      >
        <DialogTitle id="form-dialog-title">
          {quickAnswerId
            ? `${i18n.t("quickAnswersModal.title.edit")}`
            : `${i18n.t("quickAnswersModal.title.add")}`}
        </DialogTitle>
        <Formik
          initialValues={quickAnswer}
          enableReinitialize={true}
          onSubmit={(values, actions) => {
            setSubmittingContent(true);
            var defineMedia = ''
            if (quickAnswerId) {
              if (mediaBASE64.length < 1) { defineMedia = values.media }
              else { defineMedia = mediaBASE64 }
            }
            else { defineMedia = mediaBASE64 }
            setTimeout(() => {
              const array = {
                'adminId': values.adminId,
                'createdBy': user.id,
                'shortcut': values.shortcut,
                'message': values.message,
                'media': defineMedia,
                'forAll': values.forAll
              }
              handleSaveQuickAnswer(array);
            }, 400);
          }}
        >
          {({ values, errors, touched }) => (
            <Form style={{ padding: "10px" }}>
              <div className="form-row">
                <Field
                  as={TextField}
                  type="hidden"
                  name="adminId"
                />
                <div class="form-group col-md-10 pl-3">
                  <label>{i18n.t("quickAnswersModal.form.shortcut")} <span className="text-danger ml-2">*</span></label>
                  <Field
                    style={{
                      borderColor: '#c4c4c4',
                      borderRadius: '3px'
                    }}
                    className='form-control'
                    placeholder={i18n.t("quickAnswersModal.form.shortcut")}
                    name="shortcut"
                    autoFocus
                    error={touched.shortcut && Boolean(errors.shortcut)}
                    helperText={touched.shortcut && errors.shortcut}
                  />
                </div>
                <div class="form-group col-md-2 pr-3" style={{ display: 'flex' }}>
                  <input
                    accept=".csv, .xlsx, .png, .jpeg, .mp3, .ogg, .pdf"
                    style={{ display: 'none' }}
                    id="uploadArquivoMensagem"
                    multiple
                    onChange={(e) => { uploadFile(e) }}
                    type="file"
                    name="arquivo"
                  />
                  <label htmlFor="uploadArquivoMensagem">
                    <Button variant="contained" component="span" color="primary" style={{ padding: '12px', marginLeft: '-3px', bottom: '0', position: 'absolute' }}>
                      <p style={{ margin: '0px 5px -2px 0px' }}>{i18n.t("quickAnswers.buttons.file")} </p>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-cloud-arrow-up" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M7.646 5.146a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 6.707V10.5a.5.5 0 0 1-1 0V6.707L6.354 7.854a.5.5 0 1 1-.708-.708l2-2z" />
                        <path d="M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383zm.653.757c-.757.653-1.153 1.44-1.153 2.056v.448l-.445.049C2.064 6.805 1 7.952 1 9.318 1 10.785 2.23 12 3.781 12h8.906C13.98 12 15 10.988 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3a4.53 4.53 0 0 0-2.941 1.1z" />
                      </svg>
                    </Button>
                  </label>
                </div>
                <div class="form-group col-md-10 pl-3">
                  <label>{i18n.t("quickAnswersModal.form.message")} <span className="text-danger ml-2">*</span></label>
                  <Field
                    as="textarea"
                    style={{
                      borderColor: '#c4c4c4',
                      borderRadius: '3px'
                    }}
                    rows='2'
                    className='form-control '
                    placeholder={i18n.t("quickAnswersModal.form.message")}
                    name="message"
                  />
                </div>
                <div class="form-group col-md-2">
                  <a id="linkArchive" href="#">
                    <img id="typeArchive" style={{ width: '65px' }} />
                  </a>
                </div>
              </div>
              <DialogActions>
                <Can
                  role={user.profile}
                  perform="drawer-admin-items:view"
                  yes={() => (
                    <div style={{
                      display: 'block',
                    }}>
                      <label >{i18n.t("quickAnswers.buttons.allUsers")}</label>
                      <Field
                        as={Switch}
                        color="primary"
                        name="forAll"
                        checked={values.forAll}
                      />
                    </div>
                  )} />

                <Button
                  onClick={handleClose}
                  color="secondary"
                  disabled={submittingContent}
                  variant="outlined"
                >
                  {i18n.t("quickAnswersModal.buttons.cancel")}
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  disabled={submittingContent}
                  variant="contained"
                  className={classes.btnWrapper}
                >
                  {quickAnswerId
                    ? `${i18n.t("quickAnswersModal.buttons.okEdit")}`
                    : `${i18n.t("quickAnswersModal.buttons.okAdd")}`}
                  {submittingContent && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div >
  );
};

export default QuickAnswersModal;
