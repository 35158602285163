import { Divider, makeStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { green } from "@material-ui/core/colors";
import { AccessTime, Block, Done, DoneAll, GetApp } from "@material-ui/icons";
import { Avatar, Box, List, ListItem, ListItemAvatar, ListItemText, Popover, Typography, Tooltip } from "@mui/material";
import clsx from "clsx";
import { format, parseISO } from "date-fns";
import React, { useEffect, useState } from "react";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import MarkdownWrapper from "../MarkdownWrapper";
import ModalImageCors from "../ModalImageCors";
import "./style.css";
import JsonParse from "../../helpers/JsonParse";
import classNames from "classnames";
import EditIcon from '@material-ui/icons/Edit';
import ErrorIcon from '@material-ui/icons/Error';
import { withStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	messagesListWrapper: {
		overflow: "hidden",
		position: "relative",
		display: "flex",
		flexDirection: "column",
		flexGrow: 1,
	},

	messagesList: {
		backgroundColor: "#efeae2",
		display: "flex",
		flexDirection: "column",
		flexGrow: 1,
		padding: "20px 20px 20px 20px",
		overflowY: "scroll",
		[theme.breakpoints.down("sm")]: {
			paddingBottom: "90px",
		},
		...theme.scrollbarStyles,
	},

	circleLoading: {
		color: green[500],
		position: "absolute",
		opacity: "70%",
		top: 0,
		left: "50%",
		marginTop: 12,
	},

	messageLeft: {
		marginRight: 20,
		marginTop: 10,
		minWidth: 100,
		maxWidth: 310,
		height: "auto",
		display: "block",
		position: "relative",
		"&:hover #messageActionsButton": {
			display: "flex",
			position: "absolute",
			top: 0,
			right: 0,
		},

		whiteSpace: "pre-wrap",
		backgroundColor: "#ffffff",
		color: "#303030",
		alignSelf: "flex-start",
		borderTopLeftRadius: 0,
		borderTopRightRadius: 8,
		borderBottomLeftRadius: 8,
		borderBottomRightRadius: 8,
		paddingLeft: 5,
		paddingRight: 5,
		paddingTop: 5,
		paddingBottom: 0,
		boxShadow: "0 1px 1px #b3b3b3",
	},

	quotedContainerLeft: {
		margin: "-3px -80px 6px -6px",
		overflow: "hidden",
		backgroundColor: "#f0f0f0",
		borderRadius: "7.5px",
		display: "flex",
		position: "relative",
	},

	quotedMsg: {
		padding: 10,
		maxWidth: 300,
		height: "auto",
		display: "block",
		whiteSpace: "pre-wrap",
		overflow: "hidden",
	},

	quotedSideColorLeft: {
		flex: "none",
		width: "4px",
		backgroundColor: "#6bcbef",
	},

	messageRight: {
		marginLeft: 200,
		marginTop: 10,
		minWidth: 100,
		maxWidth: 310,
		height: "auto",
		display: "block",
		position: "relative",
		"&:hover #messageActionsButton": {
			display: "flex",
			position: "absolute",
			top: 0,
			right: 0,
		},

		whiteSpace: "pre-wrap",
		backgroundColor: "#dcf8c6",
		color: "#303030",
		alignSelf: "flex-end",
		borderTopLeftRadius: 8,
		borderTopRightRadius: 8,
		borderBottomLeftRadius: 8,
		borderBottomRightRadius: 0,
		paddingLeft: 5,
		paddingRight: 5,
		paddingTop: 5,
		paddingBottom: 0,
		boxShadow: "0 1px 1px #b3b3b3",
	},

	quotedContainerRight: {
		margin: "-3px -80px 6px -6px",
		overflowY: "hidden",
		backgroundColor: "#cfe9ba",
		borderRadius: "7.5px",
		display: "flex",
		position: "relative",
	},

	quotedMsgRight: {
		padding: 10,
		maxWidth: 300,
		height: "auto",
		whiteSpace: "pre-wrap",
	},

	quotedSideColorRight: {
		flex: "none",
		width: "4px",
		backgroundColor: "#35cd96",
	},

	messageActionsButton: {
		display: "none",
		position: "relative",
		color: "#999",
		zIndex: 1,
		backgroundColor: "inherit",
		opacity: "90%",
		"&:hover, &.Mui-focusVisible": { backgroundColor: "inherit" },
	},

	messageContactName: {
		display: "flex",
		color: "#6bcbef",
		fontWeight: 500,
	},

	textContentItem: {
		overflowWrap: "break-word",
		padding: "3px 80px 18px 6px",
	},

	textContentItemDeleted: {
		fontStyle: "italic",
		color: "rgba(0, 0, 0, 0.36)",
		overflowWrap: "break-word",
		padding: "3px 80px 6px 6px",
	},

	messageMedia: {
		objectFit: "cover",
		width: 250,
		height: 200,
		borderTopLeftRadius: 8,
		borderTopRightRadius: 8,
		borderBottomLeftRadius: 8,
		borderBottomRightRadius: 8,
	},

	timestamp: {
		fontSize: 11,
		position: "absolute",
		bottom: 0,
		right: 5,
		color: "#667781",
	},

	dailyTimestamp: {
		alignItems: "center",
		textAlign: "center",
		alignSelf: "center",
		width: "110px",
		backgroundColor: "#e1f3fb",
		margin: "10px",
		borderRadius: "10px",
		boxShadow: "0 1px 1px #b3b3b3",
	},

	dailyTimestampText: {
		color: "#808888",
		padding: 8,
		alignSelf: "center",
		marginLeft: "0px",
	},

	ackIcons: {
		fontSize: 18,
		verticalAlign: "middle",
		marginLeft: 4,
	},

	deletedIcon: {
		fontSize: 18,
		verticalAlign: "middle",
		marginRight: 4,
	},

	ackDoneAllIcon: {
		color: "#53bdeb",
		fontSize: 18,
		verticalAlign: "middle",
		marginLeft: 4,
	},

	downloadMedia: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		backgroundColor: "inherit",
		padding: 10,
	},

	contactsInChat: {
		position: "relative",
		width: "100%",
		padding: "10px",
		cursor: "pointer",
		background: "#eeeeee",
		borderRadius: "5px",
		"& .contactProfilePicture": {
			position: "relative",
			border: "3px solid #eeeeee"
		},
		"& label": {
			marginTop: "10px",
			fontWeight: "700",
			color: "#757575"
		}
	},
	contactsInChatPopover: {
		padding: "10px",
	},
	annotation: {
		background: '#FACB9F',
	}
}));

const HtmlTooltip = withStyles((theme) => ({
	tooltip: {
		maxWidth: 220,
		fontSize: theme.typography.pxToRem(12),
		border: '1px solid #dadde9',
	},
}))(Tooltip);

const ConfirmationModal = ({ title, open, onClose, ticketId }) => {
	const classes = useStyles();
	const [messages, setMessages] = useState([]);

	// variaveis para renderizar contatos anexados
	const [contactAnchorEl, setContactAnchorEl] = useState(null);
	const contactOpen = Boolean(contactAnchorEl);
	const contactId = contactOpen ? 'contact-simple-popover' : undefined;
	const [contacts, setContacts] = useState([]);

	const renderMessageAck = (message) => {
		let ackIcon;
		let ackTooltip = [];
		let onlytext;

		const { ackHanded, ackRead, ackSent, editedAt, annotation } = message;

		if (ackSent) {
			ackTooltip.push(
				<Typography key="ackSent" style={{ fontSize: "12px" }}>
					{i18n.t("ack.sent")} - {format(parseISO(ackSent), "dd/MM/yyyy HH:mm")}
				</Typography>
			);
		}
		if (ackHanded) {
			ackTooltip.push(
				<Typography key="ackHanded" style={{ fontSize: "12px" }}>
					{i18n.t("ack.serverAck")} - {format(parseISO(ackHanded), "dd/MM/yyyy HH:mm")}
				</Typography>
			);
		}
		if (ackRead) {
			ackTooltip.push(
				<Typography key="ackRead" style={{ fontSize: "12px" }}>
					{i18n.t("ack.read")} - {format(parseISO(ackRead), "dd/MM/yyyy HH:mm")}
				</Typography>
			);
		}
		if (editedAt) {
			ackTooltip.push(
				<Typography key="ackRead" style={{ fontSize: "12px" }}>
					{'Editada'} - {format(parseISO(editedAt), "dd/MM/yyyy HH:mm")}
				</Typography>
			);
		}

		if (message?.annotation == true) {
			ackIcon = <EditIcon fontSize="small" className={classes.ackIcons} />;
			onlytext = i18n.t("ack.error");
		} else {
			switch (message.ack) {
				case 0:
					ackIcon = <ErrorIcon fontSize="small" className={classes.ackIcons} />;
					onlytext = i18n.t("ack.error");
					break;
				case 1:
				case 2:
					ackIcon = <Done fontSize="small" className={classes.ackIcons} />;
					onlytext = i18n.t("ack.sent");
					break;
				case 3:
					ackIcon = <DoneAll fontSize="small" className={classes.ackIcons} />;
					onlytext = i18n.t("ack.serverAck");
					break;
				case 4:
				case 5:
					ackIcon = <DoneAll fontSize="small" className={classes.ackDoneAllIcon} />;
					onlytext = i18n.t("ack.read");
					break;
				default:
					ackIcon = <ErrorIcon fontSize="small" className={classes.ackIcons} />;
					onlytext = i18n.t("ack.error");
			}
		}

		return (
			<HtmlTooltip
				arrow
				title={
					<React.Fragment>{ackTooltip.length === 0 ? onlytext : ackTooltip}</React.Fragment>
				}
			>
				{ackIcon}
			</HtmlTooltip>
		);
	};

	const checkMessageMedia = (message) => {
		if (message.mediaType === "locationMessage" || message.mediaType === 'liveLocationMessage') {
			const data = JsonParse(message.dataJson);
			const location = data?.message?.locationMessage
			return (<div>
				{
					location ?
						<iframe
							width="100%"
							height="200"
							style={{ borderColor: '#ff000000' }}
							src={`https://maps.google.com/maps?q=${location.degreesLatitude},${location.degreesLongitude}&t=&z=15&ie=UTF8&iwloc=&output=embed `}
						/>
						:
						"No Location Data..."
				}
			</div>);
		}
		if (message.mediaType === "image") {
			return <ModalImageCors
				imageUrl={message?.driveUrl ? message.driveUrl : `${process.env.REACT_APP_BACKEND_URL}public/${message.mediaUrl.split('/').at(-1)}`}
			/>;
		}
		if (message.mediaType === "audio") {
			const audioSrc = message?.driveUrl ? message.driveUrl : `${process.env.REACT_APP_BACKEND_URL}public/${message.mediaUrl.split('/').at(-1)}`;
			return (
				<audio key={audioSrc} controls>
					<source
						src={audioSrc}
						type="audio/ogg"
					></source>
				</audio>
			);
		}

		if (message.mediaType === "videoMessage" || message.mediaType === "video") {
			return (
				<video
					className={classes.messageMedia}
					src={message?.driveUrl ? message.driveUrl : `${process.env.REACT_APP_BACKEND_URL}public/${message.mediaUrl.split('/').at(-1)}`}
					controls
				/>
			);
		} else {
			return (
				<>
					<div className={classes.downloadMedia}>
						<Button
							startIcon={<GetApp />}
							color="primary"
							variant="outlined"
							target="_blank"
							href={message?.driveUrl ? message.driveUrl : `${process.env.REACT_APP_BACKEND_URL}public/${message.mediaUrl.split('/').at(-1)}`}
						>
							Download
						</Button>
					</div>
					<Divider />
				</>
			);
		}
	};

	const renderQuotedMessage = (message) => {
		return (
			<div
				className={clsx(classes.quotedContainerLeft, {
					[classes.quotedContainerRight]: message.fromMe,
				})}
			>
				<span
					className={clsx(classes.quotedSideColorLeft, {
						[classes.quotedSideColorRight]: message.quotedMsg?.fromMe,
					})}
				></span>
				<div className={classes.quotedMsg}>
					{!message.quotedMsg?.fromMe && (
						<span className={classes.messageContactName}>
							{message.quotedMsg?.Contact?.name}
						</span>
					)}
					{message.quotedMsg?.body}
				</div>
			</div>
		);
	};

	useEffect(() => {
		const delayDebounceFn = setTimeout(() => {
			const fetchContacts = async () => {
				if (!ticketId) return;
				try {
					const { data } = await api.get(`/messages/${ticketId}`);
					setMessages(data.messages)
					setTimeout(() => {
						var scrollEnd = document.getElementById('ChatBox');
						scrollEnd.scrollTop = scrollEnd.scrollHeight;
					}, 500);
				} catch (err) {
					console.error({err});
					toastError(err);
				}

			};
			fetchContacts();
		}, 500);
		return () => clearTimeout(delayDebounceFn);
	}, [ticketId]);

	// funções para identificar contatos anexados
	const handleContactClick = (event, cts) => {
		setContactAnchorEl(event.currentTarget);
		setContacts(cts);
	};
	const handleContactClose = () => {
		setContactAnchorEl(null);
		setTimeout(() => {
			setContacts([]);
		}, 500);
	};
	const renderContactList = (ms) => {
		if (String(ms.mediaType).toLowerCase() != 'contactmessage' && String(ms.mediaType).toLowerCase() != 'contactsarraymessage') return;
		if (!ms?.contacts) return;

		const cts = JsonParse(ms.contacts);
		if (!cts || !Array.isArray(cts) || cts.length == 0) return;
		let avatarImages = [];
		let contactNames = cts.length > 1 ? `${cts[0].name} e ${cts.length - 1} outro(s)` : cts[0].name;
		for (let x = 0; x < 3; x++) {
			if (!cts[x]) break;
			avatarImages.push(<Avatar
				alt="contact profile picture"
				src={cts[x].profilePic || ''}
				className="contactProfilePicture"
				style={{
					position: x == 0 ? "relative" : "absolute",
					top: x == 0 ? 0 : "10px",
					left: x == 0 ? 0 : `${35 * x - 10 * (x - 1)}px`,
					zIndex: x == 0 ? 3 : x == 1 ? 2 : 1
				}}
			/>)
		}

		return (
			<Box component="div">
				<Box
					aria-describedby={contactId}
					onClick={(event) => handleContactClick(event, cts)}
					className={classes.contactsInChat}
				>
					{avatarImages}
					<Typography component="label">{contactNames}</Typography>
				</Box>
			</Box>
		);
	}

	function renderChatContent(message) {
		if (message.fromMe) {
			return (<React.Fragment key={message.id}>
				<div
					className={classNames({
						[classes.messageRight]: true,
						[classes.annotation]: message?.annotation == true ? true : false
					})}
				>
					{message.mediaUrl && checkMessageMedia(message)}
					<div
						className={clsx(classes.textContentItem, {
							[classes.textContentItemDeleted]: message.isDeleted,
						})}
					>
						{message.isDeleted && (
							<Block
								color="disabled"
								fontSize="small"
								className={classes.deletedIcon}
							/>
						)}
						{message.quotedMsg && renderQuotedMessage(message)}
						{renderContactList(message)}
						<MarkdownWrapper>{String(message.mediaType).toLowerCase() != 'contactmessage' && String(message.mediaType).toLowerCase() != 'contactsarraymessage' && message.body}</MarkdownWrapper>
						<span className={classes.timestamp}>
							{format(parseISO(message.createdAt), "HH:mm")}
							{renderMessageAck(message)}
						</span>
					</div>
				</div>
			</React.Fragment>);
		} else {
			return (
				<React.Fragment key={message.id}>
					<div className={classes.messageLeft}>
						{message.mediaUrl && checkMessageMedia(message)}
						<div className={classes.textContentItem} style={{ paddingRight: "5px" }}>
							{message.quotedMsg && renderQuotedMessage(message)}
							{renderContactList(message)}
							<MarkdownWrapper>{String(message.mediaType).toLowerCase() != 'contactmessage' && String(message.mediaType).toLowerCase() != 'contactsarraymessage' && message.body}</MarkdownWrapper>
							<span className={classes.timestamp}>
								{format(parseISO(message.createdAt), "HH:mm")}
							</span>
						</div>
					</div>
				</React.Fragment>
			);
		}
	}

	return (
		<Dialog
			open={open}
			onClose={() => onClose(false)}
			aria-labelledby="confirm-dialog"
		>
			<DialogTitle id="confirm-dialog">{title}</DialogTitle>
			<DialogContent style={{ padding: '0px', overflow: 'hidden' }} dividers>
				<div id="ChatBox"
					style={{
						width: '540px',
						height: '450px',
						maxWidth: '540px',
						overflowX: 'hidden',
						overflowY: 'scroll',
						maxHeight: '500px',
						padding: '10px',
						backgroundColor: "#efeae2"
					}}>
					{messages.map((chat) => (
						renderChatContent(chat)
					))}
				</div>
				<Popover
					id={contactId}
					open={contactOpen}
					anchorEl={contactAnchorEl}
					onClose={handleContactClose}
					anchorOrigin={{
						vertical: 'top',
						horizontal: 'left',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'left',
					}}
					classes={{ paper: classes.contactsInChatPopover }}
				>
					<Box component="div">
						<List component="nav" aria-label="secondary mailbox folders">
							{
								contacts.map(c => {
									return (
										<ListItem button>
											<ListItemAvatar>
												<Avatar
													alt="contact profile picture"
													src={c.profilePic || ''}
													className="contactProfilePicture"
												/>
											</ListItemAvatar>
											<ListItemText primary={c.name || ''} secondary={c.number || ''} />
										</ListItem>)
								})
							}
						</List>
					</Box>
				</Popover>
			</DialogContent>
			<DialogActions>
				<Button
					variant="contained"
					onClick={() => onClose(false)}
					color="default"
				>
					{i18n.t("confirmationModal.buttons.cancel")}
				</Button>
			</DialogActions>
		</Dialog >
	);
};

export default ConfirmationModal;
