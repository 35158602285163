const FormatDate = (dataISO) => {
    const dt = new Date(dataISO);
    const day = String(dt.getDate()).padStart(2, '0');
    const month = String(dt.getMonth() + 1).padStart(2, '0'); // Janeiro é 0
    const year = dt.getFullYear();
    const hour = String(dt.getHours()).padStart(2, '0');
    const minutes = String(dt.getMinutes()).padStart(2, '0');

    return `${day}/${month}/${year} ${hour}:${minutes}`;
}

export default FormatDate;