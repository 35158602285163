import { Grid, ListItemText, MenuItem, Select } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import Autocomplete, {
	createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { AuthContext } from "../../context/Auth/AuthContext";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import ButtonWithSpinner from "../ButtonWithSpinner";
import ContactModal from "../ContactModal";
const drawerWidth = 320;
const filter = createFilterOptions({
	trim: true,
});
const useStyles = makeStyles(theme => ({
	drawer: {
		width: 0,
		flexShrink: 0,
		transitionDuration: "0.3s"
	},
	drawerPaper: {
		width: drawerWidth,
		display: "flex",
		borderTop: "1px solid rgba(0, 0, 0, 0.12)",
		borderRight: "1px solid rgba(0, 0, 0, 0.12)",
		borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
		borderTopRightRadius: 4,
		borderBottomRightRadius: 4,
	},
	header: {
		display: "flex",
		borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
		backgroundColor: "#eee",
		alignItems: "center",
		padding: theme.spacing(0, 1),
		minHeight: "73px",
		justifyContent: "flex-start",
	},
	content: {
		display: "flex",
		backgroundColor: "#eee",
		flexDirection: "column",
		padding: "8px 0px 8px 8px",
		height: "100%",
		overflowY: "scroll",
		...theme.scrollbarStyles,
	},
	contactAvatar: {
		margin: 15,
		width: 160,
		height: 160,
	},
	contactHeader: {
		display: "flex",
		padding: 8,
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		"& > *": {
			margin: 4,
		},
	},
	button100: {
		width: "100%",
	}
}));

const ContactDrawer = ({ open, handleDrawerClose, contact, onClose, initialContact = {} }) => {
	const classes = useStyles();
	const [options, setOptions] = useState([]);
	const [loading, setLoading] = useState(false);
	const [searchParam, setSearchParam] = useState("");
	const [selectedContact, setSelectedContact] = useState(null);
	const [selectedQueue, setSelectedQueue] = useState("");
	const [newContact, setNewContact] = useState({});
	const [contactModalOpen, setContactModalOpen] = useState(false);
	const { user } = useContext(AuthContext);



	useEffect(() => {
		if (initialContact.id !== undefined) {
			setOptions([initialContact]);
			setSelectedContact(initialContact);
		}
	}, [initialContact]);


	useEffect(() => {
		if (!open || searchParam.length < 3) {
			setLoading(false);
			return;
		}
		setLoading(true);
		const delayDebounceFn = setTimeout(() => {
			const fetchContacts = async () => {
				try {
					const { data } = await api.get("contacts", {
						params: { searchParam },
					});
					setOptions(data.contacts);
					setLoading(false);
				} catch (err) {
					console.error({err});
					setLoading(false);
					toastError(err);
				}
			};

			fetchContacts();
		}, 500);
		return () => clearTimeout(delayDebounceFn);
	}, [searchParam, open]);


	const handleClose = () => {
		onClose();
		setSearchParam("");
		setSelectedContact(null);
	};

	const handleSaveTicket = async contactId => {
		if (!contactId) return;
		if (selectedQueue === "" && user.profile !== 'admin') {
			toast.error("Selecione uma fila");
			return;
		}
		setLoading(true);
		try {
			const queueId = selectedQueue !== "" ? selectedQueue : null;
			const { data: ticket } = await api.post("/tickets", {
				contactId: contactId,
				queueId,
				userId: user.id,
				status: "open",
			});
			onClose(ticket);
		} catch (err) {
			console.error({err});
			toastError(err);
		}
		setLoading(false);
	};



	const handleSelectOption = (e, newValue) => {
		if (newValue?.number) {
			setSelectedContact(newValue);
		} else if (newValue?.name) {
			setNewContact({ name: newValue.name });
			setContactModalOpen(true);
		}
	};

	const handleCloseContactModal = () => {
		setContactModalOpen(false);
	};

	const handleAddNewContactTicket = contact => {
		handleSaveTicket(contact.id);
	};

	const createAddContactOption = (filterOptions, params) => {
		const filtered = filter(filterOptions, params);

		if (params.inputValue !== "" && !loading && searchParam.length >= 3) {
			filtered.push({
				name: `${params.inputValue}`,
			});
		}

		return filtered;
	};

	const renderOption = option => {
		if (option.number) {
			return `${option.name} - ${option.number}`;
		} else {
			return `${i18n.t("newTicketModal.add")} ${option.name}`;
		}
	};

	const renderOptionLabel = option => {
		if (option.number) {
			return `${option.name} - ${option.number}`;
		} else {
			return `${option.name}`;
		}
	};



	if (open) {
		if (window.innerWidth > 560) {
			document.querySelector("#ModalConcluir").style = "width: 320px !important;"
		}
	}

	function modalNewTicketClose(e) {
		document.querySelector("#ModalNewTicket").style = "width: 0px !important;"
	}

	const renderContactAutocomplete = () => {
		if (initialContact === undefined || initialContact.id === undefined) {
			return (
				<Grid xs={12} item>
					<Autocomplete
						fullWidth
						options={options}
						loading={loading}
						clearOnBlur
						autoHighlight
						freeSolo
						clearOnEscape
						getOptionLabel={renderOptionLabel}
						renderOption={renderOption}
						filterOptions={createAddContactOption}
						onChange={(e, newValue) => handleSelectOption(e, newValue)}
						renderInput={params => (
							<TextField
								{...params}
								label={i18n.t("newTicketModal.fieldLabel")}
								variant="outlined"
								autoFocus
								onChange={e => setSearchParam(e.target.value)}
								onKeyPress={e => {
									if (loading || !selectedContact) return;
									else if (e.key === "Enter") {
										handleSaveTicket(selectedContact.id);
									}
								}}
								InputProps={{
									...params.InputProps,
									endAdornment: (
										<React.Fragment>
											{loading ? (
												<CircularProgress color="inherit" size={20} />
											) : null}
											{params.InputProps.endAdornment}
										</React.Fragment>
									),
								}}
							/>
						)}
					/>
				</Grid>
			)
		}
		return null;
	}

	return (
		<>
			<ContactModal
				open={contactModalOpen}
				initialValues={newContact}
				onClose={handleCloseContactModal}
				onSave={handleAddNewContactTicket}
				byContactPage={false}
			></ContactModal>
			<Drawer
				className={classes.drawer}
				id="ModalNewTicket"
				variant="persistent"
				anchor="right"
				open={open}
				PaperProps={{ style: { position: "absolute" } }}
				BackdropProps={{ style: { position: "absolute" } }}
				ModalProps={{
					container: document.getElementById("drawer-container"),
					style: { position: "absolute" },
				}}
				classes={{
					paper: classes.drawerPaper,
				}}
			>
				<div className={classes.header}>
					<IconButton
						id="closeModalNewTicket"
						onClick={(e) => {
							handleDrawerClose(e);
							modalNewTicketClose(e);
						}
						}>
						<CloseIcon />
					</IconButton>
					<Typography style={{ justifySelf: "center" }}>
						{i18n.t("contactDrawer.headerTicket")}
					</Typography>
				</div>
				<div className={classes.content}>
					<Paper square variant="outlined" className={classes.contactHeader}>
						<div>
							<DialogContent>
								<Grid style={{ width: 265 }} container spacing={2}>
									{renderContactAutocomplete()}
									<Grid xs={12} item>
										<Select
											fullWidth
											displayEmpty
											variant="outlined"
											value={selectedQueue}
											onChange={(e) => {
												setSelectedQueue(e.target.value)
											}}
											MenuProps={{
												anchorOrigin: {
													vertical: "bottom",
													horizontal: "left",
												},
												transformOrigin: {
													vertical: "top",
													horizontal: "left",
												},
												getContentAnchorEl: null,
											}}
											renderValue={() => {
												if (selectedQueue === "") {
													return "Selecione uma fila"
												}
												const queue = user.queues.find(q => q.id === selectedQueue)
												return queue.name
											}}
										>
											{user.queues?.length > 0 &&
												user.queues.map((queue, key) => (
													<MenuItem dense key={key} value={queue.id}>
														<ListItemText primary={queue.name} />
													</MenuItem>
												))}
										</Select>
									</Grid>
								</Grid>
							</DialogContent>
							<DialogActions>

								<ButtonWithSpinner
									variant="contained"
									type="button"
									disabled={!selectedContact}
									onClick={() => handleSaveTicket(selectedContact.id)}
									color="primary"
									loading={loading}
									className={classes.button100}
								>
									{i18n.t("newTicketModal.buttons.ok")}
								</ButtonWithSpinner>
							</DialogActions>
						</div>
					</Paper>
				</div>

			</Drawer>
		</>
	);
};

export default ContactDrawer;
