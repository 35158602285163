const CheckPassword = (str) => {
    // Verificar se a senha tem pelo menos 8 caracteres
    if (str.length < 8) {
        return false;
    }

    // Verificar se a senha contém pelo menos uma letra maiúscula
    if (!/[A-Z]/.test(str)) {
        return false;
    }

    // Verificar se a senha contém pelo menos uma letra minúscula
    if (!/[a-z]/.test(str)) {
        return false;
    }

    // Verificar se a senha contém pelo menos um número
    if (!/\d/.test(str)) {
        return false;
    }

    // Verificar se a senha contém pelo menos um caractere especial ou pontuação
    if (!/[\W_]/.test(str)) {
        return false;
    }

    return true;
};

export default CheckPassword;