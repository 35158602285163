import React, { useContext, useEffect, useRef, useState } from "react";

import Avatar from "@material-ui/core/Avatar";
import Badge from "@material-ui/core/Badge";
import Chip from "@material-ui/core/Chip";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import { green, red } from "@material-ui/core/colors";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from "clsx";
import { format, isSameDay, parseISO } from "date-fns";
import { useHistory, useParams } from "react-router-dom";

import { i18n } from "../../translate/i18n";

import { Tooltip, Box } from "@material-ui/core";
import MarkdownWrapper from "../MarkdownWrapper";

import Checkbox from '@material-ui/core/Checkbox';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ReopenTicketModal from "../ReopenTicketModal";
import ErrorIcon from '@material-ui/icons/Error';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import InstagramIcon from '@material-ui/icons/Instagram';
import GetUrlTicketPath from "../../helpers/GetUrlTicketPath";

const useStyles = makeStyles(theme => ({
	ticket: {
		position: "relative",
	},

	pendingTicket: {
		cursor: "unset",
	},

	noTicketsDiv: {
		display: "flex",
		height: "100px",
		margin: 40,
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
	},

	noTicketsText: {
		textAlign: "center",
		color: "rgb(104, 121, 146)",
		fontSize: "14px",
		lineHeight: "1.4",
	},

	noTicketsTitle: {
		textAlign: "center",
		fontSize: "16px",
		fontWeight: "600",
		margin: "0px",
	},

	contactNameWrapper: {
		display: "flex",
		justifyContent: "space-between",
	},

	lastMessageTime: {
		justifySelf: "flex-end",
		float: "right"
	},

	closedBadge: {
		alignSelf: "center",
		justifySelf: "flex-end",
		marginRight: 32,
		marginLeft: "auto",
		zIndex: 3
	},
	campaignBadge: {
		alignSelf: "center",
		justifySelf: "flex-end",
		marginRight: '80px',
		marginLeft: "auto",
	},
	contactLastMessage: {
		maxWidth: '30em',
		width: "100%",
		"& a": {
			pointerEvents: "none"
		}
	},

	newMessagesCount: {
		alignSelf: "center",
		marginRight: 8,
		marginLeft: "auto",
	},

	badgeStyle: {
		color: "white",
		backgroundColor: green[500],
	},

	badgeAlertStyle: {
		color: red[900],
		height: "fit-content",
		width: "fit-content",
		padding: "0",
	},

	acceptButton: {
		textTransform: "capitalize",
		padding: "2px 5px",
		margin: "0px",
		width: "70px",
		fontSize: "11px",
	},

	ticketQueueColor: {
		flex: "none",
		width: "8px",
		height: "100%",
		position: "absolute",
		top: "0%",
		left: "0%",
	},
	ticketQueueColorTags: {
		flex: "none",
		width: "8px",
		height: "90%",
		position: "absolute",
		top: "0%",
		left: "0%",
	},

	icon: {
		borderRadius: 3,
		width: 16,
		height: 16,
		boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
		backgroundColor: '#f5f8fa',
		backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
		'$root.Mui-focusVisible &': {
			outline: '2px auto rgba(19,124,189,.6)',
			outlineOffset: 2,
		},
		'input:hover ~ &': {
			backgroundColor: '#ebf1f5',
		},
		'input:disabled ~ &': {
			boxShadow: 'none',
			background: 'rgba(206,217,224,.5)',
		},
	},
	checkedIcon: {
		backgroundColor: '#137cbd',
		backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
		'&:before': {
			display: 'block',
			width: 16,
			height: 16,
			backgroundImage:
				"url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
				" fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
				"1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
			content: '""',
		},
		'input:hover ~ &': {
			backgroundColor: '#106ba3',
		},
	},
	openChip: {
		backgroundColor: '#0b708c',
		color: '#ffffff'
	},
	pendingChip: {
		backgroundColor: '#ffea00',
		color: '#ffffff'
	},
	closedChip: {
		backgroundColor: '#b71c1c',
		color: '#ffffff'
	},
	moduleBadge: {
		marginRight: 0,  
		background: '#ffffff', 
		borderRadius: '100%',
		width: '18px',
		height: '18px'
	}
}));

const TicketListItem = ({ ticket, index, checked, reopen, hiddenCheckboxOption, searchingByParam, ...props }) => {
	const classes = useStyles();
	const history = useHistory();
	const { ticketId } = useParams();
	const isMounted = useRef(true);
	const [reopenTicketModalOpen, setReopenTicketModalOpen] = useState(false);

	const currentTicketUUID = GetUrlTicketPath();

	const goToTicket = async (uuid) => {
		history.push(`/tickets/${uuid}`);
	}

	const handleSelectTicket = (e, uuid) => {
		if (e.target) {
			goToTicket(uuid);
		}
	};

	return (
		<React.Fragment key={ticket.id}>
			<ReopenTicketModal
				modalOpen={reopenTicketModalOpen}
				onClose={e => setReopenTicketModalOpen(false)}
				ticketId={ticket?.id}
			/>
			<ListItem
				dense
				button
				onClick={(event) => {
					handleSelectTicket(event, ticket.uuid);
				}}
				selected={ticketId && ticketId == ticket.uuid}
				index={index}
				id={ticket.uuid}
				className={clsx(classes.ticket)}
				style={{ background: ticketId && ticketId == ticket.uuid ? '#e0e0e0' : '' }}
			>

				<Tooltip
					arrow
					placement="right"
					title={ticket.Queue?.name || "Sem fila"}
				>
					<span
						style={{ backgroundColor: ticket.Queue?.color || "#7C7C7C" }}
						className={classes.ticketQueueColor}
					/>
				</Tooltip>
				<ListItemAvatar>
					<Badge
						overlap="circular"
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'right',
						}}
						badgeContent={
							ticket?.whatsapp?.moduleId == 1 ? (
								<Tooltip title="Este atendimento foi realizado pelo WhatsApp.">
									<WhatsAppIcon className={classes.moduleBadge} style={{color: '#25D366'}}/>
								</Tooltip>
							) : ticket?.whatsapp?.moduleId == 2 ? (
								<Tooltip title="Este atendimento foi realizado pelo Instagram.">
									<InstagramIcon className={classes.moduleBadge} style={{color: '#C13584'}} />
								</Tooltip>
							) : ( 
								<> </>
							)
						}
					>						
						<Avatar alt="user_pic" src={ticket?.Contact?.profilePicUrl} />
					</Badge>
				</ListItemAvatar>
				<ListItemText
					disableTypography
					primary={
						<span className={classes.contactNameWrapper}>
							<Typography
								className={classes.contactLastMessage}
								noWrap
								component="span"
								variant="body2"
								style={{ display: "block", justifyContent: "space-between" }}
								color="textSecondary">

								{ticket?.Contact?.isGroup == true || ticket?.isGroup == true  ? i18n.t('groupPrefix') + ticket.Contact?.name : ticket.Contact?.name}

								{
									(ticket.whatsapp?.status && String(ticket.whatsapp.status).toLowerCase() != 'connected')
									&&
									<Badge
										style={{
											float: "right",
											zIndex: "3",
											top: "8px",
											right: "9px"
										}}
										badgeContent={
											<Tooltip
												arrow
												placement="bottom"
												title={<Typography gutterBottom color="inherit">{i18n.t("messagesInput.placeholderConnection")}</Typography>}
											>
												<ErrorIcon />
											</Tooltip>
										}
										classes={{
											badge: classes.badgeAlertStyle,
										}}
									/>
								}

								{(ticket.lastMessage) && (
									<Typography
										className={classes.lastMessageTime}
										component="span"
										variant="body2"
										color="textSecondary"
										style={{
											marginRight: (ticket.whatsapp?.status && String(ticket.whatsapp.status).toLowerCase() != 'connected') ? '20px': '0'
										}}
									>
										{ticket?.lastMessageDate ? <>
											{isSameDay(parseISO(ticket.lastMessageDate), new Date()) ? (
												<>{format(parseISO(ticket.lastMessageDate), "HH:mm")}</>
											) : (
												<>{format(parseISO(ticket.lastMessageDate), "dd/MM/yyyy")}</>
											)}
										</> : <>
											{isSameDay(parseISO(ticket.updatedAt), new Date()) ? (
												<>{format(parseISO(ticket.updatedAt), "HH:mm")}</>
											) : (
												<>{format(parseISO(ticket.updatedAt), "dd/MM/yyyy")}</>
											)}
										</>}
									</Typography>
								)}
							</Typography>
						</span>
					}
					secondary={
						<span className={classes.contactNameWrapper}>
							<Typography
								className={classes.contactLastMessage}
								noWrap
								component="span"
								variant="body2"
								color="textSecondary"
							>
								{((!ticket?.draft || ticket.uuid == currentTicketUUID) && ticket.lastMessage) ? (
									<MarkdownWrapper>{ticket.lastMessage}</MarkdownWrapper>
								) : (ticket?.draft && ticket?.draft != "") ? (
									<MarkdownWrapper>{`*Rascunho*: ${ticket.draft}`}</MarkdownWrapper>
								) : (
									<br />
								)}
							</Typography>

							<Badge
								className={classes.newMessagesCount}
								badgeContent={ticket.unreadMessages}
								classes={{
									badge: classes.badgeStyle,
								}}
							/>
							{searchingByParam == true && (
								<Chip
									size="small"
									label={i18n.t(`tickets.tabs.${ticket?.inChatbotFlow == true ? 'chatbot' : !ticket.queueId && ticket.status == 'open' ? 'chatbot' : ticket.status}.title`)}
									className={classes[`${ticket.status}Chip`]}
								/>
							)}
						</span>
					}
				/>
				<ListItemIcon style={{ position: "relative", zIndex: 2 }}>
					{
						!hiddenCheckboxOption &&
						<Checkbox
							{...props}
							checked={checked}
							style={{ marginLeft: 20 }}
							className={classes.root}
							disableRipple
							color="default"
							checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
							icon={<span className={classes.icon} />}
							inputProps={{ 'aria-label': 'teste' }}
						/>
					}
				</ListItemIcon>

			</ListItem>
			{ticket?.Contact?.tagsId &&
				<>
					<div
						style={{
							marginBottom: "-1px",
							position: "relative",
							padding: "0 2px 0 10px",
							background: ticketId && ticketId == ticket.uuid ? '#e0e0e0' : ''
						}}
						onClick={(event) => {
							handleSelectTicket(event, ticket.uuid);
						}}
					>
						<>
							<Tooltip
								arrow
								placement="right"
								title={ticket.Queue?.name || "Sem fila"}
							>
								<span
									style={{ backgroundColor: ticket.Queue?.color || "#7C7C7C" }}
									className={classes.ticketQueueColorTags}
								/>
							</Tooltip>
							{Array.isArray(ticket?.Contact?.tagsId) && ticket?.Contact?.tagsId.map((tag) => (
								<Tooltip
									arrow
									title={tag.name || "Não identificado"}
								>
									<Chip
										size="small"
										style={{
											borderRadius: '5px',
											height: '10px',
											padding: '0px 10px',
											margin: '0px 3px',
											backgroundColor: tag.color,
											'& .MuiChip-label': {
												paddingLeft: '5px',
												paddingRight: '5px',
											},
										}}
									/>

								</Tooltip>
							))}
						</>
					</div>
				</>
			}

			<Divider variant="inset" component="li" style={{ marginLeft: 0 }} />
		</React.Fragment>
	);
};

export default TicketListItem;