import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Switch } from "@mui/material";
import { Button } from "@material-ui/core";
import { i18n } from "../../translate/i18n";
import ApiModal from "./Modals/ApiModal";
import { useState } from "react";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import { toast } from "react-toastify";
import { KTSVG } from "../../../_metronic/helpers";
import ApiInfoModal from "./Modals/ApiInfoModal";
import FormatDate from "../../helpers/FormatDate";

const ApiTable = ({ integrations }) => {
  const [IntegrationModalOpen, setIntegrationModalOpen] = useState(false);
  const [integrationModalInfoOpen, setIntegrationModalInfoOpen] = useState(false);
  const [integrationId, setIntegrationId] = useState(null);

  // manipulando o modal
  const showIntegrationModalOpen = () => {
    setIntegrationModalOpen(true);
  };

  const hideIntegrationModalOpen = () => {
    setIntegrationModalOpen(false);
    setIntegrationId(null);
  };

  const handleDisableApi = async (integrationData) => {
    try {
      integrationData = { ...integrationData, status: !integrationData.status };
      await api.put(`/integrations/${integrationData.id}`, integrationData);
      toast.success(i18n.t("integrations.webhook.form.validations.success"));
    } catch (err) {
      console.error({ err });
      toastError(err);
    }
  };

  const handleDeleteApi = async (integrationData) => {
    try {
      await api.delete(`/integrations/${integrationData.id}`);
      toast.success(i18n.t("integrations.webhook.form.validations.deleted"));
    } catch (err) {
      console.error({ err });
      toastError(err);
    }
  };

  const handleEditIntegrations = (integration) => {
    setIntegrationId(integration.id);
    setIntegrationModalOpen(true);
  };

  const handleInfoIntegrations = (integration) => {
    setIntegrationId(integration.id);
    setIntegrationModalInfoOpen(true);
  };

  const hideInfoIntegration = () => {
    setIntegrationModalInfoOpen(false);
    setIntegrationId(null);
  };

  return (
    <>
      <Button
        style={{
          marginLeft: "1.5px",
          float: "right"
        }}
        variant="contained"
        color="primary"
        onClick={() => showIntegrationModalOpen()}
      >
        {i18n.t("integrations.api.buttons.add")}
      </Button>
      <TableContainer>
        <Table aria-label="tabela de integrações">
          <TableHead>
            <TableRow>
              <TableCell align="left">{i18n.t("integrations.api.table.title")}</TableCell>
              <TableCell align="left">{i18n.t("integrations.api.table.name")}</TableCell>
              <TableCell align="left">{i18n.t("integrations.api.table.status")}</TableCell>
              <TableCell align="left">{i18n.t("integrations.api.table.method")}</TableCell>
              <TableCell align="left">{i18n.t("integrations.api.table.createdAt")}</TableCell>
              <TableCell align="left">{i18n.t("integrations.api.table.updatedAt")}</TableCell>
              <TableCell align="right">{i18n.t("integrations.api.table.actions")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {integrations && integrations.map((row) => (
              <TableRow key={row.id}>
                  <TableCell 
                    component="th" 
                    scope="row"
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis"
                    }}
                  >
                  {row.title}
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }}
                >
                  {row.title}
                </TableCell>
                <TableCell align="left">
                    <Switch
                      checked={row.status}
                      onChange={() => handleDisableApi(row)}
                      color="primary"
                      name={`status${row.status}`}
                      key={`status${row.status}`}
                      inputProps={{ "aria-label": "handle status" }}
                    />
                  </TableCell>
                  <TableCell align="left">{row.method}</TableCell>
                  <TableCell align="left">{FormatDate(row.createdAt)}</TableCell>
                  <TableCell align="left">{FormatDate(row.updatedAt)}</TableCell>
                  <TableCell align="right">
                    <Button
                      title={i18n.t("buttons.edit")}
                      className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                      onClick={() => handleEditIntegrations(row)}
                    >
                      <KTSVG path="/media/icons/duotune/art/art005.svg" className="svg-icon-3" />
                    </Button>
                    <Button
                      title={i18n.t("buttons.info")}
                      className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                      onClick={() => handleInfoIntegrations(row)}
                    >
                      <KTSVG path="/media/icons/duotune/general/gen004.svg" className="svg-icon-3" />
                    </Button>
                    <Button
                      title={i18n.t("buttons.delete")}
                      className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
                      onClick={() => handleDeleteApi(row)}
                    >
                      <KTSVG path="/media/icons/duotune/general/gen027.svg" className="svg-icon-3" />
                    </Button>
                  </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <ApiModal
        open={IntegrationModalOpen}
				onClose={hideIntegrationModalOpen}
        integrationId={integrationId}
      />

      <ApiInfoModal 
        open={integrationModalInfoOpen}
        onClose={hideInfoIntegration}
        integrationId={integrationId}
      />
    </>
  );
}

export default ApiTable;