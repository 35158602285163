import { Box, Checkbox, Chip, ListItemText, Typography, makeStyles, Menu, Tooltip } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import { useEffect, useState } from "react";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        listStyle: 'none',
        padding: theme.spacing(0.5),
        margin: 0,
    },
    chip: {
        margin: theme.spacing(0.5),
    },
    input: {
        position: "relative",
        border: "1px solid #e4e6ef",
        padding: "7px 10px",
        borderRadius: "4px",
        cursor: "pointer",
    },
    inputDisabled: {
        borderColor: "#e4e6ef",
        color: "#e4e6ef",
        cursor: "not-allowed",
        pointerEvents: "none"

    }
}));

const MultiSelectChip = ({ selectedTagsIds = [], onChange, onClose, formGroup = true, ...props }) => {
    const classes = useStyles();

    const [tags, setTags] = useState([]);
    const [tagsFilter, setTagsFilter] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
        onClose();
	};

    useEffect(() => {
        const fetchTags = async () => {
            try {
                let { data } = await api.get("/tags");
                if (Array.isArray(data.tags)) {
                    data.tags.unshift({ id: 'null', color: "#0b708c", name: i18n.t("ticketsByTags.filters.selectWithoutTags"), status: 1 });
                    data.tags.unshift({ id: 'selectAll', color: "#0b708c", name: i18n.t("ticketsByTags.filters.selectAllTags"), status: 1 });
                    setTags(data.tags);
                } else {
                    console.error('Data from /tags is not an array:', data);
                }
            } catch (err) {
                console.error({err});
                toastError(err);
            }
        };
        fetchTags();
    }, []);

    const handleChange = tagId => {

        // verificar se é selecionar todos
        if (String(tagId).toLowerCase() == 'selectall') {
            // verificar se é para selecionar ou deselecionar
            if (selectedTagsIds.length == 0) {
                // selecionar
                const tagsMapped = tags.map(t => t.id);
                onChange(tagsMapped);
            } else {
                // deselecionar
                onChange([]);
            }
            return;
        }

        let updatedTags;

        if (selectedTagsIds.includes(tagId)) {
            updatedTags = selectedTagsIds.filter(id => id !== tagId);
        } else {
            updatedTags = [...selectedTagsIds, tagId];
        }

        onChange(updatedTags);
    };

    useEffect(() => {
        const filterTags = tags.filter(tag => selectedTagsIds.includes(tag.id));
        setTagsFilter(filterTags);
    }, [selectedTagsIds, tags])

    return (
        <Box className={formGroup == true && 'form-group col-md-12'}>
            <Box mb={1}>
                <Typography variant="body1">
                    Selecione os contatos por Etiquetas.
                    <span className="text-danger"></span>
                </Typography>
            </Box>
            <Box className={formGroup == true && 'form-group'}>
                <FormControl
                    fullWidth
                    margin="dense"
                    style={{
                        padding: '0px',
                        margin: '5px 0px 0px 0px'
                    }}>
                    <Tooltip title={"Etiquetas"}>
                        <Box 
                            className={classNames({
                                    [classes.input]: true,
                                    [classes.inputDisabled]: props?.disabled == true,
                                })
                            }
                            component="span" 
                            onClick={handleClick} 
                            aria-haspopup="true" aria-controls="tags-menu"
                        >
                            Etiquetas
                        </Box>
                    </Tooltip>
                    <Menu
                        id="tags-menu"
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        PaperProps={{
                            style: {
                                maxHeight: "300px",
                                width: anchorEl?.width || "630px",
                                marginTop: "17px"
                            },
                        }}
                        keepMounted
                    >
                        {tags?.length > 0 &&
                            tags.filter(tag => tag.status === 1).map(tag => (
                                <MenuItem dense key={tag.id} value={tag.id} onClick={() => handleChange(tag.id)}>
                                    <Checkbox
                                        size="small"
                                        color="default"
                                        style={{
                                            color: selectedTagsIds.includes(tag.id) ? tag.color : undefined
                                        }}
                                        checked={selectedTagsIds.indexOf(tag.id) > -1}
                                    />
                                    <ListItemText primary={tag.name} />
                                </MenuItem>
                            ))}
                    </Menu>
                </FormControl>
            </Box>
            <Box className={formGroup == true && 'form-group col-md-12'}>
                <Box component="ul" className={classes.root}>
                    {tagsFilter.map((tag, i) => {
                        return (
                            <li key={i}>
                                <Chip
                                    color="primary"
                                    label={tag.name}
                                    style={{
                                        backgroundColor: tag.color,
                                        borderRadius: '5px',
                                        height: '20px'
                                    }}
                                    className={classes.chip}
                                />
                            </li>
                        );
                    })}
                </Box>
            </Box>
        </Box>
    );
};

export default MultiSelectChip;
