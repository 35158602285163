import React, { useEffect, useState, useReducer } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import { i18n } from "../../translate/i18n";
import PropTypes from "prop-types";
import clsx from "clsx";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";

// icons
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from '@material-ui/icons/Edit';
import FilterListIcon from "@material-ui/icons/FilterList";
import PauseCircleFilledIcon from '@material-ui/icons/PauseCircleFilled';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import SendIcon from '@material-ui/icons/Send';

import api from '../../services/api'; // Importe sua instância Axios
import { Box } from "@material-ui/core";
import ScheduleMessageModal from "../ScheduleMessageModal";
import { format, parseISO } from "date-fns";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  searchBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: 'flex-end',
    width: "60%",
    gap: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      flexDirection: "column",
      alignItems: "stretch",
    },
  },
  textField: {
    width: "40%",
    [theme.breakpoints.down('sm')]: {
      width: "100%",
    },
  },
  newScheduleButton: {
    width: "30%",
    textTransform: "none",
    padding: '3px 0px 10px 0px',
    [theme.breakpoints.down('sm')]: {
      width: "100%",
    },
  },
  formControl: {
    width: "20%",
    [theme.breakpoints.down('sm')]: {
      width: "100%",
    },
  },
}));



const reducer = (state, action) => {

  if (action.type === "LOAD_SCHEDULE_MESSAGES") {
    const scheduleMessages = action.payload;
    if (!scheduleMessages) return [];
    const newScheduleMessages = [];
    state = [];

    scheduleMessages.forEach((scheduleMessage) => {
      const scheduleMessageIndex = state.findIndex((c) => c.id === scheduleMessage.id);
      if (scheduleMessageIndex !== -1) {
        state[scheduleMessageIndex] = scheduleMessage;
      } else {
        newScheduleMessages.push(scheduleMessage);
      }
    });

    return [...state, ...newScheduleMessages];
  }

  if (action.type === "UPDATE_SCHEDULE_MESSAGES") {
    const scheduleMessage = action.payload;
    const scheduleMessageIndex = state.findIndex((c) => c.id === scheduleMessage.id);

    if (scheduleMessageIndex !== -1) {
      state[scheduleMessageIndex] = scheduleMessage;
      return [...state];
    } else {
      return [scheduleMessage, ...state];
    }
  }

  if (action.type === "DELETE_SCHEDULE_MESSAGE") {
    const scheduleMessageId = action.payload;

    const scheduleMessageIndex = state.findIndex((c) => c.id === scheduleMessageId);
    if (scheduleMessageIndex !== -1) {
      state.splice(scheduleMessageIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }

};


function ScheduleMessageListModal(props) {
  const classes = useStyles();
  const { open, onClose, onConfirm, contactId, ticketId, whatsappId } = props;
  const [openEditScheduleModal, setOpenEditScheduleModal] = useState(false);
  const [scheduleMessages, dispatch] = useReducer(reducer, []);
  const [scheduleId, setScheduleId] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchParam, setSearchParam] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [state, setState] = useState({ status: 'await' });

  const fetchData = async () => {
    if (contactId) {
      try {
        const { data } = await api.get(`/scheduleMessages/list/${contactId}`, {
          params: {
            pageNumber: page + 1,
            limit: rowsPerPage,
            searchParam: searchTerm,
            status: state.status
          },
        });
        dispatch({ type: "LOAD_SCHEDULE_MESSAGES", payload: data.scheduleMessage });
        setTotalCount(data.count);
        setHasMore(data.hasMore);
      } catch (error) {
        console.error("Erro ao buscar dados:", error);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [searchTerm, state, page, rowsPerPage, searchParam, contactId]);


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClose = () => {
    setSearchTerm('');
    setState({ status: 'await' });
    onClose(false)
  }

  const handleDelete = async (id) => {
    try {
      await api.delete(`/scheduleMessages/${id}`);
      dispatch({ type: "DELETE_SCHEDULE_MESSAGE", payload: id });
    } catch (error) {
      console.error(error)
    }
  };

  const handleEdit = async (id) => {
    setScheduleId(id);
    setTimeout(() => {
      setOpenEditScheduleModal(true);
    }, 250);
  };

  const handleSendNow = async (id) => {
    try {
      const { data } = await api.post(`/scheduleMessages/send/${id}`);
      // setScheduleMessages((prevScheduleMessages) =>
      //   prevScheduleMessages.map((schedule) =>
      //     schedule.id === id ? data : schedule
      //   )
      // );
    } catch (err) {
      console.error(err);
    }
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleChange = (event) => {
    setState({
      ...state,
      status: event.target.value,
    });
  };

  const handleCreateOrUpdateSchedule = (scheduleData) => {
    dispatch({ type: "UPDATE_SCHEDULE_MESSAGES", payload: scheduleData });
  };


  useEffect(() => {
    dispatch({ type: "RESET" });
  }, [page, rowsPerPage, searchParam]);

  const handleNewScheduleMessage = () => {
    setOpenEditScheduleModal(true)
  };


  function truncateText(text, maxLength) {
    if (text?.length > maxLength) {
      return text.substring(0, maxLength) + '...';
    } else {
      return text;
    }
  }

  const createResponseToDate = (start, stop, time) => {
    if (stop) {
      return `${format(parseISO(start), "dd/MM/yyyy")} até ${format(parseISO(stop), "dd/MM/yyyy")} - ${time}`
    }
    if (start) {
      return `${format(parseISO(start), "dd/MM/yyyy")} - ${time}`
    }
    return ''
  }


  return (
    <>
      <ScheduleMessageModal
        scheduleId={scheduleId}
        open={openEditScheduleModal}
        onClose={() => {
          fetchData();
          setScheduleId(null);
          setOpenEditScheduleModal(false);
        }}
        onConfirm={handleCreateOrUpdateSchedule}
        contactId={contactId}
        ticketId={ticketId}
        whatsappId={whatsappId}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle id="form-dialog-title">
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography>Agendamentos de Mensagens</Typography>
            <Box className={classes.searchBox}>
              <Box className={classes.textField}>
                <TextField
                  placeholder="Buscar agendamento"
                  variant="outlined"
                  size="small"
                  value={searchTerm}
                  onChange={handleSearchChange}
                  fullWidth
                />
              </Box>
              <Box className={classes.formControl}>
                <FormControl variant="outlined" fullWidth size="small">
                  <Select
                    value={state.status}
                    onChange={handleChange}
                  >
                    <MenuItem value={'all'}>Todos</MenuItem>
                    <MenuItem value={'await'}>Aguardando</MenuItem>
                    <MenuItem value={'started'}>Iniciados</MenuItem>
                    <MenuItem value={'finished'}>Finalizados</MenuItem>
                    <MenuItem value={'error'}>Com erros</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Box className={classes.newScheduleButton}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={handleNewScheduleMessage}
                >
                  Novo agendamento
                </Button>
              </Box>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent style={{ minHeight: '400px' }}>
          <div className={classes.root}>
            <TableContainer style={{ minHeight: '400px', overflow: 'auto' }}>
              <Table className={classes.table} aria-labelledby="tableTitle" size="medium" aria-label="enhanced table">
                <TableHead>
                  <TableRow>
                    <TableCell>Mensagem</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Data de Envio</TableCell>
                    <TableCell align="right">Ações</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {scheduleMessages.map((schedule) => (
                    <TableRow hover tabIndex={-1} key={schedule.id}>
                      <TableCell>{truncateText(schedule.message, 60)}</TableCell>
                      <TableCell>{i18n.t(`schedules.table.status.${schedule.status}`)}</TableCell>
                      <TableCell>{createResponseToDate(schedule.sendAt, schedule.stopAt, schedule.time)}</TableCell>
                      <TableCell align="right" style={{ width: '225px' }}>
                        <Box>
                          <Tooltip title="Enviar imediatamente">
                            <IconButton onClick={() => handleSendNow(schedule.id)} style={{ padding: '5px' }} aria-label="send" disabled={['await', 'started'].includes(schedule.status) == false}>
                              <SendIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Editar agendamento">
                            <IconButton onClick={() => handleEdit(schedule.id)} style={{ padding: '5px' }} aria-label="edit" disabled={['await', 'started'].includes(schedule.status) == false}>
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Apagar agendamento">
                            <IconButton onClick={() => handleDelete(schedule.id)} style={{ padding: '5px' }} aria-label="delete" disabled={['await', 'started'].includes(schedule.status) == false}>
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '16px' }}>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={totalCount}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleClose} color="default">
            {i18n.t("confirmationModal.buttons.cancel")}
          </Button>
          <Button variant="contained" onClick={handleClose} color="secondary">
            {i18n.t("confirmationModal.buttons.confirm")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ScheduleMessageListModal;
