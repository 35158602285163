import React from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    container: {
        display: "flex",
        alignItems: "center"
    },
    border: {
        borderBottom: "2px solid lightgray",
        width: "100%"
    },
    content: {
        width: '100%'
    }
}));

const DividerWithText = ({ children, ...props }) => {
    const classes = useStyles();
    return (
        <div className={classes.container}>
            <div className={classes.border} />
            <div {...props} className={classes.content}>{children}</div>
            <div className={classes.border} />
        </div>
    );
};
export default DividerWithText;