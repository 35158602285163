import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Switch from "@material-ui/core/Switch";
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { green } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import { Field, FieldArray, Form, Formik } from "formik";
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { AuthContext } from "../../context/Auth/AuthContext";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import TagCheckList from '../Tag/tagCheckList';
import JsonParse from '../../helpers/JsonParse';
import { format } from 'date-fns';
import { pt } from 'date-fns/locale';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
	textField: {
		marginRight: theme.spacing(1),
		flex: 1,
	},

	extraAttr: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},

	btnWrapper: {
		position: "relative",
	},

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	select: {
		padding: 0,
		"& ul": {
			padding: 5,
		},
		"& li": {
			padding: 5,
			fontSize: 12,
		},
	},
	tab: {
		backgroundColor: "transparent",
		color: "#000",
		boxShadow: "none"
	}
}));


const ContactSchema = Yup.object().shape({
	name: Yup.string().required("Name is required"),
	number: Yup.string()
		.required("Number is required")
		.matches(/^\d+$/, "Invalid number format. Only numbers is allowed."),
});

const ContactModal = ({ open, onClose, contactId, initialValues, onSave, byContactPage = true, tags }) => {
	const classes = useStyles();
	const isMounted = useRef(true);
	const [whatsapp, setWhatsapp] = useState();
	const { user: loggedInUser } = useContext(AuthContext);
	const initialState = {
		adminId: loggedInUser.adminId,
		name: "",
		number: "",
		email: "",
		forAll: true,
		rg: "",
		empresa: "",
		cpf: "",
		connection: "",
		nasc: "",
		endereco: "",
		extraInfo: [],
		queueId: ""
	};
	const [contact, setContact] = useState(initialState);
	const [queues, setQueues] = useState([]); // filas sem filtro buscadas na base
	const [isStartTicket, setIsStartTicket] = useState(false);
	const [tabValue, setTabValue] = React.useState(0); // para abas do modal
	const [selectedTags, setSelectedTags] = useState([]);
	const [openConfirm, setOpenConfirm] = useState(false);

	const history = useHistory();

	useEffect(() => {
		return () => {
			isMounted.current = false;
		};
	}, []);

	useEffect(() => {
		const fetchSession = async () => {
			try {
				let { data } = await api.get("/whatsapp/");
				data.map(obj => { delete obj.Chatbots; return obj; });
				setWhatsapp(JSON.stringify(data))
			} catch (err) {
				console.error({err});
				toastError(err);
			}
		};
		fetchSession();
	}, []);

	useEffect(() => {
		const fetchContact = async () => {
			await getQueues();
			if (initialValues) {
				setContact(prevState => {
					return { ...prevState, ...initialValues };
				});
			}

			if (!contactId) return;

			try {
				const { data } = await api.get(`/contacts/${contactId}`);
				let rg = '';
				let empresa = '';
				let cpf = '';
				let nasc = '';
				let endereco = '';

				// mapear campos extras dos contatos
				if (data.extraInfo) {
					data.extraInfo.map(item => {
						switch (item.name) {
							case 'rg':
								rg = item.value;
								break;
							case 'cpf':
								cpf = item.value;
								break;
							case 'empresa':
								empresa = item.value;
								break;
							case 'nasc':
								nasc = item.value;
								break;
							case 'endereco':
								endereco = item.value;
								break;
						}
					});
				}

				const lgpdDate = data.LGPDAt ? format(new Date(data.LGPDAt), "dd/MM/yyyy HH:mm", { locale: pt }) : '';

				var dados = {
					"id": data.id,
					"adminId": data.adminId,
					"name": data.name,
					"number": data.number,
					"email": data.email,
					"rg": rg,
					"empresa": empresa,
					"cpf": cpf,
					"nasc": nasc,
					"endereco": endereco,
					"forAll": data.forAll,
					"createdBy": data.createdBy,
					"connection": data.connection,
					"profilePicUrl": data.profilePicUrl,
					"isGroup": data.isGroup,
					"createdAt": data.createdAt,
					"updatedAt": data.updatedAt,
					"aceiteLGPD": data.acceptLGPD,
					"dataAceiteLGPD": lgpdDate,
					"extraInfo": [
						{
							"name": "rg",
							"value": rg
						},
						{

							"name": "empresa",
							"value": empresa,

						},
						{
							"name": "cpf",
							"value": cpf
						},
						{
							"name": "nasc",
							"value": nasc
						},
						{
							"name": "endereco",
							"value": endereco
						}
					],
				}
				// liberar filas permitidas e a preencher
				if (whatsapp && data.connection && whatsapp.length > 0) {
					let conn_id = null;
					let connections_tmp = JsonParse(whatsapp);
					for (let cont = 0; cont < connections_tmp.length; cont++) {
						if (connections_tmp[cont]['numberConnection'] == data.connection) {
							conn_id = connections_tmp[cont]['id'];
							break;
						}
					}
				}
				setContact(dados);
				// selecionar etiquetas do contato
				if (data.tagsId) {
					try {
						let tagsParse = JsonParse(data.tagsId);
						if (!tagsParse || tagsParse.length < 1) throw new Error('Etiquetas não identificadas');
						setSelectedTags(tagsParse);
					} catch (err) {
						console.warn(err);
					}
				}
			} catch (err) {
				console.error({err});
				toastError(err);
			}
		};

		fetchContact();
	}, [contactId, open, initialValues]);

	const handleClose = () => {
		onClose();
		setContact(initialState);
		setQueues([]);
		setSelectedTags([]);
		setTabValue(0);
	};

	const handleSaveContact = async values => {
		try {
			if (contactId) {
				await api.put(`/contacts/${contactId}`, values);
				handleClose();
			} else {

				values.createdBy = loggedInUser.id;  // Pegar o id do usuario que criou este contato
				const { data } = await api.post("/contacts", values);

				if (data?.uuid) history.push(`/tickets/${data.uuid}`);
				if (onSave) onSave(data);

				handleClose();

			}
			toast.success(i18n.t("contactModal.success"));
		} catch (err) {
			console.error({err});
			toastError(err);
		}
	};

	function renderItemConnection(conexao) {
		if (!conexao.numberConnection) {
			return
		}
		return <MenuItem
			value={conexao.numberConnection}
			data-id={conexao.id}
		>
			{conexao.name} - {conexao.numberConnection}
		</MenuItem>
	}

	function RenderSelectConnection(Connections) {
		var i = 0;
		var rows = [];
		for (var conexao in Connections) {
			rows.push(renderItemConnection(Connections[conexao]));
		}
		return rows;
	}

	// função para resgatar as filas e suas conexões
	async function getQueues() {
		// resetar lista
		setQueues([]);
		// resgatar lista de filas da base de dados
		const resp = await api.get(`/queueListWithConnections/`);
		let response_tmp = resp.data;
		if (typeof response_tmp == 'undefined' || response_tmp == 'null' || response_tmp == '' || response_tmp == null || response_tmp.length == 0 || resp.status !== 200) {
			return toast.error('Erro ao resgatar filas, contate o administrador.');
		}
		// se o whatsappId não for nulo, percorrer retorno retirando as filas sem whatsappId, pois não estavam no filtro
		setQueues(response_tmp);
		return;
	}
	
	function TabPanel(props) {
		const { children, value, index, ...other } = props;

		return (
			<div
				role="tabpanel"
				hidden={value !== index}
				id={`simple-tabpanel-${index}`}
				aria-labelledby={`simple-tab-${index}`}
				{...other}
			>
				{value === index && (
					<Box p={3}>
						<Typography>{children}</Typography>
					</Box>
				)}
			</div>
		);
	}

	function a11yProps(index) {
		return {
			id: `simple-tab-${index}`,
			'aria-controls': `simple-tabpanel-${index}`,
		};
	}

	const handleTabChange = (event, newValue) => {
		setTabValue(newValue);
	};

	const handleTagChange = useCallback((tagId, isChecked) => {
		setSelectedTags((prev) =>
			isChecked ? [...prev, tagId] : prev.filter(id => id !== tagId)
		);
	}, []);

	const handleDeleteClick = () => {
		setOpenConfirm(true);
	};

	const handleCloseConfirm = () => {
		setOpenConfirm(false);
	};

	const handleConfirmDelete = async values => {
		try {
			if (contactId) {
				// Lógica para excluir a data de aceite LGPD
				await api.put(`/contacts/lgpd/${contactId}`, {});
				//toast.success(i18n.t("contactModal.success"));
			}
			handleClose();
			handleCloseConfirm();
		} catch (err) {
			console.error({err});
			toastError(err);
		}
	};

	return (
		<div className={classes.root}>
			<Dialog open={open} onClose={handleClose} maxWidth="lg" scroll="paper" style={{ width: '100%' }}>
				<DialogTitle id="form-dialog-title">
					{contactId
						? `${i18n.t("contactModal.title.edit")}`
						: `${i18n.t("contactModal.title.add")}`}
				</DialogTitle>
				<Formik
					initialValues={contact}
					enableReinitialize={true}
					validationSchema={ContactSchema}
					onSubmit={(values, actions, event) => {
						setTimeout(() => {
							let rg = values.rg;
							let empresa = values.empresa;
							let cpf = values.cpf;
							let nasc = values.nasc;
							let endereco = values.endereco;
							values.number = values.number.replace(/\D/g, "");

							let dados = {
								"id": values.id,
								"adminId": values.adminId,
								"queueId": null,
								"tagsId": JSON.stringify(selectedTags),
								"name": values.name,
								"number": values.number,
								"email": values.email,
								"forAll": values.forAll,
								"connection": values.connection,
								"profilePicUrl": values.profilePicUrl,
								"isGroup": values.isGroup,
								"createdAt": values.createdAt,
								"updatedAt": values.updatedAt,
								"isStartTicket": isStartTicket,
								"extraInfo": [
									{
										"name": "rg",
										"value": rg,
										"contactId": values.id
									},
									{

										"name": "empresa",
										"value": empresa,
										"contactId": values.id
									},
									{

										"name": "cpf",
										"value": cpf,
										"contactId": values.id
									},
									{

										"name": "nasc",
										"value": nasc,
										"contactId": values.id
									},
									{

										"name": "endereco",
										"value": endereco,
										"contactId": values.id
									}
								],
							}
							handleSaveContact(dados);
							actions.setSubmitting(false);
						}, 400);
					}}
				>

					{({ values, errors, touched, isSubmitting }) => {

						return ((
							<Form style={{ padding: "10px", maxWidth: '600px', overflowY: 'hidden' }}>
								<AppBar position="static" className={classes.tab}>
									<Tabs value={tabValue} onChange={handleTabChange} aria-label="simple tabs" TabIndicatorProps={{ style: { background: '#0b708c' } }}>
										<Tab label={i18n.t("contactModal.form.mainInfo")}
											{...a11yProps(0)}
										/>
										{tags && <Tab label={i18n.t("tagForContactsModal.title")} {...a11yProps(1)} />}
									</Tabs>
								</AppBar>

								<DialogContent dividers style={{ minWidth: '580px', paddingLeft: '0', paddingRight: '0' }}>
									<Box component="div" style={{ maxHeight: '60vh', overflowY: 'auto' }}>
										<TabPanel value={tabValue} index={0}>
											<div className="form-row">
												<Field
													as={TextField}
													type="hidden"
													name="adminId"
												/>
												<div class="form-group col-md-12">
													<label>
														{i18n.t("contacts.form.name")}
														<Typography component="span" style={{ marginLeft: '5px' }} color="secondary">*</Typography>
													</label>
													<Field
														style={{
															borderColor: '#c4c4c4',
															borderRadius: '3px'
														}}
														className='form-control '
														placeholder={i18n.t("contactModal.form.name")}
														name="name"
														autoFocus
														required
														disabled={contactId && loggedInUser.profile === "user" && loggedInUser.id != values.createdBy ? true : false}
														maxLength={200}
													/>
												</div>

												<div class="form-group col-md-6">
													<label>
														{i18n.t("contacts.form.phoneNumber")}
														<Typography component="span" style={{ marginLeft: '5px' }} color="secondary">*</Typography>
													</label>
													<Field
														style={{
															borderColor: '#c4c4c4',
															borderRadius: '3px'
														}}
														required
														disabled={contactId && loggedInUser.profile === "user" && loggedInUser.id != values.createdBy ? true : false}
														className='form-control '
														name="number"
														placeholder="Cod. País + Cod. Estado + número"
														onBlur={(event) => {
															values.number = values.number.replace(/\D/g, "");
															event.target.value = values.number;
														}}
														maxLength={50}
													/>
												</div>

												<div class="form-group col-md-6">
													<label style={{ marginBottom: '11px' }}>
														{i18n.t("contacts.form.connection")}
														<Typography component="span" style={{ marginLeft: '5px' }} color="secondary">*</Typography>
													</label>
													<Field
														variant="outlined"
														as={Select}
														required
														disabled={contactId && loggedInUser.profile === "user" && loggedInUser.id != values.createdBy ? true : false}
														name="connection"
														style={{ width: '100%', height: '38px' }}
														MenuProps={{ classes: { paper: classes.select } }}
													>
														{RenderSelectConnection(JsonParse(whatsapp))}
													</Field>
												</div>

												<div class="form-group col-md-8">
													<label>{i18n.t("contacts.form.email")}</label>
													<Field
														style={{
															borderColor: '#c4c4c4',
															borderRadius: '3px'
														}}

														className='form-control '
														name="email"
														placeholder="Email do Contato"
														maxLength={200}
														pattern="[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)+(\.[a-zA-Z]{2,})?"
														/>
												</div>
												<FieldArray name="extraInfo">
													{
														<>
															<div class="form-group col-md-4">
																<label>{i18n.t("contacts.form.company")}</label>
																<Field
																	style={{
																		borderColor: '#c4c4c4',
																		borderRadius: '3px'
																	}}
																	className='form-control '
																	name="empresa"
																	placeholder="Empresa"
																	type="text" 
																	maxLength={100}
																/>
															</div>

															<div class="form-group col-md-8">
																<label>{i18n.t("contacts.form.address")}</label>
																<Field
																	style={{
																		borderColor: '#c4c4c4',
																		borderRadius: '3px'
																	}}
																	className='form-control '
																	name="endereco"
																	placeholder="Endereco"
																	type="text" 
																	maxLength={200}
																/>
															</div>

															<div class="form-group col-md-4">
																<label>{i18n.t("contacts.form.birth")}</label>
																<Field
																	mask="99/99/9999"
																	style={{
																		borderColor: '#c4c4c4',
																		borderRadius: '3px'
																	}}
																	className='form-control '
																	name="nasc"
																	placeholder="Data de Nascimento"
																	type="text" 
																	maxLength={50}
																/>
															</div>

															<div class="form-group col-md-8">
																<label>{i18n.t("contacts.form.rg")}</label>
																<Field
																	mask="99.999.999-9"
																	style={{
																		borderColor: '#c4c4c4',
																		borderRadius: '3px'
																	}}
																	className='form-control '
																	name="rg"
																	placeholder="RG"
																	type="text" 
																	maxLength={50}
																/>
															</div>

															<div class="form-group col-md-4">
																<label>{i18n.t("contacts.form.cpf")}</label>
																<Field
																	mask="999.999.999-99"
																	style={{
																		borderColor: '#c4c4c4',
																		borderRadius: '3px'
																	}}
																	className='form-control '
																	name="cpf"
																	placeholder="CPF"
																	type="text"
																	maxLength={15}
																/>
															</div>
																	
															{contactId && (
																<div className="form-group col-md-6">
																	<label>{i18n.t("contacts.form.lgpdAt")}</label>
																	<div style={{ display: 'flex', alignItems: 'center' }}>
																		<Field
																			style={{
																				borderColor: '#c4c4c4',
																				borderRadius: '3px',
																				margin: '5px 0',
																				flex: 1 // Permite que o input ocupe o espaço disponível
																			}}
																			className='form-control'
																			name="dataAceiteLGPD"
																			placeholder="Data aceite LGPD"
																			type="text"
																			disabled={true}
																		/>
																		{contact.dataAceiteLGPD && (
																			<Tooltip title="Excluir aceite LGPD">
																				<IconButton onClick={handleDeleteClick} style={{ marginLeft: '5px' }}>
																					<DeleteIcon />
																				</IconButton>
																			</Tooltip>
																		)}
																	</div>
																</div>
															)}
															
															<div class="form-group col-md-12">
																<label style={{ marginTop: '40px' }}>{i18n.t("contacts.form.forAllUsers")}</label>
																<Field
																	as={Switch}
																	color="primary"
																	name="forAll"
																	checked={values.forAll}
																	disabled={loggedInUser.profile === "user" && loggedInUser.id != values.createdBy ? true : false}
																/>
															</div>
														</>
													}
												</FieldArray>
											</div>
										</TabPanel>
										<TabPanel value={tabValue} index={1}>
											<TagCheckList tags={tags} selectedTags={selectedTags} onChange={handleTagChange} />
										</TabPanel>
									</Box>
								</DialogContent>

								<DialogActions>
									<Button
										onClick={handleClose}
										color="secondary"
										disabled={isSubmitting}
										variant="outlined"
									>
										{i18n.t("contactModal.buttons.cancel")}
									</Button>
									<Button
										type="submit"
										color="primary"
										disabled={isSubmitting}
										onClick={() => setIsStartTicket(false)}
										variant="contained"
										className={classes.btnWrapper}
									>
										{contactId
											? `${i18n.t("contactModal.buttons.okEdit")}`
											: `${i18n.t("contactModal.buttons.okAdd")}`}
										{isSubmitting && (
											<CircularProgress
												size={24}
												className={classes.buttonProgress}
											/>
										)}
									</Button>
									{(!contactId && byContactPage) && <Button
										type="submit"
										color="primary"
										disabled={isSubmitting}
										id="startTicket"
										onClick={() => setIsStartTicket(true)}
										variant="contained"
										className={classes.btnWrapper}
									>
										{i18n.t("contacts.buttons.startConversation")}
										{isSubmitting && (
											<CircularProgress
												size={24}
												className={classes.buttonProgress}
											/>
										)}
									</Button>}
								</DialogActions>

								{/* Modal de Confirmação */}
								<Dialog open={openConfirm} onClose={handleCloseConfirm}>
									<DialogTitle>Confirmar Exclusão</DialogTitle>
									<DialogContent>
										<p>
											Deseja realmente excluir a data de aceite LGPD do cliente?
											<br />
											Ao excluir, caso o cliente entre em contato futuramente, o fluxo LGPD será reapresentado para novo aceite.
										</p>
									</DialogContent>
									<DialogActions>
										<Button onClick={handleCloseConfirm} color="secondary">
											Cancelar
										</Button>
										<Button onClick={handleConfirmDelete} color="primary">
											Confirmar
										</Button>
									</DialogActions>
								</Dialog>
							</Form>
						))
					}}
				</Formik>
			</Dialog>
		</div >
	);
};

export default ContactModal;
