
import { Button, Card } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ArrowBackIos from "@material-ui/icons/ArrowBackIos";
import { useHistory } from "react-router-dom";
import TicketHeaderSkeleton from "../TicketHeaderSkeleton";

const useStyles = makeStyles((theme) => ({
  ticketHeader: {
    overflow: 'hidden',
    width: '100%',
    display: "flex",
    backgroundColor: "#eee",
    flex: "none",
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    overflowX: 'auto',
    [theme.breakpoints.down("sm")]: {
      flexWrap: "wrap",
    },
    '@media(min-width: 810px)': {
      height: "67px",
    }
  },
  closeTicket: {
    [theme.breakpoints.down('959')]: {
      display: 'inline-block',
      position: 'relative',
      height: '65px'
    },
    [theme.breakpoints.down('500')]: {
      width: '40px',
      minWidth: '40px'
    },
  },
}));

const TicketHeader = ({ loading, children }) => {
  const classes = useStyles();
  const history = useHistory();
  const handleBack = () => {
    history.push("/tickets");
  };

  return (
    <>
      {loading ? (
        <TicketHeaderSkeleton />
      ) : (
        <Card square className={classes.ticketHeader}>
          <Button 
            id="btnCloseTicketOnHeader"
            className={classes.closeTicket}
            color="primary" 
            onClick={handleBack} 
          >
            <ArrowBackIos />
          </Button>
          {children}
        </Card>
      )}
    </>
  );
};

export default TicketHeader;
