
import { format, parseISO } from "date-fns";
import React, { useEffect, useState } from "react";

import {
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    makeStyles,
    useTheme
} from "@material-ui/core";

import CloseIcon from '@material-ui/icons/Close';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import Paper from '@material-ui/core/Paper';
import CircularProgress from "@material-ui/core/CircularProgress";

import { green } from "@material-ui/core/colors";

import MarkdownWrapper from "../MarkdownWrapper";
// import NewEmojiPicker from "../NewEmojiPicker";

const useStyles = makeStyles((theme) => ({
    messagesList: {
        backgroundColor: '#efeae2',
        display: "flex",
        justifyContent: "center",
        flexGrow: 1,
        padding: "20px 20px 20px 20px",
        overflowY: "scroll",
        '@media (max-width: 600px)': {
            paddingBottom: "90px",
        },
        ...theme.scrollbarStyles,
    },
    textContentItem: {
        overflowWrap: "break-word",
        padding: "3px 80px 6px 6px",
    },
    messageRight: {
        fontSize: '13px',
        marginLeft: 20,
        marginTop: 2,
        minWidth: 100,
        maxWidth: 510,
        height: "auto",
        display: "block",
        position: "relative",
        whiteSpace: "pre-wrap",
        alignSelf: "flex-end",
        backgroundColor: '#dcf8c6',
        borderRadius: 8,
        paddingLeft: 5,
        paddingRight: 5,
        paddingTop: 5,
        paddingBottom: 0,
    },
    timestamp: {
        fontSize: 11,
        position: "absolute",
        bottom: 0,
        right: 5,
        color: "#999",
    },
}));

const EditMessageModal = ({ open, onClose, loading, onSave, message }) => {

    const classes = useStyles();
    const theme = useTheme();

    const [editedMessage, setEditedMessage] = useState(null);

    useEffect(() => {
        if (open) {
            setEditedMessage(message?.body);
        }
    }, [open, message]);

    const handleSave = () => {
        onSave(editedMessage)
    };

    if (!open) return null;

    return (
        <Dialog
            open={open}
            onClose={() => onClose(false)}
            aria-labelledby="edit-message-dialog"
        >
            <div style={{ minWidth: '500px' }}>
                <DialogTitle id="edit-message-dialog" style={{ padding: '10px', backgroundColor: theme.mode === 'dark' ? '#111b21' : '#f0f2f5' }}>
                    <Box style={{ display: 'flex', alignItems: 'center' }}>
                        <IconButton onClick={() => onClose(false)}> <CloseIcon /></IconButton>
                        <p style={{ margin: '0px !important' }}>Editar Mensagem</p>
                    </Box>
                </DialogTitle>
                <DialogContent style={{ padding: '0px' }}>
                    <Box>
                        <Box className={classes.messagesList}>
                            <Box component="div" className={`${classes.messageRight}`} style={{ fontStyle: 'italic' }}>
                                <Box className={classes.textContentItem}>
                                    <Box component="div" style={{ color: '#000' }}>
                                        <MarkdownWrapper>{message?.body}</MarkdownWrapper>
                                    </Box>
                                    <span className={classes.timestamp}>
                                        {format(parseISO(message?.updatedAt), "HH:mm")}
                                    </span>
                                </Box>
                            </Box>
                        </Box>
                        <Paper
                            component="form"
                            style={{
                                p: '2px 4px',
                                display: 'flex',
                                alignItems: 'center',
                                borderRadius: '0px',
                                backgroundColor: theme.mode === 'dark' ? '#111b21' : '#f0f2f5'
                            }}
                        >
                            <Box style={{
                                display: 'flex',
                                width: '100%',
                                backgroundColor: theme.mode === 'dark' ? '#212b36' : '#ffffff',
                                margin: '10px 0px 10px 20px',
                                borderRadius: '10px'
                            }}>
                                <InputBase
                                    style={{ padding: '15px 0px 15px 15px', flex: 1 }}
                                    multiline
                                    disabled={loading}
                                    maxRows={6}
                                    placeholder="Reescreva a mensagem"
                                    value={editedMessage}
                                    onChange={e => setEditedMessage(e.target.value)}
                                    inputProps={{ 'aria-label': 'search google maps' }}
                                />
                            </Box>
                            <IconButton disabled={loading} color="primary" onClick={() => handleSave()} aria-label="directions">
                                {loading ? (
                                    <CircularProgress
                                        size={24}
                                        style={{
                                            color: green[500],
                                            position: "absolute",
                                            top: "50%",
                                            left: "50%",
                                            marginTop: -12,
                                            marginLeft: -12
                                        }}
                                    />)
                                    :
                                    (
                                        <CheckCircleIcon style={{
                                            width: '35px',
                                            height: '35px'
                                        }} />
                                    )
                                }


                            </IconButton>
                        </Paper>
                    </Box>
                </DialogContent >
            </div>
        </Dialog >
    );
};

export default EditMessageModal;
