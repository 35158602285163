
import { Avatar, CardHeader, Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { i18n } from "../../translate/i18n";

const useStyles = makeStyles((theme) => ({
	headerInfo: {
		cursor: 'pointer',
		[theme.breakpoints.down('959')]: {
			width: 'fit-content',
			display: 'inline-flex',
		},
		[theme.breakpoints.down('500')]: {
			width: '60vw',
		},
		[theme.breakpoints.down('400')]: {
			width: '50vw',
		},
		"& span": {
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
		}
	},
	content: {
		width: '100%',
		display: 'grid'
	},
	subheader: {
		fontSize: '.85rem',
		paddingTop: '5px',
		"& span": {
			fontSize: '.85rem',
			padding: '0',
			margin: '0'
		}
	},
	signedTo: {
		position: 'relative',
		display: 'block',
	}
}));

const TicketInfo = ({ contact, ticket, onClick }) => {
	const classes = useStyles();

	return (
		<CardHeader
			onClick={onClick}
			className={classes.headerInfo}
			titleTypographyProps={{ noWrap: true }}
			subheaderTypographyProps={{ noWrap: true }}
			avatar={<Avatar src={contact.profilePicUrl} alt="contact_image" />}
			title={`${contact.isGroup ? i18n.t('groupPrefix') : ''}${contact.name} #${ticket.id}`}
			subheader={
				<Box className={classes.subheader}>
					{ticket.Queue && `Fila: ${ticket.Queue.name}`}
					{
						ticket.User &&
						<Typography component="span" className={classes.signedTo}>
							{`${i18n.t("messagesList.header.assignedTo")} ${ticket.User.name}`}
						</Typography>
					}
				</Box>
			}
			classes={{
				content: classes.content
			}}
		/>
	);
};

export default TicketInfo;
