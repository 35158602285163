// método para remover aspas dentro das aspas do JSON
const addQuotesAndRemoveInside = function (text) {
    return text.replace(/:(?!\{)(.*?)(,|\})/g, (match, p1, p2) => {
        // Remove as aspas duplas dentro de p1
        let cleanedText = p1.replace(/"/g, '');
        if (cleanedText[0] && cleanedText[0] === ' ') cleanedText = cleanedText.replace(/^\s/, '');
        // Adiciona aspas duplas antes e depois do texto
        return `:"${cleanedText}"${p2}`;
    });
}

const JsonParse = (str, replace = false) => {
    if (!str || String(str).trim == '') return null;
    if (typeof str !='string') return str;

    if (replace == true) {
        str = String(str).trim().replaceAll(/\r/g, '\\r').replaceAll(/\n/g, '\\n');
        str = addQuotesAndRemoveInside(str);
    } else {
        // Remover quebras de linha desnecessárias
        str = str.replaceAll(/\n/g, ' ');

        // Remover espaços extras
        str = str.replaceAll(/\s\s+/g, ' ');
    }

    try {
        return JSON.parse(str);
    } catch (err) {
        if (replace == false) return JsonParse(str, true);
        console.error({str, err});
        return null;
    }

}

export default JsonParse;