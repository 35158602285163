import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Tooltip, Typography } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from "@material-ui/core/styles";
import { CheckCircle, GetApp } from "@material-ui/icons";
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import { format } from "date-fns";
import React, { useState } from 'react';
import * as XLSX from 'xlsx';
import { KTSVG } from '../../../_metronic/helpers';
import { i18n } from "../../translate/i18n";
import ModalImageCors from "../ModalImageCors";

const useStyles = makeStyles(theme => ({
    tr: {
        height: "50px",
        "& td, th": {
            borderBottomColor: "#e4e6ef",
            borderBottomStyle: "dashed",
            borderBottomWidth: "1px"
        }
    },
    theader: {
        "& td, th": {
            height: "50px",
            color: "#a1a5b7",
            fontWeight: "800",
            fontSize: "1rem",
            borderBottomColor: "#e4e6ef",
            borderBottomStyle: "solid",
            borderBottomWidth: "1px"
        }
    },
    iconButton: {
        background: "#f5f8fa",
        padding: "5px",
        borderRadius: "2px",
        margin: "0 2px!important",
        "& :hover": {
            color: "rgb(0, 168, 132)",
            cursor: "pointer"
        }
    },
    dialog: {
        "& .MuiDialog-paper": {
            width: "500px",
            height: "max-content",
            minHeight: "200px",
        }
    },
    messageMedia: {
        objectFit: "cover",
        width: 250,
        height: 200,
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        borderBottomLeftRadius: 8,
        borderBottomRightRadius: 8,
        marginBottom: "20px"
    },
    downloadMedia: {
        width: "100%",
        padding: "5px 0px",
        textTransform: "lowercase",
        fontSize: "13px",
        minWidth: "200px",
        marginBottom: "20px",
        "& .MuiButton-label": {
          position: "relative",
          padding: "10px 40px 0 30px",
          display: "inline-block",
          textOverflow: "ellipsis",
          wordWrap: "initial",
          whiteSpace: "nowrap", 
          overflow: "hidden",
          height: "40px",
          lineHeight: "18px",
          "& .MuiButton-startIcon": {
            position: "absolute",
            top: "7px",
            left: "10px"
          },
          "& .MuiButton-endIcon": {
            padding: "3px",
            border: "1px solid #9e9e9e",
            borderRadius: "50%",
            width: "30px",
            height: "30px",
            textAlign: "center",
            position: "absolute",
            right: "10px",
            top: "2px",
            color: "#9e9e9e"
          }
        },
        "&:hover": {
          backgroundColor: "#0b708c",
          color: "#ffffff",
          "& .MuiButton-endIcon": {
            color: "#ffffff!important",
            borderColor: "#ffffff!important"
          }
        }
      },
}));

// constante para identificar arquivos renderizaveis
const mimeTypes = [
    { "extension": ".jpg", "typeArchive": "image/jpeg" },
    { "extension": ".png", "typeArchive": "image/png" },
    { "extension": ".mp3", "typeArchive": "audio/mp3" },
    { "extension": ".mp3", "typeArchive": "audio/mpeg" },
    { "extension": ".oga", "typeArchive": "audio/ogg" },
    { "extension": ".opus", "typeArchive": "audio/opus" },
    { "extension": ".avi", "typeArchive": "video/x-msvideo" },
    { "extension": ".mp4", "typeArchive": "video/mp4" },
    { "extension": ".mpeg", "typeArchive": "video/mpeg" },
    { "extension": ".svg", "typeArchive": "image/svg+xml" },
    { "extension": ".webp", "typeArchive": "image/webp" },
    { "extension": ".avif", "typeArchive": "image/avif" },
    { "extension": ".bmp", "typeArchive": "image/bmp" },
    { "extension": ".gif", "typeArchive": "image/gif" },
    { "extension": ".ico", "typeArchive": "image/vnd.microsoft.icon" },
    { "extension": ".tiff", "typeArchive": "image/tiff" },
    { "extension": ".aac", "typeArchive": "audio/aac" },
    { "extension": ".mid", "typeArchive": "audio/midi" },
    { "extension": ".mid", "typeArchive": "audio/x-midi" },
    { "extension": ".wav", "typeArchive": "audio/wav" },
    { "extension": ".weba", "typeArchive": "audio/webm" },
    { "extension": ".3gp", "typeArchive": "audio/3gpp" },
    { "extension": ".3g2", "typeArchive": "audio/3gpp2" },
    { "extension": ".ogv", "typeArchive": "video/ogg" },
    { "extension": ".ts", "typeArchive": "video/mp2t" },
    { "extension": ".webm", "typeArchive": "video/webm" },
    { "extension": ".3gp", "typeArchive": "video/3gpp" },
    { "extension": ".3g2", "typeArchive": "video/3gpp2" }
];

const TableRelatory = ({ data }) => {

    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [dialogInfo, setDialogInfo] = useState({ name: '', message: '', driveUrl: '', isDrive: '', mediaName: '' })

    const renderStatus = (status, campaignStatus) => {
        return (campaignStatus && (campaignStatus == 'paused' && status == 'started')) ? campaignStatus : status;
    }

    const export_excel = () => {
        var campaigns = [];
    
        if (data && Array.isArray(data)) {
            data.forEach(row => {
                let obj = {};
                obj[i18n.t("relatoryGeneralCampaigns.history.table.name")] = row['name'];
                obj[i18n.t("relatoryGeneralCampaigns.history.table.status")] = i18n.t(`relatoryGeneralCampaigns.history.table.statusType.${renderStatus(row['status'], row['campaignStatus'])}`);
                obj[i18n.t("relatoryGeneralCampaigns.history.table.createdAt")] = row['createdAt'];
                obj[i18n.t("relatoryGeneralCampaigns.history.table.begin")] = row['begin'];
                obj[i18n.t("relatoryGeneralCampaigns.history.table.end")] = row['end'];
                obj[i18n.t("relatoryGeneralCampaigns.history.table.holiday")] = row['holiday'] ? 'y' : 'n';
                obj[i18n.t("relatoryGeneralCampaigns.history.table.sentQty")] = row['sentQty'];
                obj[i18n.t("relatoryGeneralCampaigns.history.table.notSentQty")] = row['notSentQty'];
                obj[i18n.t("relatoryGeneralCampaigns.history.table.errorQty")] = row['errorQty'];
                obj[i18n.t("relatoryGeneralCampaigns.history.table.totQty")] = row['totQty'];
                campaigns.push(obj);
            });
        }
        const ws = XLSX.utils.json_to_sheet(campaigns)
        const wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, ws, 'Responses')
        XLSX.writeFile(wb, `${i18n.t("relatoryGeneralCampaigns.history.table.relatoryTitle")}_${format((new Date()), "dd/MM/yyyy_hh:ii")}.xlsx`)
    }

    const handleClickOpen = (row) => {
        setDialogInfo({
            name: row['name'] || '',
            message: row['message'] || '',
            driveUrl: row['driveUrl'] || '',
            isDrive: row['isDrive'] || '',
            mediaName: row['mediaName'] || ''
        })
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const renderFiles = (url, mediaName) => {
        let extension = url.split('.').pop();
        if (extension.substring(0, 1) != '.') extension = '.' + extension;
        let mediaType = '';
        for (let cont = 0; cont < mimeTypes.length; cont++) {
            if (mimeTypes[cont]['extension'] == extension) {
                mediaType = mimeTypes[cont]['typeArchive'];
                break;
            }
        }
        if (mediaType != '') mediaType = mediaType.split('/')[0];

        if (mediaType === "image") {
            return (
                <Box style={{background: "#eeeeee", padding: "5px", borderRadius: "5px", marginBottom: "20px"}}>
                    <ModalImageCors
                        imageUrl={url || ""}
                    />
                </Box>
            );
        }
        if (mediaType === "audio") {
            return (
                <audio controls style={{ marginBottom: "20px" }}>
                    <source
                        src={url || ""}
                        type="audio/ogg">
                    </source>
                </audio>
            );
        }

        if (mediaType === "videoMessage" || mediaType === "video") {
            return (
                <video
                    className={classes.messageMedia}
                    src={url || ""}
                    controls
                />
            );
        } else {
            return (
                <>
                    <div>
                        <Tooltip
                            arrow
                            title={<Typography fontSize={20}>Download</Typography>}
                        >
                            <Button
                                startIcon={<InsertDriveFileIcon />}
                                endIcon={<GetApp />}
                                color="primary"
                                variant="outlined"
                                target="_blank"
                                className={classes.downloadMedia}
                                href={url || ""}
                            >
                                {mediaName || 'Download'}
                            </Button>
                        </Tooltip>
                    </div>
                </>
            );
        }
    }

    return (
        <React.Fragment>
            <Button
                aria-controls="customized-menu"
                aria-haspopup="true"
                variant="contained"
                color="primary"
                style={{ float: "right" }}
                onClick={e => export_excel(e)}
            >
                {i18n.t("relatoryGeneralCampaigns.history.buttons.export")}
            </Button>
            <TableContainer >
                <Table size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow className={classes.theader}>
                            <TableCell>{i18n.t("relatoryGeneralCampaigns.history.table.name")}</TableCell>
                            <TableCell align="right">{i18n.t("relatoryGeneralCampaigns.history.table.status")}</TableCell>
                            <TableCell align="right">{i18n.t("relatoryGeneralCampaigns.history.table.createdAt")}</TableCell>
                            <TableCell align="right">{i18n.t("relatoryGeneralCampaigns.history.table.begin")}</TableCell>
                            <TableCell align="right">{i18n.t("relatoryGeneralCampaigns.history.table.end")}</TableCell>
                            <TableCell align="right">{i18n.t("relatoryGeneralCampaigns.history.table.holiday")}</TableCell>
                            <TableCell align="right">{i18n.t("relatoryGeneralCampaigns.history.table.sentQty")}</TableCell>
                            <TableCell align="right">{i18n.t("relatoryGeneralCampaigns.history.table.notSentQty")}</TableCell>
                            <TableCell align="right">{i18n.t("relatoryGeneralCampaigns.history.table.errorQty")}</TableCell>
                            <TableCell align="right">{i18n.t("relatoryGeneralCampaigns.history.table.totQty")}</TableCell>
                            <TableCell align="right">{i18n.t("relatoryGeneralCampaigns.history.table.action")}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(data && Array.isArray(data)) && data.map((row) => {
                            return (
                                <TableRow key={`rgc${row["id"]}`} className={classes.tr}>
                                    <TableCell component="th" scope="row">{row['name']}</TableCell>
                                    <TableCell align="right">{i18n.t(`relatoryGeneralCampaigns.history.table.statusType.${renderStatus(row['status'], row['campaignStatus'])}`)}</TableCell>
                                    <TableCell align="right">{row['createdAt']}</TableCell>
                                    <TableCell align="right">{row['begin']}</TableCell>
                                    <TableCell align="right">{row['end']}</TableCell>
                                    <TableCell align="right">
                                        {row['holiday'] && (
                                            <CheckCircle style={{ color: "#4caf50" }} />
                                        )}
                                    </TableCell>
                                    <TableCell align="right">{row['sentQty']}</TableCell>
                                    <TableCell align="right">{row['notSentQty']}</TableCell>
                                    <TableCell align="right">{row['errorQty']}</TableCell>
                                    <TableCell align="right">{row['totQty']}</TableCell>
                                    <TableCell align="right">
                                        <Typography
                                            component="a"
                                            title={i18n.t("relatoryGeneralCampaigns.history.buttons.relatory")}
                                            // onClick={() => { history.push(`/relatory/${row['id']}`); }}
                                            href={`/relatory/${row['id']}`}
                                            className={classes.iconButton}
                                        >
                                            <KTSVG path='/media/icons/duotune/general/gen032.svg' className='svg-icon-3' />
                                        </Typography>
                                        <Typography
                                            component="span"
                                            title={i18n.t("relatoryGeneralCampaigns.history.buttons.message")}
                                            onClick={() => { handleClickOpen(row) }}
                                            className={classes.iconButton}
                                        >
                                            <KTSVG path='/media/icons/duotune/communication/com012.svg' className='svg-icon-3' />
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="message-dialog"
                aria-describedby="message-dialog-description"
                className={classes.dialog}
            >
                <DialogTitle id="message-dialog">{dialogInfo?.name || 'campaign name'}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        { (dialogInfo?.isDrive && dialogInfo.mediaName && dialogInfo?.driveUrl) && renderFiles(dialogInfo.driveUrl, dialogInfo.mediaName) }
                        <Typography component="p">{dialogInfo?.message || ''}</Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="secondary">{i18n.t("relatoryGeneralCampaigns.history.buttons.close")}</Button>
                </DialogActions>
            </Dialog >
        </React.Fragment>
    );
}

export default TableRelatory;