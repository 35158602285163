import { Box, Button, Grid, TextField } from "@mui/material";
import { useState } from "react";
import * as Yup from "yup";
import toastError from "../../../errors/toastError";
import { i18n } from "../../../translate/i18n";

export default function IntegrationCaptureTab({ integrationData, setIntegrationData }) {
    const maxCaptures = 10;
    const initialState = { key: '', message: '' };
    const [capture, setCapture] = useState(initialState);

    const CheckCaptureSchema = Yup.object().shape({
        key: Yup.string()
            .required(i18n.t(`integrations.webhook.form.customFields.key.required`))
            .min(2, i18n.t(`integrations.webhook.form.customFields.key.min`)),
        message: Yup.string()
            .required(i18n.t(`integrations.webhook.form.customFields.message.required`))
            .min(1, i18n.t(`integrations.webhook.form.customFields.message.min`)),
    });
    // reordenar para seguir sequencia correta
    if (CheckCaptureSchema['_nodes']) CheckCaptureSchema['_nodes'] = CheckCaptureSchema['_nodes'].reverse();

    const add = async () => {
        // validações
        try {
            await CheckCaptureSchema.validate(capture);
        } catch (err) {
            console.error({err});
            toastError({ response: { data: { error: err?.message || 'INTERNAL_ERROR' } } });
            return;
        }

        let captureTmp = Array.isArray(integrationData.captures) ? integrationData.captures : [];
        // verificar se já foi inserido
        if (captureTmp.findIndex((h) => String(h.key) == String(capture.key)) != -1) {
            toastError({ response: { data: { error: i18n.t(`integrations.webhook.form.customFields.key.added`) } } });
            return;
        }

        captureTmp.unshift(capture);
        setIntegrationData({ ...integrationData, captures: captureTmp });
        setCapture(initialState);
    }

    return (
        <>
            <Grid container spacing={2} style={{ position: "relative" }}>
                <Grid item xs={12} md={5} mb={2}>
                    <TextField
                        margin="dense"
                        id="keyCapture"
                        label={i18n.t(`integrations.webhook.form.customFields.key.title`)}
                        type="text"
                        fullWidth
                        variant="standard"
                        maxLength={50}
                        value={capture.key}
                        disabled={integrationData.captures?.length == maxCaptures}
                        onChange={(e) => { setCapture({ ...capture, key: e.target.value }) }}
                    />
                </Grid>
                <Grid item xs={12} md={5} mb={2}>
                    <TextField
                        margin="dense"
                        id="valueCapture"
                        label={i18n.t(`integrations.webhook.form.customFields.message.title`)}
                        type="text"
                        fullWidth
                        variant="standard"
                        maxLength={255}
                        value={capture.message}
                        disabled={integrationData.captures?.length == maxCaptures}
                        onChange={(e) => { setCapture({ ...capture, message: e.target.value }) }}
                    />
                </Grid>
                <Grid item xs={12} md={2} mb={2}>
                    <Button
                        variant="contained"
                        color="primary"
                        style={{ width: '100%', top: '22px' }}
                        onClick={() => add()}
                        disabled={integrationData.captures?.length == maxCaptures}
                    >
                        {i18n.t('buttons.add')}
                    </Button>
                </Grid>
            </Grid>
            {
                (Array.isArray(integrationData.captures) && integrationData.captures.length > 0)
                &&
                <Box style={{ marginTop: '15px', paddingBottom: "10px" }}>
                    {integrationData.captures.map((h) => (
                        <Grid container spacing={2} mb={2} style={{ marginBottom: "10px" }} >
                            <Grid item xs={5} style={{ textIndent: '10px' }} >{h.key}</Grid>
                            <Grid item xs={5} >{h.message}</Grid>
                            <Grid item xs={2} style={{ position: 'relative' }}>
                                <Button
                                    variant="contained"
                                    color="error"
                                    style={{ width: '100%' }}
                                    onClick={() => {
                                        let captureTmp = integrationData.captures.filter((hd) => hd.key != h.key);
                                        setIntegrationData({ ...integrationData, captures: captureTmp });
                                    }}
                                >
                                    {i18n.t('buttons.delete')}
                                </Button>
                            </Grid>
                        </Grid>
                    ))}
                </Box>
            }
        </>
    );
}