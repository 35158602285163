import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import { green } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import { useEffect, useState } from "react";
import toastError from "../../errors/toastError";


import Select from "@material-ui/core/Select";

import api from "../../services/api";


import { toast } from "react-toastify";
import { i18n } from "../../translate/i18n";
import JsonParse from "../../helpers/JsonParse";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
	textField: {
		marginRight: theme.spacing(1),
		flex: 1,
	},

	extraAttr: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},

	btnWrapper: {
		position: "relative",
	},

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	select: {
		padding: 0,
		"& li": {
			padding: 5,
			fontSize: 12,
		},
	},
}));


const ConfirmationImportModal = ({ title, children, open, onClose, onConfirm, isDelete }) => {
	const classes = useStyles();
	const [whatsapp, setWhatsapp] = useState();
	const [connection, setConnection] = useState('');

	useEffect(() => {
		const fetchSession = async () => {
			try {
				const { data } = await api.get("/whatsapp/");
				setWhatsapp(JSON.stringify(data))
			} catch (err) {
				console.error({err});
				toastError(err);
			}
		};
		fetchSession();
	}, []);

	function renderItemConnection(conexao) {
		if (!conexao.numberConnection) {
			return
		}
		return <MenuItem 
					value={conexao.id}
				>
					{conexao.name} - {conexao.numberConnection}
				</MenuItem>
	}

	function RenderSelectConnection(Connections) {

		var rows = [];
		for (var conexao in Connections) {
			rows.push(renderItemConnection(Connections[conexao]));
		}

		return rows;
	}
	const SelectedConnection = (event) => {
		setConnection(event.target.value);
	};
	const VerifyConnection = () => {
		if (isDelete) {
			onClose(false);
			onConfirm()
		} else {
			if (!connection) return toast.error('Selecione uma conexão.');
			
			onClose(false);
			onConfirm(
				{
					"idConnection": connection,
				}
			);
		}
	}

	return (
		<Dialog
			open={open}
			onClose={() => onClose(false)}
			aria-labelledby="confirm-dialog"
		>
			<DialogTitle id="confirm-dialog">{title}</DialogTitle>
			<DialogContent dividers>
				<Typography>{children}</Typography>
			</DialogContent>
			{!isDelete &&
				<>
					{whatsapp &&
						<>
							<div class="form-group col-md-12" style={{ padding: '25px 25px 0px 25px', maxWidth: '330px' }}>
								<label style={{ marginBottom: '11px' }}>
									Importar para a conexão
									<span className="text-danger ml-2">*</span>
								</label>
								<Select
									variant="outlined"
									style={{ width: '100%', height: '38px' }}
									MenuProps={{ classes: { paper: classes.select } }}
									value={connection}
									onChange={SelectedConnection}
								>
									{RenderSelectConnection(JsonParse(whatsapp))}
								</Select>
							</div>
						</>
					}
				</>
			}
			<DialogActions>
				<Button
					variant="contained"
					onClick={() => onClose(false)}
					color="default"
				>
					{i18n.t("confirmationModal.buttons.cancel")}
				</Button>
				<Button
					variant="contained"
					onClick={() => { VerifyConnection() }}
					color="secondary"
				>
					{i18n.t("confirmationModal.buttons.confirm")}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default ConfirmationImportModal;
