import { Box, Checkbox, FormControlLabel, Grid } from "@mui/material";

const TagCheckList = ({ tags, selectedTags, onChange }) => {

    return (
        <>
            {(tags && tags.length > 0) && tags.map(tag => {
                return (
                    <Grid container spacing={2} key={`tag-row-${tag.id}`}>
                        <Grid item xs={12} mb={2} style={{ position: "relative" }}>
                            <Box component="div"
                                style={{
                                    backgroundColor: tag.color,
                                    padding: "10px",
                                    width: "50px",
                                    borderRadius: "30px",
                                    position: "absolute",
                                    right: "10px",
                                    top: "20px",
                                }}
                            ></Box>
                            <FormControlLabel
                                color="success"
                                style={{ margin: '0px' }}
                                control={
                                    <Checkbox
                                        checked={selectedTags.includes(tag.id)}
                                        onChange={(e) => onChange(tag.id, e.target.checked)}
                                        id={`tag${tag.id}`}
                                        name={`tag${tag.id}`}
                                        value={tag.id}
                                    />
                                }
                                label={tag.name}
                            />
                        </Grid>
                    </Grid>
                );
            })}
        </>
    );
}

export default TagCheckList;