import React, { useState, useContext, useMemo } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles, createTheme } from '@material-ui/core/styles';
import Container from "@material-ui/core/Container";
import { i18n } from "../../translate/i18n";
import { AuthContext } from "../../context/Auth/AuthContext";
import Logo from "../../assets/logo_login.png";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    // backgroundColor: theme.palette.secondary.main,
    borderRadius: 0,
    backgroundColor: "transparent",
    width: "200px", // Fix IE 11 issue.
    height: "150px", // Fix IE 11 issue.
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  logo: {
    width: "100%", // Fix IE 11 issue.
  },
}));

const Login = () => {

  const classes = useStyles();
  const [user, setUser] = useState({ email: "", password: "" });
  const { handleLogin } = useContext(AuthContext);

  const theme = useMemo(() => createTheme({
    palette: {
      type: 'light',
      primary: {
        light: '#0b708c',
        main: '#0b708c',
        dark: '#0b708c',
        contrastText: '#fff',
      },
      background: {
        paper: '#ffffff',
      },
      text: {
        primary: '#000000',
      },
    },
  }), []);

  const handleChangeInput = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const handlSubmit = (e) => {
    e.preventDefault();
    handleLogin(user);
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <img className={classes.logo} src={Logo} alt="WhatsAll"></img>
          {/* <LockOutlinedIcon /> */}
        </Avatar>
        <Typography component="h1" variant="h5">
          {i18n.t("login.title")}
        </Typography>
        <form className={classes.form} noValidate onSubmit={handlSubmit}>
          <input
            type="email"
            className='form-control form-control-lg form-control-solid'
            name="email"
            id="email"
            placeholder={i18n.t("login.form.email")}
            value={user.email}
            data-kt-search-element='input'
            onChange={handleChangeInput}
            style={{margin: '5px 0px 5px 0px'}}
          />
          <input
            type="password"
            className='form-control form-control-lg form-control-solid'
            name="password"
            id="password"
            placeholder={i18n.t("login.form.password")}
            value={user.password}
            data-kt-search-element='input'
            onChange={handleChangeInput}
            style={{margin: '5px 0px 5px 0px'}}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            {i18n.t("login.buttons.submit")}
          </Button>
          {/*<Grid container>
            <Grid item>
              <Link
                href="#"
                variant="body2"
                component={RouterLink}
                to="/signup"
              >
                {i18n.t("login.buttons.register")}
              </Link>
            </Grid>
          </Grid>*/}
        </form>
      </div>
      <Box mt={8}>{/* <Copyright /> */}</Box>
    </Container>
  );
};

export default Login;
