import { Box, Button, Grid, TextField } from "@mui/material";
import { useState } from "react";
import * as Yup from "yup";
import toastError from "../../../errors/toastError";
import { i18n } from "../../../translate/i18n";

export default function IntegrationFieldTab({ integrationData, setIntegrationData }) {
    const maxFields = 10;
    const initialState = { key: '', value: '' };
    const [field, setField] = useState(initialState);

    const CheckFieldSchema = Yup.object().shape({
        key: Yup.string()
            .required(i18n.t(`integrations.webhook.form.customFields.key.required`))
            .min(2, i18n.t(`integrations.webhook.form.customFields.key.min`)),
        value: Yup.string()
            .required(i18n.t(`integrations.webhook.form.customFields.value.required`))
            .min(1, i18n.t(`integrations.webhook.form.customFields.value.min`)),
    });
    // reordenar para seguir sequencia correta
    if (CheckFieldSchema['_nodes']) CheckFieldSchema['_nodes'] = CheckFieldSchema['_nodes'].reverse();

    const add = async () => {
        // validações
        try {
            await CheckFieldSchema.validate(field);
        } catch (err) {
            console.error({err});
            toastError({ response: { data: { error: err?.message || 'INTERNAL_ERROR' } } });
            return;
        }

        let fieldTmp = Array.isArray(integrationData.fields) ? integrationData.fields : [];
        // verificar se já foi inserido
        if (fieldTmp.findIndex((h) => String(h.key) == String(field.key)) != -1) {
            toastError({ response: { data: { error: i18n.t(`integrations.webhook.form.customFields.key.added`) } } });
            return;
        }

        fieldTmp.unshift(field);
        setIntegrationData({ ...integrationData, fields: fieldTmp });
        setField(initialState);
    }

    return (
        <>
            <Grid container spacing={2} style={{ position: "relative" }}>
                <Grid item xs={12} md={5} mb={2}>
                    <TextField
                        margin="dense"
                        id="keyHeader"
                        label={i18n.t(`integrations.webhook.form.customFields.key.title`)}
                        type="text"
                        fullWidth
                        variant="standard"
                        maxLength={50}
                        value={field.key}
                        disabled={integrationData.fields?.length == maxFields}
                        onChange={(e) => { setField({ ...field, key: e.target.value }) }}
                    />
                </Grid>
                <Grid item xs={12} md={5} mb={2}>
                    <TextField
                        margin="dense"
                        id="valueHeader"
                        label={i18n.t(`integrations.webhook.form.customFields.value.title`)}
                        type="text"
                        fullWidth
                        variant="standard"
                        maxLength={255}
                        value={field.value}
                        disabled={integrationData.fields?.length == maxFields}
                        onChange={(e) => { setField({ ...field, value: e.target.value }) }}
                    />
                </Grid>
                <Grid item xs={12} md={2} mb={2}>
                    <Button
                        variant="contained"
                        color="primary"
                        style={{ width: '100%', top: '22px' }}
                        onClick={() => add()}
                        disabled={integrationData.fields?.length == maxFields}
                    >
                        {i18n.t('buttons.add')}
                    </Button>
                </Grid>
            </Grid>
            {
                (Array.isArray(integrationData.fields) && integrationData.fields.length > 0)
                &&
                <Box style={{ marginTop: '15px', paddingBottom: "10px" }}>
                    {integrationData.fields.map((h) => (
                        <Grid container spacing={2} mb={2} style={{ marginBottom: "10px" }} >
                            <Grid item xs={5} style={{ textIndent: '10px' }} >{h.key}</Grid>
                            <Grid item xs={5} >{h.value}</Grid>
                            <Grid item xs={2} style={{ position: 'relative' }}>
                                <Button
                                    variant="contained"
                                    color="error"
                                    style={{ width: '100%' }}
                                    onClick={() => {
                                        let fieldTmp = integrationData.fields.filter((hd) => hd.key != h.key);
                                        setIntegrationData({ ...integrationData, fields: fieldTmp });
                                    }}
                                >
                                    {i18n.t('buttons.delete')}
                                </Button>
                            </Grid>
                        </Grid>
                    ))}
                </Box>
            }
        </>
    );
}