import { Chip, Grid, MenuItem, Select } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import Avatar from '@mui/material/Avatar';
import React, { useContext, useEffect, useReducer, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { AuthContext } from "../../context/Auth/AuthContext";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import ButtonWithSpinner from "../ButtonWithSpinner";
import './style.css';


const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const initialState = { selectedOptions: [] };

function reducer(state, action) {
	switch (action.type) {
		case "SET_SELECTED_OPTIONS":
			return { selectedOptions: action.payload.options };

		case "REMOVE_OPTION":
			return {
				selectedOptions: state.selectedOptions.filter(
					(option) => option.id !== action.payload.id
				)
			};
		default:
			throw new Error();
	}
}

const ListBroadcastModal = ({ modalOpen, onClose }) => {
	const [loading, setLoading] = useState(false);
	const [searchParam, setSearchParam] = useState("");
	const [whatsapps, setWhatsapps] = useState([]);
	const [broadcastName, setBroadcastName] = useState("");
	const [whatsappBroadcast, setWhatsappBroadcast] = useState("");
	const [contactsData, setOptions] = useState("");
	const { user } = useContext(AuthContext);
	const [state, dispatch] = useReducer(reducer, initialState);
	const [queues, setQueues] = useState([]); // filas sem filtro buscadas na base
	const [filteredQueues, setFilteredQueues] = useState([]); // filas filtradas para mostrar no select
	const [selectedQueue, setSelectedQueue] = useState('');
	const history = useHistory();

	useEffect(() => {
		if (!modalOpen) {
			setLoading(false);
			return;
		}
		setLoading(true);
		const delayDebounceFn = setTimeout(() => {
			const fetchContacts = async () => {
				try {
					const { data } = await api.get("contactsBroadcast", {
						params: { searchParam },
					});
					setOptions(data);
					setLoading(false);
				} catch (err) {
					console.error({err});
					setLoading(false);
					toastError(err);
				}
			};

			fetchContacts();
		}, 500);
		return () => clearTimeout(delayDebounceFn);
	}, [modalOpen]);

	const handleClose = (event, reason) => {
		if (reason && reason == "backdropClick") return;
		onClose(false);
	};

	// ----- Config do autocomplete ----- //

	const handleChange = (event, values) => {
		dispatch({ type: "SET_SELECTED_OPTIONS", payload: { options: values } });
	};

	const removeOption = (id) => {
		dispatch({ type: "REMOVE_OPTION", payload: { id: id } });
	};

	// ------- Filtro de contatos ------- //
	const filterContacts = (contacts) => {
		var options = contacts.map((option) => { const firstLetter = option.name[0].toUpperCase(); return { firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter, ...option, }; });
		options = options.map((option) => { return { 'name': option.name, 'number': option.number, 'id': option.id, 'profilePicUrl': option?.profilePicUrl, 'isGroup': option?.isGroup, 'whatsappName': option?.whatsappName } });
		return options;
	}

	const formatContact = (contact) => {

		let str = `${contact.isGroup ? i18n.t('groupPrefix') : ''}${contact.name}`;
		if (contact?.whatsappName) str += ` (${contact.whatsappName})`;
		const find = contact.name.replace(/[0-9]/g, '').length === 0;
		if (!find) str += ` - ${contact.number}`;
		return str;
	}

	useEffect(() => {
		const fetchSession = async () => {
			try {
				await getQueues();
				const { data } = await api.get("/whatsapp/");
				setWhatsapps(data)
			} catch (err) {
				console.error({err});
				toastError(err);
			}
		};
		fetchSession();
	}, []);

	const sendBroadcastList = () => {

		if (!whatsappBroadcast || !broadcastName || !selectedQueue || (state.selectedOptions.map(x => x.id)).length < 1) return toast.error(`Preencha todos os campos para cria uma lista de transmissão nova.`);
		var values = {
			'whatsappId': whatsappBroadcast,
			'name': broadcastName,
			'contacts': JSON.stringify(state.selectedOptions.map(x => x.id)),
			'queueId': selectedQueue
		};
		const fetchContacts = async () => {
			try {
				const {data} = await api.post("/broadcast", values);
				toast.success(`Lista de transmissão ${broadcastName} criada!`);
				onClose(true);
				 history.push(`/tickets/broadcast/${data.id}`);
			}
			catch (err) {
				console.error({err});
				toastError(err)
			}
		};
		fetchContacts();
	}


	function renderItemwhatsapp(whatsappCon) {
		let disabled = whatsappCon.status !== 'CONNECTED' || whatsappCon.option == 'receptive';
		let label = '';

		if (disabled && whatsappCon.option == 'receptive') label = `${whatsappCon.name} - Conexão apenas para atendimentos`;
		else label = `${whatsappCon.name} - ${whatsappCon.numberConnection && `(${whatsappCon.numberConnection}) - `}${whatsappCon.status === 'CONNECTED' ? 'Conectado' : 'Desconectado, escaneie o QRCODE.'}`;
		return <MenuItem 
					disabled={disabled} 
					value={whatsappCon.id}
				>
					{label}
				</MenuItem>
	}

	function RenderSelectwhatsapps(whatsapps) {
		var i = 0;
		var rows = [];
		for (var whatsapp in whatsapps) {
			rows.push(renderItemwhatsapp(whatsapps[whatsapp]));
		}
		return rows;
	}

	// função para resgatar as filas e suas conexões
	async function getQueues() {
		// resetar lista
		setQueues([]);
		// resgatar lista de filas da base de dados
		const resp = await api.get(`/queueListWithConnections/`);
		let response_tmp = resp.data;
		if (typeof response_tmp == 'undefined' || response_tmp == 'null' || response_tmp == '' || response_tmp == null || response_tmp.length == 0 || resp.status !== 200) {
			return toast.error('Erro ao resgatar filas, contate o administrador.');
		}
		// se o whatsappId não for nulo, percorrer retorno retirando as filas sem whatsappId, pois não estavam no filtro
		setQueues(response_tmp);
		return;
	}
	// função para filtrar filas de acordo com a conexão
	function filterQueues(whatsappId) {
		setFilteredQueues([]);
		setSelectedQueue('');
		if (!whatsappId || typeof whatsappId == 'undefined' || whatsappId == '' || whatsappId == null || whatsappId == 'null') {
			return toast.error('Erro ao resgatar filas localmente, contate o administrador.');
		}
		whatsappId = String(whatsappId);
		queues.map((item) => {
			// se a conexão bater com o parametro local, adicionar ao array
			if (item.whatsappConnections) {
				let whatsappIds = item.whatsappConnections.split(';');
				if (whatsappIds.indexOf(whatsappId) >= 0) {
					setFilteredQueues(oldFilteredQueues => [
						...oldFilteredQueues,
						{
							id: item.id, 
							name: item.name,
						}
					]);
				}
			}
		});
		return;
	}

	return (
		<>
			<Dialog open={modalOpen && contactsData} onClose={handleClose}>
				<DialogTitle id="form-dialog-title">
					Criar lista de transmissão
				</DialogTitle>
				<DialogContent dividers>
					<Grid style={{ minHeight: 200, width: 400 }} container spacing={2}>
						<div style={{ width: 400 }}>
							<div style={{ padding: 10 }}>
								<label>
									Nome da lista de transmissão
									<span className="text-danger ml-2">*</span>
								</label>
								<TextField onChange={e => setBroadcastName(e.target.value)} variant="outlined" fullWidth />
							</div>
							<div style={{ padding: 10 }}>
								<>
									<label>
										Whatsapp vinculado
										<span className="text-danger ml-2">*</span>
									</label>
									<Select
										onChange={(e) => {
											setWhatsappBroadcast(e.target.value);
											filterQueues(e.target.value);
										}}
										variant="outlined"
										name="whatsappSelected"
										required
										style={{ width: '100%' }}
									>
										{RenderSelectwhatsapps(whatsapps)}
									</Select>
								</>
							</div>
							<div style={{ padding: 10 }}>
								<>
									<label>
										Fila
										<span className="text-danger ml-2">*</span>
									</label>
									<Select
										// onChange={e => setWhatsappBroadcast(e.target.value)}
										variant="outlined"
										name="queueSelected"
										style={{ width: '100%' }}
										disabled={filteredQueues == null || filteredQueues.length == 0 ? true: false}
										value={selectedQueue}
                                        onChange={function(e) {
                                            setSelectedQueue(e.target.value);
                                        }}
									>
										{
                                            filteredQueues.map((item) => {
                                                return <MenuItem value={item.id}>{item.name}</MenuItem>
                                            })
                                        }
									</Select>
								</>
							</div>
							<div style={{ padding: 10 }}>
								<label>
									Pesquise contatos
									<span className="text-danger ml-2">*</span>
								</label>
								{!loading && contactsData && <>
									<Autocomplete
										multiple
										limitTags={2}
										options={filterContacts(contactsData)}
										disableCloseOnSelect
										getOptionLabel={(option) => formatContact(option)}
										value={state.selectedOptions}
										renderTags={(values) =>
											values.map((value) => (
												<Chip
													key={value.id}
													label={
														<div style={{ display: 'flex', alignItems: 'center' }}>
															<Avatar style={{ height: 25, marginRight: '10px', width: 25 }} src={value?.profilePicUrl} />
															{ value.name || '' }
														</div>
													}
													onDelete={() => {
														removeOption(value.id);
													}}
												/>
											))
										}
										getOptionSelected={(option, value) => option.id === value.id}
										renderOption={(option, { selected }) => (
											<React.Fragment>
												<Checkbox
													icon={icon}
													checkedIcon={checkedIcon}
													style={{ marginRight: 8 }}
													checked={selected}
												/>
												<div style={{ marginRight: '10px' }} ><Avatar style={{ height: 30, width: 30 }} src={option?.profilePicUrl} /></div>
												{formatContact(option)}
											</React.Fragment>
										)}
										onChange={handleChange}
										renderInput={(params) => (
											<TextField {...params} variant="outlined" />
										)}
									/>
								</>}
							</div>
						</div>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={handleClose}
						color="secondary"
						disabled={loading}
						variant="outlined"
					>
						{i18n.t("newTicketModal.buttons.cancel")}
					</Button>
					<ButtonWithSpinner
						variant="contained"
						type="button"
						disabled={(state.selectedOptions.length < 1) && (broadcastName.length < 3)}
						onClick={() => sendBroadcastList()}
						color="primary"
						loading={loading}
					>
						{i18n.t("newTicketModal.buttons.ok")}
					</ButtonWithSpinner>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default ListBroadcastModal;
