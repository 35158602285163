import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MenuItem from "@material-ui/core/MenuItem";
import xlsx from "json-as-xlsx";
import { useCallback, useContext, useEffect, useState } from "react";
import MultiProgress from "react-multi-progress";
import { toast } from "react-toastify";
import * as XLSX from 'xlsx';
import excelContacts from "../../assets/xlsx/download.xlsx";
import { i18n } from "../../translate/i18n";

import Checkbox from '@material-ui/core/Checkbox';
import LinearProgress from '@material-ui/core/LinearProgress';
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import toastError from "../../errors/toastError";
import api from "../../services/api";

import { Grid } from "@material-ui/core";
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from "@material-ui/core/Typography";
import { AuthContext } from "../../context/Auth/AuthContext";
import PhoneValidator from "../../helpers/PhoneValidator";
import TagCheckList from "../Tag/tagCheckList";

const useStyles = makeStyles(theme => ({
    linearColorPrimary: {
        backgroundColor: '#b2dfdb',
    },
    linearBarColorPrimary: {
        backgroundColor: '#00695c',
    },
    tab: {
        backgroundColor: "transparent",
        color: "#000",
        boxShadow: "none",
        marginTop: "15px"
    },
    lbl: {
        display: "block",
        marginBottom: "10px",
        "& span": {
            color: "#b71c1c",
            marginLeft: "10px"
        }
    }
}));

const ConfirmationModal = ({ open, onClose, onConfirm }) => {

    const classes = useStyles();

    const [isLoadingFile, setisLoadingFile] = useState(false);

    const [objectContacts, setObjectContacts] = useState([]);
    const [contacts, setContacts] = useState([]);
    const [whatsapp, setWhatsapp] = useState([]);
    const [contactsWithError, setContactsWithError] = useState([]);
    const [existError, setExistError] = useState(false);

    const [success, setSuccess] = useState(0);

    const [whatsappId, setWhatsappId] = useState("");
    const [loading, setLoading] = useState(false);
    const [forAll, setForAll] = useState(true);

    const [tabValue, setTabValue] = useState(0); // para abas do modal
    const { user } = useContext(AuthContext);
    const [selectedTags, setSelectedTags] = useState([]);
    const [tags, setTags] = useState([]);


    function renderItemWhatsapp(campaign) {
        return <MenuItem value={campaign.id}>{campaign.name} - ({campaign.numberConnection}) - {campaign.status === 'CONNECTED' ? 'Conectado' : 'Desconectado'}</MenuItem>
    }

    function RenderSelectWhatsapp(Campaigns) {
        var i = 0;
        var rows = [];
        for (var campanha in Campaigns) {
            rows.push(renderItemWhatsapp(Campaigns[campanha]));
        }
        return rows;
    }

    // função para buscar tags
    async function getTags() {
        try {
            const { data } = await api.get("/tags");
            if (!data || !data.tags) data.tags = [];
            setTags(data.tags)
        } catch (error) {
            console.error(error)
        }
    }

    // função para marcar/desmarcar tags
    const handleTagChange = useCallback((tagId, isChecked) => {
        setSelectedTags((prev) =>
            isChecked ? [...prev, tagId] : prev.filter(id => id !== tagId)
        );
    }, []);

    useEffect(() => {
        const fetchSession = async () => {
            try {
                await getTags();
                const { data } = await api.get("/whatsapp/");
                setWhatsapp(data)
            } catch (err) {
                toastError(err);
            }
        };
        fetchSession();
    }, [open]);

    const closeModal = () => {

        if (loading) return
        let setState = success > 0 ? true : false

        setisLoadingFile(false);
        setObjectContacts([]);
        setContacts([]);

        setWhatsappId('');
        setSelectedTags([]);
        setForAll(true);
        setTabValue(0);

        setContactsWithError([]);
        setExistError(false);
        onClose(false);
        onConfirm(setState);

    }

    function ordenarArrayCrescente(array) {
        array.sort(function (a, b) {
            return a - b;
        });

        return array;
    }

    const handleSaveContacts = async () => {
        if (whatsappId === "" || !contacts) return toast.error('Preenchar todos os campos para importar');
        setContactsWithError([]);
        setLoading(true)
        try {
            const { data } = await api.post("/contactsExcel", { 'contacts': contacts, 'forAll': forAll, 'connectionId': whatsappId, 'tagsId': JSON.stringify(ordenarArrayCrescente(selectedTags)) });
            setSuccess(data.success);
            if (data.error_content.length > 0) {
                setContactsWithError(data.error_content)
                setExistError(true)
            } else {
                setLoading(false)
                closeModal()
            }

        } catch (err) {
            setLoading(false)
            closeModal()
            toast.error("Erro na Importação! Contato Duplicado.");
        }
        setTimeout(() => {
            setLoading(false)
        }, 4000);
    }

    const contactsUpload = async (e) => {
        if (e.target.files) {
            setisLoadingFile(true);
            setObjectContacts([]);
            setContacts([]);

            const reader = new FileReader();
            reader.onload = async (e) => {
                const arquivo = e.target.result;
                const file = XLSX.readFile(arquivo);
                let data = [];
                let error = [];
                let duplicated = [];

                function verifyArray(contact) {
                    const index = (data.findIndex(ctt => ctt.number === contact.number) === -1);
                    return index
                }

                const sheets = file.SheetNames;

                for (let i = 0; i < sheets.length; i++) {
                    const temp = XLSX.utils.sheet_to_json(file.Sheets[file.SheetNames[i]]);

                    for await (const res of temp) {
                        var telefone = res.numero;
                        telefone = telefone.toString().replace(/\D/g, '');
                        res.numero = telefone.toString().substring(0, 2) === '55' && telefone.toString().length > 11 && telefone.toString().length <= 13 ? telefone.toString().substring(2) : `${telefone}`;

                        if (res.nome == '' || !('nome' in res) || !('numero' in res) || await PhoneValidator(res.numero) == false) { error.push(res); }
                        else {
                            const obj = res;
                            delete Object.assign(obj, { name: obj.nome })['nome'];
                            delete Object.assign(obj, { number: `55${obj.numero}` })['numero'];

                            let verifyDuplicated = verifyArray(obj);
                            verifyDuplicated ? data.push(obj) : duplicated.push(obj);

                        }
                    }
                }

                var total = data.length + error.length + duplicated.length;

                var percent = [{
                    'error': Math.round(((error.length * 100) / total), -1),
                    'success': Math.round(((data.length * 100) / total), -1),
                    'duplicated': Math.round(((duplicated.length * 100) / total), -1),
                    'total_duplicated': duplicated.length,
                    'total_success': data.length,
                    'total_error': error.length,
                }];
                setContacts(data);
                setisLoadingFile(false);
                setObjectContacts(percent);
            }
            reader.readAsArrayBuffer(e.target.files[0]);
        }
    }

    const selectedConnection = e => {
        setWhatsappId(e.target.value);
    }

    const checkboxChange = e => {
        var checkBox = forAll === true ? false : true;
        setForAll(checkBox)
    }

    const downloadArchive = () => {

        let data = [{
            sheet: `Correção`,
            columns: [
                { label: "nome", value: "nome" },
                { label: "numero", value: "numero" },
                { label: "erro", value: "erro" },
            ],
            content: contactsWithError,
        }]

        let settings = { fileName: `Correção_${new Date().toLocaleDateString('pt-BR')}` }

        xlsx(data, settings) // Will download the excel file

    }

    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box p={3}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <Dialog
            open={open}
            aria-labelledby="confirm-dialog"
        >
            <DialogTitle id="confirm-dialog">{i18n.t("importContactsModal.title")}</DialogTitle>
            <DialogContent dividers>
                {loading ? <>
                    <Box component="div" >
                        <LinearProgress
                            classes={{
                                colorPrimary: classes.linearColorPrimary,
                                barColorPrimary: classes.linearBarColorPrimary,
                            }}
                        />
                        <Box component="p"
                            style={{ textAlign: 'center', marginTop: '15px', fontSize: '15px', fontFamily: 'Circular-Loom', }}
                        >{i18n.t("importContactsModal.title")}</Box>
                    </Box>

                </> :
                    <> {
                        existError ?
                            <Box component="div"
                                style={{
                                    display: 'flex',
                                    width: '550px',
                                    justifyContent: 'center',
                                    flexWrap: 'wrap'
                                }}
                            >
                                <Box component="p"
                                    style={{
                                        fontSize: '17px',
                                        fontFamily: 'inherit'
                                    }}
                                >
                                    {i18n.t("importContactsModal.errorMessage.start")} <strong>{`${contactsWithError?.length}`}</strong> {i18n.t("importContactsModal.errorMessage.end")}
                                </Box>

                                <Box component="p"
                                    style={{
                                        fontSize: '14px',
                                        fontFamily: 'inherit'
                                    }}
                                >
                                    {i18n.t("importContactsModal.errorMessage.warning")}
                                </Box>

                                <Button onClick={e => downloadArchive(e)} variant="contained" color="primary">{`${i18n.t("importContactsModal.errorMessage.button")} (${contactsWithError?.length})`}</Button>
                            </Box>
                            :
                            <>
                                <Box component="div" style={{ display: 'flex', justifyContent: 'space-between', marginTop: '35px' }}>
                                    <input
                                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                        hidden
                                        id="outlined-button-file"
                                        onChange={e => contactsUpload(e)}
                                        type="file"
                                        multiple={false}
                                    />

                                    <Typography component="label" htmlFor="outlined-button-file">
                                        <Button variant="outlined" component="span" style={{ padding: '10px 25px' }} >
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-video2" viewBox="0 0 16 16">
                                                <path d="M10 9.05a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z" />
                                                <path d="M2 1a2 2 0 0 0-2 2v9a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2H2ZM1 3a1 1 0 0 1 1-1h2v2H1V3Zm4 10V2h9a1 1 0 0 1 1 1v9c0 .285-.12.543-.31.725C14.15 11.494 12.822 10 10 10c-3.037 0-4.345 1.73-4.798 3H5Zm-4-2h3v2H2a1 1 0 0 1-1-1v-1Zm3-1H1V8h3v2Zm0-3H1V5h3v2Z" />
                                            </svg>
                                            &nbsp;&nbsp;&nbsp;
                                            {i18n.t("importContactsModal.uploadButton")}
                                        </Button>
                                    </Typography>
                                    <Button
                                        style={{
                                            padding: '10px',
                                            height: '40px'
                                        }}
                                        type="submit"
                                        color="primary"
                                        variant="contained"
                                        href={excelContacts}
                                        download="Contatos.xlsx"
                                        target='_blank'
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-download" viewBox="0 0 16 16">
                                            <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                                            <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                                        </svg>
                                        &nbsp;&nbsp;&nbsp;
                                        {i18n.t("importContactsModal.downloadButton")}
                                    </Button>
                                </Box>
                                <Box component="p" hidden={isLoadingFile ? false : true}>{i18n.t("importContactsModal.uploadingMessage")}</Box>
                                {
                                    objectContacts.length > 0 &&
                                    <>
                                        {/* Informações a serem carregadas após o load da lista de contato */}
                                        <Box component="div" style={{ marginTop: '30px' }}>
                                            <Box component="div" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <Box component="p">
                                                    <Typography component="span" style={{
                                                        background: '#0b708c',
                                                        padding: '0px 8px',
                                                        marginRight: '5px',
                                                        borderRadius: '10px'
                                                    }}>
                                                    </Typography>
                                                    {i18n.t("importContactsModal.valid")}
                                                    &nbsp;&nbsp;&nbsp;
                                                    {objectContacts[0].total_success}
                                                </Box>
                                                {
                                                    objectContacts[0].total_duplicated > 0 &&
                                                    <Box component="p">
                                                        <Typography component="span" style={{
                                                            background: '#f50057',
                                                            padding: '0px 8px',
                                                            marginRight: '5px',
                                                            borderRadius: '10px'
                                                        }}>
                                                        </Typography>
                                                        {i18n.t("importContactsModal.invalid")}
                                                        &nbsp;&nbsp;&nbsp;
                                                        {objectContacts[0].total_error}
                                                    </Box>
                                                }
                                                <Box component="p">
                                                    <Typography component="span" style={{
                                                        background: '#bdbdbd',
                                                        padding: '0px 8px',
                                                        marginRight: '5px',
                                                        borderRadius: '10px'
                                                    }}>
                                                    </Typography>
                                                    {i18n.t("importContactsModal.duplicated")}
                                                    &nbsp;&nbsp;&nbsp;
                                                    {objectContacts[0].total_duplicated}
                                                </Box>
                                            </Box>
                                            <MultiProgress
                                                transitionTime={1.2}
                                                elements={[
                                                    {
                                                        value: objectContacts[0].success,
                                                        color: "#0b708c",
                                                        showPercentage: true,
                                                        fontSize: 12,
                                                        textColor: "white",
                                                        isBold: true,
                                                    },
                                                    {
                                                        value: objectContacts[0].error,
                                                        color: "#f50057",
                                                        showPercentage: true,
                                                        fontSize: 12,
                                                        textColor: "white",
                                                        isBold: true,
                                                    },
                                                    {
                                                        value: objectContacts[0].duplicated,
                                                        color: "#bdbdbd",
                                                        showPercentage: true,
                                                        fontSize: 12,
                                                        textColor: "white",
                                                        isBold: true,
                                                    }
                                                ]}
                                                height={15}
                                            />
                                        </Box>

                                        {/* Tabs */}
                                        <AppBar position="static" className={classes.tab}>
                                            <Tabs value={tabValue} onChange={handleTabChange} aria-label="simple tabs" TabIndicatorProps={{ style: { background: '#0b708c' } }}>
                                                <Tab label={i18n.t("importContactsModal.tabs.main")} {...a11yProps(0)} />
                                                <Tab label={i18n.t("importContactsModal.tabs.tags")} {...a11yProps(1)} />
                                            </Tabs>
                                        </AppBar>

                                        <Box component="div" style={{ overflowY: "auto", height: "30vh", minHeight: "300px" }}>
                                            <TabPanel value={tabValue} index={0}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} mb={2}>
                                                        <Typography component="label" className={classes.lbl} >
                                                            {i18n.t("importContactsModal.form.whatsapp")}
                                                            <Typography component="span">*</Typography>
                                                        </Typography>
                                                        <Select
                                                            onChange={e => selectedConnection(e)}
                                                            variant="outlined"
                                                            name="whatsappSelected"
                                                            required
                                                            style={{ width: '100%', height: '40px' }}
                                                            value={whatsappId}
                                                        >
                                                            {RenderSelectWhatsapp(whatsapp)}
                                                        </Select>
                                                    </Grid>
                                                </Grid>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} mb={2}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={forAll}
                                                                    onChange={e => checkboxChange(e)}
                                                                    value="forAll"
                                                                />
                                                            }
                                                            label={i18n.t("importContactsModal.form.forAll")}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </TabPanel>
                                            <TabPanel value={tabValue} index={1}>
                                                <TagCheckList tags={tags} selectedTags={selectedTags} onChange={handleTagChange} />
                                            </TabPanel>
                                        </Box>
                                    </>
                                }
                            </>
                    }
                    </>
                }
            </DialogContent>
            <DialogActions>
                <Button
                    disabled={loading}
                    variant="contained"
                    onClick={e => { closeModal() }}
                    color="default"
                >
                    {i18n.t("confirmationModal.buttons.cancel")}
                </Button>
                <Button
                    hidden={objectContacts.length < 1 || existError || loading}
                    variant="contained"
                    onClick={e => { handleSaveContacts(); }}
                    color="secondary"
                >
                    {i18n.t("importContactsModal.importButton")}
                </Button>
            </DialogActions>
        </Dialog >
    );
};

export default ConfirmationModal;
