import { Box, Typography, makeStyles } from "@material-ui/core";
import WarningIcon from '@material-ui/icons/Warning';
import { useContext } from "react";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../../context/Auth/AuthContext";
import { i18n } from "../../translate/i18n";

const isMobile = Number(window.innerWidth) < 600 ? true : false;
const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        verticalAlign: 'middle',
        padding: '10px',
        width: '100vw',
        height: '100vh',
        backgroundColor: '#448aff',
        backgroundImage: 'linear-gradient(to bottom right, #eeeeee, #bdbdbd)',
    },
    form: {
        maxWidth: '800px',
        width: '90vw',
        borderRadius: '10px',
        maxHeight: '600px',
        margin: '0 auto',
        padding: '20px 50px',
        boxShadow: '1px 1px 10px rgba(0,0,0,.5)',
        backgroundColor: '#fff',
        border: '1px solid rgba(0,0,0,.2)',
        position: 'relative',
        marginTop: isMobile ? '10vh' : 'calc((100vh - 600px) / 2)'
    },
    title: {
        fontSize: '2rem',
        fontWeight: '600',
        marginBottom: '30px',
        borderBottom: '1px solid #e0e0e0',
        paddingBottom: '10px',
        marginTop: '20px'
    },
    subtitle: {
        fontSize: '1.2rem',
        color: '#757575'
    },
    button: {
        display: 'block',
        width: 'calc(100% - 90px)',
        bottom: '50px',
        position: 'absolute',
        left: '45px',
        height: '40px',
    },
    icon: {
        width: '40px',
        height: '40px',
        marginRight: '20px',
        color: '#d50000'
    },
    image: {
        width: "100%",
        display: "block",
        marginBottom: "20px"
    }
}));

const PageNotFound = () => {
    const classes = useStyles();
    const { handleLogout, user } = useContext(AuthContext);
    const history = useHistory();

    return (
        <Box className={classes.container}>
            <Box className={classes.form}>
                <Typography variant="h3" className={classes.title}>
                    <WarningIcon className={classes.icon} />
                    {i18n.t("pageFound.title")}
                </Typography>
                <Box
                    component="img"
                    alt="Conexa Digital"
                    src="/media/illustrations/unitedpalms-1/18-dark.png"
                    className={classes.image}
                />
                {/* <Button
                    className={classes.button}
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        if (user?.id) history.push("/dashboard");
                        else handleLogout(user?.id || '', user?.adminId || '');
                    }}
                >
                    {i18n.t("pageFound.button")}
                </Button> */}
            </Box>
        </Box>
    );
};

export default PageNotFound;