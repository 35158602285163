import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import socket from "../useSocket";
import JsonParse from "../../helpers/JsonParse";

const useAuth = () => {
	const history = useHistory();
	const [isAuth, setIsAuth] = useState(false);
	const [loading, setLoading] = useState(true);
	const [user, setUser] = useState({});
	const [state, setState] = useState('offline');

	api.interceptors.request.use(
		config => {
			const token = localStorage.getItem("token");
			if (token) {
				config.headers["Authorization"] = `Bearer ${JsonParse(token)}`;
				setIsAuth(true);
			}
			return config;
		},
		error => {
			Promise.reject(error);
		}
	);

	api.interceptors.response.use(
		response => {
			return response;
		},
		async error => {
			const originalRequest = error.config;
			if (error?.response?.status === 403 && !originalRequest._retry) {
				originalRequest._retry = true;

				const { data } = await api.post("/auth/refresh_token");
				if (data) {
					localStorage.setItem("token", JSON.stringify(data.token));
					api.defaults.headers.Authorization = `Bearer ${data.token}`;
				}
				return api(originalRequest);
			}
			if (error?.response?.status === 401) {
				localStorage.removeItem("token");
				api.defaults.headers.Authorization = undefined;
				setIsAuth(false);
			}
			return Promise.reject(error);
		}
	);

	useEffect(() => {
		const token = localStorage.getItem("token");
		(async () => {
			if (token) {
				try {
					const { data } = await api.post("/auth/refresh_token");
					api.defaults.headers.Authorization = `Bearer ${data.token}`;
					setIsAuth(true);
					setUser(data.user);
					setState('online');
				} catch (err) {
					console.error({err});
					toastError(err);
				}
			}
			setLoading(false);
		})();
	}, []);

	useEffect(() => {
		socket.on("user", data => {
			if (data.action === "update" && data.user.id === user.id) {
				if (data.user.state !== state) {
					setState(data.user.state);
				}

				// verificar se o user atual e o novo forem o mesmo, não atualizar
				const areObjectsEqual = (obj1, obj2) => {
					if (obj1 === obj2) return true;

					if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 == null || obj2 == null) {
						return false;
					}

					const keys1 = Object.keys(obj1);
					const keys2 = Object.keys(obj2);

					if (keys1.length !== keys2.length) return false;

					for (const key of keys1) {
						if (!keys2.includes(key) || !areObjectsEqual(obj1[key], obj2[key])) {
							return false;
						}
					}

					return true;
				}

				if (areObjectsEqual(data.user, user) == false) setUser(data.user);
			}
		});

		return () => { };
	}, [user]);

	if (user.profile === "adminSuper") history.push("/company");

	const handleLogin = async userData => {
		setLoading(true);

		try {
			const { data } = await api.post("/auth/login", userData);
			localStorage.setItem("token", JSON.stringify(data.token));
			api.defaults.headers.Authorization = `Bearer ${data.token}`;
			window.location.href= data.user?.profile == "user" ? "/tickets" : "/dashboard";
			setUser(data.user);
			setIsAuth(true);
			toast.success(i18n.t("auth.toasts.success"));
			setState('online');
			setLoading(false);
		} catch (err) {
			console.error({err});
			toastError(err);
			setLoading(false);
		}
	};

	const handleLogout = async (userId, adminId) => {
		setLoading(true);

		try {
			await api.delete("/auth/logout", { data: { userId, adminId } });
			setIsAuth(false);
			setUser({});
			localStorage.removeItem("token");
			api.defaults.headers.Authorization = undefined;
			setLoading(false);
			history.push("/login");
			socket.disconnect();
		} catch (err) {
			console.error({err});
			toastError(err);
			setLoading(false);
		}
	};

	const handleStatus = (currentStatus) => {
		const status = state;
		setState(currentStatus);

		if (status != 'online' && currentStatus == 'online') {
			const path = history.location.pathname || '/';
			history.push(path);
		}
	}

	return { isAuth, user, loading, handleLogin, handleLogout, handleStatus, state };
};

export default useAuth;
