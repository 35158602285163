import { useContext, useEffect, useReducer, useState } from "react";

import { makeStyles } from "@material-ui/core";

import MainContainer from "../../components/MainContainer";

import { toast } from "react-toastify";
import { KTSVG } from '../../../_metronic/helpers';
import ConclusionMessagesModal from "../../components/ConclusionMessagesModal";
import ConfirmationModal from "../../components/ConfirmationModal";
import { AuthContext } from "../../context/Auth/AuthContext";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import socket from "../../hooks/useSocket";
import { Button } from "@material-ui/core";


const reducer = (state, action) => {
  if (action.type === "LOAD_QUICK_ANSWERS") {
    const conclusionMessages = action.payload;
    const newConclusionMessages = [];

    conclusionMessages.forEach((conclusionMessage) => {
      const conclusionMessageIndex = state.findIndex((q) => q.id === conclusionMessage.id);
      if (conclusionMessageIndex !== -1) {
        state[conclusionMessageIndex] = conclusionMessage;
      } else {
        newConclusionMessages.push(conclusionMessage);
      }
    });

    return [...state, ...newConclusionMessages];
  }

  if (action.type === "UPDATE_QUICK_ANSWERS") {
    const conclusionMessage = action.payload;
    const conclusionMessageIndex = state.findIndex((q) => q.id === conclusionMessage.id);

    if (conclusionMessageIndex !== -1) {
      state[conclusionMessageIndex] = conclusionMessage;
      return [...state];
    } else {
      return [conclusionMessage, ...state];
    }
  }

  if (action.type === "DELETE_QUICK_ANSWERS") {
    const conclusionMessageId = action.payload;

    const conclusionMessageIndex = state.findIndex((q) => q.id === conclusionMessageId);
    if (conclusionMessageIndex !== -1) {
      state.splice(conclusionMessageIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
  modalStyle: {
    margin: '20px 10px',
    padding: '10px',
    minWidth: '395px',
    "@media (max-width: 740px)": {
      padding: '0px !important',
      marginTop: '65px'
    }
  }
}));

const ConclusionMessages = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchParam, setSearchParam] = useState("");
  const [conclusionMessages, dispatch] = useReducer(reducer, []);
  const [selectedConclusionMessages, setSelectedConclusionMessages] = useState(null);
  const [conclusionMessagesModalOpen, setConclusionMessagesModalOpen] = useState(false);
  const [deletingConclusionMessages, setDeleteConclusionMessages] = useState(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam]);


  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchConclusionMessage = async () => {
        try {
          const { data } = await api.get("/conclusionMessages/", {
            params: { searchParam, pageNumber },
          });
          dispatch({ type: "LOAD_QUICK_ANSWERS", payload: data.conclusionMessages });
          setHasMore(data.hasMore);
          setLoading(false);
        } catch (err) {
          console.error({err});
          toastError(err);
        }
      };
      fetchConclusionMessage();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, pageNumber]);

  useEffect(() => {
    socket.on(`conclusionMessage-${user.adminId}`, (data) => {
      if (data.adminId !== user?.adminId || !data.adminId) return;
      if (data.action === "update" || data.action === "create") {
        dispatch({ type: "UPDATE_QUICK_ANSWERS", payload: data.conclusionMessage });
      }

      if (data.action === "delete") {
        dispatch({
          type: "DELETE_QUICK_ANSWERS",
          payload: +data.conclusionMessageId,
        });
      }
    });

    return () => {};
  }, []);

  const handleOpenConclusionMessagesModal = () => {
    setSelectedConclusionMessages(null);
    setConclusionMessagesModalOpen(true);
  };

  const handleConclusionMessagesModal = () => {
    setSelectedConclusionMessages(null);
    setConclusionMessagesModalOpen(false);
  };

  const handleEditConclusionMessages = (conclusionMessage) => {
    setSelectedConclusionMessages(conclusionMessage);
    setConclusionMessagesModalOpen(true);
  };

  const handleDeleteConclusionMessages = async (conclusionMessageId) => {
    try {
      await api.delete(`/conclusionMessages/${conclusionMessageId}`);
      toast.success(i18n.t("concludeMessage.toasts.deleted"));
    } catch (err) {
      console.error({err});
      toastError(err);
    }
    setDeleteConclusionMessages(null);
    setSearchParam("");
    setPageNumber(1);
  };

  const loadMore = () => {
    setPageNumber((prevState) => prevState + 1);
  };

  const handleScroll = (e) => {
    if (!hasMore || loading) return;
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
    }
  };

  return (
    <MainContainer>
      <ConfirmationModal
        title={
          deletingConclusionMessages &&
          `${i18n.t("concludeMessage.confirmationModal.deleteTitle")}`
        }
        open={confirmModalOpen}
        onClose={setConfirmModalOpen}
        onConfirm={() => handleDeleteConclusionMessages(deletingConclusionMessages.id)}
      >
        {i18n.t("concludeMessage.confirmationModal.deleteMessage")}
      </ConfirmationModal>
      <ConclusionMessagesModal
        open={conclusionMessagesModalOpen}
        onClose={handleConclusionMessagesModal}
        aria-labelledby="form-dialog-title"
        conclusionMessageId={selectedConclusionMessages && selectedConclusionMessages.id}
      ></ConclusionMessagesModal>
      <div className={`${classes.modalStyle} row gy-5 gx-xl-8`}
        onScroll={handleScroll}>
        <div className='col-xl-15'>
          <div className='card card-xxl-stretch-5 mb-15 mb-xl-8'>
            {/* begin::Header */}
            <div className='card-header border-0 pt-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder fs-3 mb-1'>{i18n.t("quickConcludeMessage.list.title")}</span>
                {/*<span className='text-muted mt-1 fw-bold fs-7'>Over 500 members</span>*/}
              </h3>
              <div
                className='card-toolbar '
                data-bs-toggle='tooltip'
                data-bs-placement='top'
                data-bs-trigger='hover'
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleOpenConclusionMessagesModal}
                >
                  <KTSVG path='media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
                  {i18n.t("quickConcludeMessage.buttons.new")}
                </Button>
              </div>
            </div>
            {/* end::Header */}
            {/* begin::Body */}
            <div className='card-body py-3'>
              {/* begin::Table container */}
              <div className='table-responsive'>
                {/* begin::Table */}
                <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                  {/* begin::Table head */}
                  <thead>
                    <tr className='fw-bolder text-muted'>
                      <th className='min-w-150px'>{i18n.t("quickConcludeMessage.list.shortcut")}</th>
                      <th className='min-w-120px'>{i18n.t("quickConcludeMessage.list.message")}</th>
                      <th className='min-w-100px text-end'>{i18n.t("quickConcludeMessage.list.actions")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <>
                      {conclusionMessages.map((conclusionMessage) => (
                        <>
                          <tr >
                            <td>
                              <div>
                                <div className='d-flex justify-content-start flex-column'>
                                  <a href='#' className='text-dark fw-bolder text-hover-primary fs-6'>
                                    {conclusionMessage.shortcut}
                                  </a>
                                </div>
                              </div>
                            </td>
                            <td>
                              <a className='text-dark fw-bolder text-hover-primary d-block fs-6'>
                                {conclusionMessage.message}
                              </a>
                            </td>
                            <td>
                              <div className='d-flex justify-content-end flex-shrink-0'>
                                <button
                                  title={i18n.t("buttons.edit")}
                                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                  onClick={() => handleEditConclusionMessages(conclusionMessage)}
                                >
                                  <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                                </button>
                                <button
                                  title={i18n.t("buttons.delete")}
                                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                  onClick={(e) => {
                                    setConfirmModalOpen(true);
                                    setDeleteConclusionMessages(conclusionMessage);
                                  }}
                                >
                                  <KTSVG
                                    path='/media/icons/duotune/general/gen027.svg'
                                    className='svg-icon-3'
                                  />
                                </button>
                              </div>
                            </td>
                          </tr>
                        </>
                      ))}

                    </>
                  </tbody>
                  {/* end::Table body */}
                </table>
                {/* end::Table */}
              </div>
              {/* end::Table container */}
            </div>
            {/* begin::Body */}
          </div>
        </div>
      </div>
    </MainContainer>
  );
};

export default ConclusionMessages;
